<span
  *ngIf="status"
  class="badge"
  [ngClass]="{
    'bg-success': status === 'DONE' || status === 'COMPLETED_EXTERNALLY',
    'bg-danger':
      status === 'AUTOMATION_ERRORS' ||
      status === 'CONSUMER_ERRORS' ||
      status === 'REJECTED_INVALID' ||
      status === 'REJECTED_UNPROCESSABLE',
    'bg-warning':
      status?.indexOf('NEEDS') === 0 ||
      status === 'READY_FOR_REVIEW' ||
      status === 'DISREGARDED' ||
      status === 'REROUTED',
    'bg-info': status === 'READY_FOR_AUTOMATION'
  }"
>
  <div [placement]="tooltipPlacement" container="body" [ngbTooltip]="statusTooltip">{{ humanReadableStatus }}</div>
</span>
