import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { VxRuleTemplatesQuery } from 'app/vision-x/vision-x.gql'
import { QueryVxRuleTemplatesArgs, VxRuleTemplateList } from 'generated/graphql'
import { Observable } from 'rxjs'

/**
 * Fetch and update visionX entities like files, file page types, and file sources
 */
@Injectable({
  providedIn: 'root',
})
export class VxRuleTemplateService {
  constructor(private apollo: Apollo) {}

  fetchVxRuleTemplates(search?: string): Observable<ApolloQueryResult<{ vxRuleTemplates: VxRuleTemplateList }>> {
    return this.apollo.watchQuery<{ vxRuleTemplates: VxRuleTemplateList }, QueryVxRuleTemplatesArgs>({
      query: VxRuleTemplatesQuery,
      variables: {
        limit: 10,
        offset: 0,
        search: search?.length ? search : null,
      },
      fetchPolicy: 'network-only',
    }).valueChanges
  }
}
