import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { ProcedureSetBotType } from 'app/teleport/teleport.service'
import {
  GetLegacyPortalMapByPayerIdDocument,
  GetPortalMapByPayerPlanIdDocument,
  PriorAuthPortalMapOutDto,
  PortalMapOutDto,
  GetPriorAuthPortalMapDocument,
  QueryGetPriorAuthPortalMapArgs,
  QueryGetPortalMapArgs,
  QueryLegacyGetPortalMapArgs
} from 'generated/graphql'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { debug } from '../utils/debug'
import { FeatureFlagService, FeatureFlags } from './feature-flag.service'

/**
 * Handle portal mapping operations
 *
 * @export
 * @class PortalMapService
 */
@Injectable({
  providedIn: 'root',
})
export class PortalMapService {
  constructor(private featureFlagService: FeatureFlagService, private apollo: Apollo) {}

  /**
   * @description Will check whether `api.portalMap` is enabled or not.
   */
  async getPortalMapEnabledFlag(): Promise<boolean> {
    return await this.featureFlagService.getFeatureFlagValue(FeatureFlags.api.portalMap)
  }

  /**
   * Fetch a portal map for a given payer plan ID
   *
   * @param {string} payerPlanId
   * @param {string} orgId
   * @return {*}  {Observable<PortalMap>}
   * @memberof TeleportService
   */
  getPriorAuthPortalMap(orgId: string, primaryProcedureCode: string, payerName: string): Observable<PriorAuthPortalMapOutDto> {
    let portalMap = this.apollo
      .query<{ getPriorAuthPortalMap: PriorAuthPortalMapOutDto }, QueryGetPriorAuthPortalMapArgs>({
        query: GetPriorAuthPortalMapDocument,
        variables: {
          orgId,
          primaryProcedureCode,
          payerName
        },
      })
      .pipe(
        first(),
        map((result) => result.data?.getPriorAuthPortalMap),
      )
      return portalMap
  }

  /**
   * Fetch a portal map for a given payer plan ID
   *
   * @param {string} payerPlanId
   * @param {string} orgId
   * @return {*}  {Observable<PortalMap>}
   * @memberof TeleportService
   */
  getPortalMap(payerPlanId: string, orgId: string, primaryProcedureCode: string, botType: ProcedureSetBotType): Observable<PortalMapOutDto> {
    debug('portal-map-service', 'search for portal map using payerPlanId', payerPlanId)
    let portalMap = this.apollo
      .query<{ getPortalMap: PortalMapOutDto }, QueryGetPortalMapArgs>({
        query: GetPortalMapByPayerPlanIdDocument,
        variables: {
          payerPlanId,
          orgId,
          primaryProcedureCode,
          botType
        },
      })
      .pipe(
        first(),
        map((result) => result.data?.getPortalMap),
      )
      return portalMap
  }

  /**
   * Fetch a portal map for a given payer ID
   *
   * @param {string} payerId
   * @param {string} orgId
   * @return {*}  {Observable<PortalMapOutDTO>}
   * @memberof TeleportService
   */
   legacyGetPortalMap(payerId: string, orgId: string, primaryProcedureCode: string, botType: ProcedureSetBotType): Observable<PortalMapOutDto> {
    debug('portal-map-service', 'search for portal map using payerId', payerId)
    let portalMap = this.apollo
      .query<{ legacyGetPortalMap: PortalMapOutDto }, QueryLegacyGetPortalMapArgs>({
        query: GetLegacyPortalMapByPayerIdDocument,
        variables: {
          payerId,
          orgId,
          primaryProcedureCode,
          botType
        },
      })
      .pipe(
        first(),
        map((result) => result.data?.legacyGetPortalMap),
      )
      return portalMap
  }
}