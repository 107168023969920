import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import {
  DeleteVxExtractionCondition,
  DeleteVxExtractionReplacement,
  UpdateVxExtractionReplacement,
} from 'app/vision-x/vision-x.gql'
import {
  CreateVxExtractionReplacementInput,
  ExtractionRule,
  MutationDeleteVxExtractionConditionArgs,
  MutationDeleteVxExtractionReplacementArgs,
  UpdateVxDocumentExtractionDataDocument,
  VxDocumentExtractionFieldUpdate,
  VxDocumentExtractionGroupUpdate,
  VxDocumentExtractionUpdateOutput,
  VxExtractionReplacement,
} from 'generated/graphql'

export type RepeatRange = {
  start: number
  end: number
  left: number
}

@Injectable({
  providedIn: 'root',
})
export class VxExtractionService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  async updateVxDocumentExtractionData(
    groups: VxDocumentExtractionGroupUpdate[],
    fields: VxDocumentExtractionFieldUpdate[],
  ): Promise<VxDocumentExtractionUpdateOutput> {
    const result = await this.apollo
      .mutate<
        { updateExtractionData: VxDocumentExtractionUpdateOutput },
        {
          groups: VxDocumentExtractionGroupUpdate[]
          fields: VxDocumentExtractionFieldUpdate[]
        }
      >({
        mutation: UpdateVxDocumentExtractionDataDocument,
        variables: { groups, fields },
        update: (store, fetchResult) => {
          store.evict({
            id: store.identify({
              id: fetchResult.data?.updateExtractionData?.vxDocumentExtractionGroups[0]?.vxDocumentId,
              __typename: 'VxDocument',
            }),
          })
          store.gc()
        },
      })
      .toPromise()

    return result.data?.updateExtractionData
  }

  async updateVxExtractionReplacement(
    id: string,
    data: CreateVxExtractionReplacementInput,
  ): Promise<VxExtractionReplacement> {
    const result = await this.apollo
      .mutate<
        { updateVxExtractionReplacement: VxExtractionReplacement },
        { id: string; data: CreateVxExtractionReplacementInput }
      >({
        mutation: UpdateVxExtractionReplacement,
        variables: { id, data },
      })
      .toPromise()
    return result.data?.updateVxExtractionReplacement
  }

  async testExtractionRuleConditions(
    extractionRule: ExtractionRule,
    fileId: string,
    parentRanges?: RepeatRange[],
  ): Promise<{ parentRange: RepeatRange; foundRanges: RepeatRange[] }[]> {
    const url = `/api/fileImage/${fileId}/test-conditions`

    return await this.http
      .post<{ parentRange: RepeatRange; foundRanges: RepeatRange[] }[]>(
        url,
        { extractionRule, parentRanges },
        { withCredentials: true, responseType: 'json' },
      )
      .toPromise()
  }

  async deleteVxExtractionCondition(id: string): Promise<boolean> {
    const result = await this.apollo
      .mutate<{ deleteResult: boolean }, MutationDeleteVxExtractionConditionArgs>({
        mutation: DeleteVxExtractionCondition,
        variables: {
          id,
        },
      })
      .toPromise()
    return result.data?.deleteResult
  }

  async deleteVxExtractionReplacement(id: string): Promise<boolean> {
    const result = await this.apollo
      .mutate<{ deleteResult: boolean }, MutationDeleteVxExtractionReplacementArgs>({
        mutation: DeleteVxExtractionReplacement,
        variables: {
          id,
        },
      })
      .toPromise()
    return result.data?.deleteResult
  }
}
