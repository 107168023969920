import { Component, Input, Output, EventEmitter } from '@angular/core'
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ClientCredentialsService } from 'app/admin/client-credentials/client-credentials.service'

/**
 * Component to display a password field that can
 * toggle to an input field
 *
 * @export
 * @class ClientCredentialPasswordInputComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-client-credential-password-input',
  templateUrl: './client-credential-password-input.component.html',
  styleUrls: ['./client-credential-password-input.component.scss'],
})
export class ClientCredentialPasswordInputComponent {
  isPasswordVisible: boolean = false
  _didGetPassword: boolean = false
  @Output() didGetPassword: EventEmitter<void> = new EventEmitter()
  @Input() passwordFormControl: UntypedFormControl | FormControl
  @Input() clientCredentialId: string | null

  constructor(private clientCredentialsService: ClientCredentialsService) {}

  passwordClickHandler() {
    if (!this._didGetPassword) {
      this.clientCredentialsService.getClientCredentialPassword(this.clientCredentialId).subscribe((result) => {
        const password = result.data.getClientCredentialPassword
        this.passwordFormControl.setValue(password)
        this.didGetPassword.emit()
        this.isPasswordVisible = !this.isPasswordVisible
      })
      this._didGetPassword = true
    } else {
      this.isPasswordVisible = !this.isPasswordVisible
    }
  }
}
