import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { PrivacyPolicyPage } from 'app/policies/privacy-policy/privacy-policy.page'
import { TermsOfUsePage } from 'app/policies/terms-of-use/terms-of-use.page'
import { BadgePillComponent } from 'app/shared/components/badge-pill/badge-pill.component'
import { CanvasControlsComponent } from 'app/shared/components/canvas-controls/canvas-controls.component'
import { CollapsingCardComponent } from 'app/shared/components/collapsing-card/collapsing-card.component'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { DateRangePickerComponent } from 'app/shared/components/date-range-picker/date-range-picker.component'
import { FilterDropdownComponent } from 'app/shared/components/filter-dropdown/filter-dropdown.component'
import { GenericModalComponent } from 'app/shared/components/generic-modal/generic-modal.component'
import { JanusIconComponent } from 'app/shared/components/janus-icon/janus-icon.component'
import { LoadingBarComponent } from 'app/shared/components/loading-bar/loading-bar.component'
import { LoadingComponent } from 'app/shared/components/loading/loading.component'
import { MultiInputComponent } from 'app/shared/components/multi-input/multi-input.component'
import { OrgLogoComponent } from 'app/shared/components/org-logo/org-logo.component'
import { OrgSwitcherModalComponent } from 'app/shared/components/org-switcher-modal/org-switcher-modal.component'
import { OrgSwitcherComponent } from 'app/shared/components/org-switcher/org-switcher.component'
import { OrganizationListComponent } from 'app/shared/components/organization-list/organization-list.component'
import { OrganizationTypeaheadComponent } from 'app/shared/components/organization-typeahead/organization-typeahead.component'
import { PaginationComponent } from 'app/shared/components/pagination/pagination.component'
import { PayerTypeaheadComponent } from 'app/shared/components/payer-typeahead/payer-typeahead.component'
import { PayerPlanPayerTypeaheadComponent } from 'app/shared/components/payerplan-payer-typeahead/payerplan-payer-typeahead.component'
import { PayerPlanPlanTypeaheadComponent } from 'app/shared/components/payerplan-plan-typeahead/payerplan-plan-typeahead.component'
import { QuicksightDashboardComponent } from 'app/shared/components/quicksight-dashboard/quicksight-dashboard.component'
import { SearchFieldComponent } from 'app/shared/components/search-field/search-field.component'
import { TaskTypeTypeaheadComponent } from 'app/shared/components/task-type-typeahead/task-type-typeahead.component'
import { TextTypeaheadComponent } from 'app/shared/components/text-typeahead/text-typeahead.component'
import { TypeaheadSelectComponent } from 'app/shared/components/typeahead-select/typeahead-select.component'
import { UserAvatarMenuComponent } from 'app/shared/components/user-avatar-menu/user-avatar-menu.component'
import { UserAvatarComponent } from 'app/shared/components/user-avatar/user-avatar.component'
import { VxAccordionComponent } from 'app/shared/components/vx-accordion/vx-accordion.component'
import { VxDocumentStatusBadgeComponent } from 'app/shared/components/vx-document-status-badge/vx-document-status-badge.component'
import { VxDocumentTypeMultiSelectTypeaheadComponent } from 'app/shared/components/vx-document-type-multiselect-typeahead/vx-document-type-multiselect-typeahead.component'
import { VxDocumentTypeaheadComponent } from 'app/shared/components/vx-document-typeahead/vx-document-typeahead.component'
import { VxDropdownButtonComponent } from 'app/shared/components/vx-dropdown-button/vx-dropdown-button.component'
import { VxPageTypeMultiSelectTypeaheadComponent } from 'app/shared/components/vx-page-type-multiselect-typeahead/vx-page-type-multiselect-typeahead.component'
import { VxPageTypeTypeaheadComponent } from 'app/shared/components/vx-page-type-typeahead/vx-page-type-typeahead.component'
import { FcBsValidationDirective } from 'app/shared/directives/fc-bs-validation.directive'
import { ApproximationPipe } from 'app/shared/pipes/approximation.pipe'
import { OrdinalPipe } from 'app/shared/pipes/ordinal.pipe'
import { SentenceCasePipe } from 'app/shared/pipes/sentence-case.pipe'
import { StartCasePipe } from 'app/shared/pipes/start-case.pipe'
import { VxDocumentTypeTypeaheadComponent } from 'app/vx/components/vx-document-type-typeahead/vx-document-type-typeahead.component'
import { VxFileThumbnailComponent } from './components/vx-file-thumbnail/vx-file-thumbnail.component'
import { VxReferralViewerComponent } from './components/vx-referral-viewer/vx-referral-viewer.component'
import { PayerPlanPipe } from './pipes/payer-plan.pipe'
import { ExportVxDocumentFileButtonComponent } from './export-vx-document-file-button/export-vx-document-file-button.component'

const routes: Routes = [
  {
    path: 'policies',
    children: [
      {
        path: 'privacy-policy',
        component: PrivacyPolicyPage,
      },
      {
        path: 'terms-of-use',
        component: TermsOfUsePage,
      },
    ],
  },
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgbModule,
    DateRangePickerComponent,
    BadgePillComponent,
    CollapsingCardComponent,
    ConfirmModalComponent,
    FilterDropdownComponent,
    GenericModalComponent,
    JanusIconComponent,
    LoadingComponent,
    LoadingBarComponent,
    MultiInputComponent,
    OrgLogoComponent,
    OrgSwitcherModalComponent,
    OrgSwitcherComponent,
    OrganizationTypeaheadComponent,
    PaginationComponent,
    SearchFieldComponent,
    TextTypeaheadComponent,
    TypeaheadSelectComponent,
    UserAvatarMenuComponent,
    UserAvatarComponent,
    VxDocumentTypeaheadComponent,
    VxPageTypeTypeaheadComponent,
    FcBsValidationDirective,
    ApproximationPipe,
    SentenceCasePipe,
    StartCasePipe,
    VxAccordionComponent,
    VxDocumentStatusBadgeComponent,
    VxDocumentTypeTypeaheadComponent,
    CanvasControlsComponent,
    OrganizationListComponent,
    PayerTypeaheadComponent,
    PayerPlanPayerTypeaheadComponent,
    PayerPlanPlanTypeaheadComponent,
    QuicksightDashboardComponent,
    TaskTypeTypeaheadComponent,
    VxDocumentTypeMultiSelectTypeaheadComponent,
    VxDropdownButtonComponent,
    VxFileThumbnailComponent,
    VxPageTypeMultiSelectTypeaheadComponent,
    VxReferralViewerComponent,
    ExportVxDocumentFileButtonComponent,
    OrdinalPipe,
    PayerPlanPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DateRangePickerComponent,
    BadgePillComponent,
    CollapsingCardComponent,
    ConfirmModalComponent,
    FilterDropdownComponent,
    GenericModalComponent,
    JanusIconComponent,
    LoadingComponent,
    LoadingBarComponent,
    MultiInputComponent,
    OrgLogoComponent,
    OrgSwitcherModalComponent,
    OrgSwitcherComponent,
    OrganizationTypeaheadComponent,
    PaginationComponent,
    SearchFieldComponent,
    TextTypeaheadComponent,
    TypeaheadSelectComponent,
    UserAvatarMenuComponent,
    UserAvatarComponent,
    VxDocumentTypeaheadComponent,
    VxPageTypeTypeaheadComponent,
    FcBsValidationDirective,
    ApproximationPipe,
    SentenceCasePipe,
    StartCasePipe,
    VxAccordionComponent,
    VxDocumentStatusBadgeComponent,
    VxDocumentTypeTypeaheadComponent,
    CanvasControlsComponent,
    OrganizationListComponent,
    PayerTypeaheadComponent,
    PayerPlanPayerTypeaheadComponent,
    PayerPlanPlanTypeaheadComponent,
    QuicksightDashboardComponent,
    TaskTypeTypeaheadComponent,
    VxDocumentTypeMultiSelectTypeaheadComponent,
    VxDropdownButtonComponent,
    VxFileThumbnailComponent,
    VxPageTypeMultiSelectTypeaheadComponent,
    VxReferralViewerComponent,
    ExportVxDocumentFileButtonComponent,
    OrdinalPipe,
    PayerPlanPipe,
  ],
  providers: [ApproximationPipe, SentenceCasePipe, StartCasePipe, OrdinalPipe, PayerPlanPipe],
})
export class SharedModule {}
