import { HttpErrorResponse } from '@angular/common/http'
import { NgModule } from '@angular/core'
import {
  ApolloClientOptions,
  ApolloLink,
  FetchResult,
  InMemoryCache,
  Operation,
  selectURI,
  split,
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition, Observable } from '@apollo/client/utilities'
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpBatchLink } from 'apollo-angular/http'
import { OperationDefinitionNode, print } from 'graphql'
import { Client, createClient } from 'graphql-ws'
import { setPathForLoginWithRelayState } from './auth/redirect-to-login'
import { v4 as uuid } from 'uuid'

/**
 * Link that allows us to connect websockets and Apollo.
 *
 * Apollo Client v3.5 and newer provides this link; however, v3.0 (which we are
 * on) does not. This class fills that void.
 *
 * @see {@link https://github.com/enisdenjo/graphql-ws#apollo-client}
 */
class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super()
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      )
    })
  }
}

const NON_BATCHED_OPERATIONS: string[] = ['FindMatchingOcrTextRelationshipsQuery']

export function createApollo(httpLink: HttpBatchLink): ApolloClientOptions<any> {
  let host = `${location.hostname}:${location.port}`
  const uri = `${location.protocol}//${host}/graphql`
  const http = httpLink.create({
    uri,
    withCredentials: true,
    batchMax: 10, // 10 operations per batch max
    batchInterval: 25, // 25 milliseconds
    // purpose of batchKey is to know what operations to batch together
    // the operations in NON_BATCHED_OPERATIONS are known to take significantly longer than other queries made with them, so we do not want them to make the rest of the page stall
    batchKey: (operation: Operation) => {
      if (NON_BATCHED_OPERATIONS.includes(operation.operationName)) {
        return uuid()
      }

      // everything here and below is the "default" functionality according to apollo: https://github.com/apollographql/apollo-client/blob/main/src/link/batch-http/batchHttpLink.ts#L239-L253
      const context = operation.getContext()

      const contextConfig = {
        http: context.http,
        options: context.fetchOptions,
        credentials: context.credentials,
        headers: context.headers,
      }

      //may throw error if config not serializable
      return selectURI(operation, uri) + JSON.stringify(contextConfig)
    },
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `ws${location.protocol === 'https:' ? 's' : ''}://${host}/graphql`,
      connectionParams: {
        withCredentials: true,
      },
      shouldRetry: () => true,
    }),
  )

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    http,
  )

  const error = onError(({ graphQLErrors, networkError }) => {
    if (networkError instanceof HttpErrorResponse) {
      if ((networkError as HttpErrorResponse)?.status === 500) {
        if (
          (networkError as HttpErrorResponse)?.error?.errors?.some((e) =>
            e?.message?.toLowerCase()?.includes('unauthorized'),
          )
        ) {
          if (!document.location.href?.includes('/login')) {
            setPathForLoginWithRelayState()
          }
        }
      }
    }
  })

  return {
    link: error.concat(link),
    cache: new InMemoryCache({
      typePolicies: {},
    }),
  }
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpBatchLink],
    },
  ],
})
export class GraphQLModule {}
