import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

/**
 * Modal to allow filtering Client credentials
 *
 * @export
 * @class ClientCredentialFilterComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-client-credential-filter',
  templateUrl: './client-credential-filter.component.html',
  styleUrls: ['./client-credential-filter.component.scss'],
})
export class ClientCredentialFilterComponent implements OnInit, AfterViewInit {
  @Input() userSelectionForEnabledDisabledFilter: string
  @Input() selectedPortal: string
  @Input() portals: Array<string>

  readonly NO_PORTAL_FILTER_STRING = 'No portal filter'

  // Get the enabled filter control
  filters = new UntypedFormGroup({
    enabledFilterControl: new UntypedFormControl('all'),
  })
  enabledFilterControl = this.filters.get('enabledFilterControl') as UntypedFormControl

  constructor(public modal: NgbActiveModal) {}

  /**
   * Set the default value for the enabled filter
   */
  ngOnInit(): void {
    this.enabledFilterControl.setValue(this.userSelectionForEnabledDisabledFilter)
  }

  // TODO: Can this DOM manipulation be avoided by using an ng directive for the select element?
  // TODO: Attempted this with double binding [(ngModel)] but it didn't work, maybe due to this being populated dynamically
  // TODO: Creating a form control and using setValue() was also attempted but didn't work
  /**
   * Since we are dynamically populating this list, we need to wait for the view to init
   * and then set the selected portal
   */
  ngAfterViewInit(): void {
    const defaultOption = this.selectedPortal ?? this.NO_PORTAL_FILTER_STRING
    document.querySelectorAll('#client-credentials-filter-modal option').forEach((option: HTMLOptionElement) => {
      if (option.value === defaultOption) {
        option.setAttribute('selected', 'selected')
      } else {
        option.removeAttribute('selected')
      }
    })
  }

  /**
   * Reset filters to default values
   */
  resetFilters(): void {
    this.selectedPortal = this.NO_PORTAL_FILTER_STRING
    document.querySelectorAll('#client-credentials-filter-modal option').forEach((option: HTMLOptionElement) => {
      if (option.value === this.selectedPortal) {
        option.setAttribute('selected', 'selected')
      } else {
        option.removeAttribute('selected')
      }
    })
    this.userSelectionForEnabledDisabledFilter = 'all'
    this.enabledFilterControl.setValue(this.userSelectionForEnabledDisabledFilter)
  }

  changePortal(portal: Event): void {
    this.selectedPortal = (<HTMLSelectElement>portal.target).value
  }

  /**
   * Close modal and return selected filters
   *
   * @memberof ClientCredentialFilterComponent
   */
  applyFilters(): void {
    const portal = this.selectedPortal
    const enabledFilter = this.enabledFilterControl.value
    this.modal.close({ enabledFilter, portal })
  }
}
