//Manages table
import { OrgService } from 'app/admin/org/org.service'
import { IntegrationBillingProviderService } from './services/integrationBillingProvider/ibp.service'

export class TableService {
  constructor(private orgService: OrgService, private ibpService: IntegrationBillingProviderService) {}
  serviceMap = {
    integrationBillingprovider: this.ibpService,
  }
  get(table: string, org: string) {
    const service = this.serviceMap[table]
    if (!service) return
    return service['get'](org)
  }
  delete(id: string) {
    return
  }
}
