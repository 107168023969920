import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

/**
 * Component to display a spinning loading indicator
 *
 * @export
 * @class LoadingComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  imports: [CommonModule, NgbModule],
  standalone: true,
})
export class LoadingComponent implements OnInit {
  @Input() outerClass: string | string[] | Set<string> | { [klass: string]: any }
  @Input() innerClass: string | string[] | Set<string> | { [klass: string]: any }

  constructor() {}

  ngOnInit(): void {}
}
