import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { ListResponseMetaData, ObserveAccessControl, QueryObserveAccessControlsArgs } from 'generated/graphql'
import { ObserveAccessControlsService } from 'app/admin/observe-access-controls/observe-access-controls.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { first, takeUntil, tap } from 'rxjs/operators'
import { patternCanBeInterpreted } from '../observe-access-control-edit/parse-acl-patterns'
import { Subject } from 'rxjs'
import { escape as htmlEscape } from 'lodash'

@Component({
  selector: 'app-observe-access-control-list',
  templateUrl: './observe-access-control-list.component.html',
  styleUrls: ['./observe-access-control-list.component.scss'],
})
export class ObserveAccessControlListComponent implements OnInit, OnDestroy {
  @Input()
  set searchText(text: string) {
    this._searchText = text.trim()

    this.onPageUpdate({newPageNumber: 1})
  }

  @Input()
  set patternToMatch(val: string) {
    if (val && val.length > 2) {
      this.testRegex(val)
    } else {
      this.matchingRegexIndexes = []
    }
  }

  @Output()
  createRuleForAccessControl = new EventEmitter<ObserveAccessControl>()

  accessControlMetaData: ListResponseMetaData = { limit: 100, offset: 0, sort: 'created_at:desc', total: 0 }
  page = new UntypedFormControl(1)
  OACpageSize: number = 100

  observeAccessControls: ObserveAccessControl[] = []
  isLoading = false
  lastModifiedAclDate: string = ''
  matchingRegexIndexes: number[] = []
  selectedACLId: string = ''
  patternCanBeInterpreted = patternCanBeInterpreted
  destroy$: Subject<void> = new Subject<void>()

  private _searchText = ''

  constructor(
    private observeAccessControlService: ObserveAccessControlsService,
    private modal: NgbModal,
    private toast: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.getLastModifiedDate()
    await this.getObserveAccessControls()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onPageUpdate($event: PaginationUpdateEvent): void {
    this.page.patchValue($event.newPageNumber)
    this.getObserveAccessControls()
  }

  async getLastModifiedDate(): Promise<void> {
    const result = await this.observeAccessControlService.getLastModifiedAclDate().pipe(first()).toPromise()
    this.lastModifiedAclDate = result.data?.lastModifiedAclDate || ''
  }

  async getObserveAccessControls(): Promise<void> {
    this.selectedACLId = ''
    this.isLoading = true
    const offset = (this.page.value - 1) * this.OACpageSize
    const variables: QueryObserveAccessControlsArgs = {
      sort: this.accessControlMetaData?.sort,
      offset,
      search: this._searchText,
      limit: this.OACpageSize,
    }
    this.accessControlMetaData = {
      ...this.accessControlMetaData, 
      offset
    }

    this.observeAccessControlService.searchObserveAccessControls(variables).pipe(
      takeUntil(this.destroy$),
      tap(() => {this.isLoading = true})
    ).subscribe(result => {
      this.observeAccessControls = [...result.data.observeAccessControls.entities]
      this.accessControlMetaData.total = this.observeAccessControlService.getMeta()?.total || 0
      this.isLoading = false
    },
    (err) => {
      this.toast.error(`An error occured fetching observe access controls ${err}`)
      this.isLoading = false
    })
  }

  confirmDelete(controlID: string, $event: Event): void {
    $event.stopPropagation()
    let accessControl: ObserveAccessControl = this.observeAccessControls.find((OAC) => OAC.id === controlID)
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete Observe Access Control'
    modalRef.componentInstance.body = `Delete Observe Access Control for "${htmlEscape(accessControl?.pattern)}"?`
    modalRef.componentInstance.yes = 'Delete Access Control'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteObserveAccessControl(controlID)
      },
      (dismissed) => {},
    )
  }

  async deleteObserveAccessControl(controlId: string): Promise<void> {
    try {
      await this.observeAccessControlService.deleteObserveAccessControl(controlId)
      this.toast.success('Observe Access Control successfully deleted')
      await this.getObserveAccessControls()
    } catch (err) {
      this.toast.error(`Could not delete Observe access control ${err}`)
    }
  }

  createRule(accessControl?: ObserveAccessControl): void {
    this.selectedACLId = accessControl?.id || ''
    this.createRuleForAccessControl.emit(accessControl)
  }

  testRegex(text: string): void {
    this.matchingRegexIndexes = []
    this.observeAccessControls?.forEach((control, index) => {
      let regexp = new RegExp(control.pattern)

      if (regexp.test(text) || control.pattern.substring(0, text.length) === text) {
        this.matchingRegexIndexes.push(index)
      }
    })
    if (this.matchingRegexIndexes.length === 0) {
      this.toast.error('This site is Denied due to default denial rule.', 'no matching regex found')
    }
  }

  get matchClass(): 'allowed' | 'denied' | 'multi' {
    if (this.matchingRegexIndexes?.length === 1) {
      return this.observeAccessControls[this.matchingRegexIndexes[0]]?.allowed ? 'allowed' : 'denied'
    } else if (this.matchingRegexIndexes?.length > 1) {
      let matches = this.observeAccessControls.filter((control, index) => this.matchingRegexIndexes.includes(index))
      let sameValue: 'allowed' | 'denied' = matches[0]?.allowed ? 'allowed' : 'denied'
      if (matches.some((match) => match.allowed != matches[0].allowed)) {
        return 'multi'
      }
      return sameValue
    }
    return null
  }
}
