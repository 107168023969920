import { Pipe, PipeTransform } from '@angular/core'
import { startCase } from '../utils/startCase'

@Pipe({
  name: 'startCase',
  standalone: true,
})
export class StartCasePipe implements PipeTransform {
  transform(value: string): string {
    return startCase(value)
  }
}
