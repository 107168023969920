import { CommonModule } from '@angular/common'
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'vx-accordion',
  templateUrl: './vx-accordion.component.html',
  styleUrls: ['./vx-accordion.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class VxAccordionComponent {
  @Input() @HostBinding('class.is-collapsed') isCollapsed = false
  @Input() allowCollapse: boolean = true
  @Output() onToggled = new EventEmitter<boolean>()

  constructor(public elementRef: ElementRef) {}
}
