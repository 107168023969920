<div class="border my-2 pl-0 text-alt-dark-gray" [ngClass]="'border-alt-' + borderColor">
  <div class="custom-control custom-checkbox w-100 pl-2 py-2">
    <div class="ml-5 mr-3 d-flex justify-content-between">
      <span>
        <input
          type="checkbox"
          class="custom-control-input"
          [id]="'formExtractionField-' + fieldKey"
          [formControl]="isFieldIncludedInVxPageTypeRulesForm"
          (click)="$event.stopPropagation(); handleCheckboxClick()"
        />
        <label class="custom-control-label" [for]="'formExtractionField-' + fieldKey">{{ fieldName }}</label>
      </span>
      <div *ngIf="isFieldIncludedInVxPageTypeRulesForm.value && !fieldLabelFormControl.value">
        <div class="vx-page-badge text-center rounded text-alt-orange" container="body">
          <i class="fa fa-exclamation-triangle"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isFieldIncludedInVxPageTypeRulesForm.value" class="mx-3 py-2">
    <input
      type="text"
      class="form-control border-alt-light-gray text-alt-medium-gray"
      placeholder="Look for..."
      [formControl]="fieldLabelFormControl"
      (focus)="isFocused = true"
      (blur)="isFocused = false"
    />
  </div>
</div>
