import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ToastService } from 'app/shared/services/toast.service'
import { cloneDeep, maxBy } from 'lodash'
import * as paper from 'paper'
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs'
import { map, pairwise, startWith, switchMap, takeUntil } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'

const computedStyle = getComputedStyle(document.body)

export interface Annotation {
  rect: Rect
  id?: string
  highlighted?: boolean
  key?: string
  value?: string
  isLoading?: boolean
  ocrTextIds?: string[]
  /*
   * The value that is selected for the wordOrderRank field
   * is the minimum of all ocrTexts.
   */
  wordOrderRank?: number
  confidence?: number
  required?: boolean
  anchor?: boolean
}

export type Rect = {
  x: number
  y: number
  w: number
  h: number
}

/**
 * Page to display annotations for an image
 *
 * @export
 * @class ImageAnnotateComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-image-annotate',
  templateUrl: './image-annotate.component.html',
  styleUrls: ['./image-annotate.component.scss'],
  host: {
    class: 'h-100',
  },
})
export class ImageAnnotateComponent implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  @Input() imageUrl: string = ''
  @Input() annotations: Annotation[]
  @Input() selectedAnnotationId: string
  @Input() annotationTooltipCallback: (annotation: Annotation) => string[]
  @Output() onAnnotateCreate = new EventEmitter<Annotation>()
  @Output() onAnnotateEdit = new EventEmitter<Annotation>()
  @Output() onAnnotateSelect = new EventEmitter<Annotation>()
  @Output() onAnnotateDelete = new EventEmitter<string>()

  @ViewChild('canvasWrapper') canvasWrapper: ElementRef<HTMLDivElement>
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>

  @ViewChild('imageContainer') imageContainer: ElementRef<HTMLImageElement>

  @ViewChild('btnCopy') btnCopy: ElementRef
  @ViewChild('btnDelete') btnDelete: ElementRef
  panningTool: paper.Tool = null
  paperProject$ = new BehaviorSubject<paper.Project>(null)

  canvasModes = new UntypedFormGroup({
    panzoom: new UntypedFormControl(false),
    draw: new UntypedFormControl(false),
    select: new UntypedFormControl(false),
  })

  canvasClass = ''

  image: HTMLImageElement = null

  imageUrlChanges$ = new BehaviorSubject<string>(this.imageUrl)
  destroy$: Subject<void> = new Subject<void>()

  constructor(private toast: ToastService) {}
  ngOnInit(): void {
    combineLatest([
      this.canvasModes.valueChanges.pipe(startWith(this.canvasModes.value), pairwise()),
      // Only subscribe to this when paper is initialized
      this.paperProject$,
    ])
      .pipe(
        map(([canvasModes, _]) => {
          return canvasModes
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(([old, curr]) => {
        const { panzoom, draw, select } = curr
        if (draw && !old.draw) {
          this.setCanvasMode('draw')
          this.canvasModes.patchValue({ panzoom: false, select: false })
        } else if (select && !old.select) {
          this.setCanvasMode('select')
          this.canvasModes.patchValue({ panzoom: false, draw: false })
        } else if (panzoom && !old.panzoom) {
          this.setCanvasMode('panzoom')
          this.canvasModes.patchValue({ draw: false, select: false })
        } else if (!draw && !select && !panzoom) {
          this.setCanvasMode('clear')
        }
      })
  }

  ngAfterViewInit(): void {
    this.imageUrlChanges$
      .asObservable()
      .pipe(
        switchMap((imageUrl: string) => {
          return this.initializeCanvas(imageUrl)
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (paperProject) => this.paperProject$.next(paperProject),
        (err) => this.toast.error(err?.message, JSON.stringify(err)),
      )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedAnnotationId || changes.annotations) {
      this.clearRects(this.paperProject$.getValue())
      this.drawAllRects()
    }

    if (changes.imageUrl && changes.imageUrl?.previousValue !== changes.imageUrl?.currentValue) {
      // Trigger canvas re-initialization
      this.imageUrlChanges$.next(changes.imageUrl.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.imageUrlChanges$.complete()
    this.destroy$.next()
    this.destroy$.complete()
  }

  initializeCanvas(imageUrl: string): Observable<paper.Project> {
    return new Observable((subscriber) => {
      this.image = new Image()
      this.image.onabort = () => {
        subscriber.error(new Error('Failed to load image'))
      }
      this.image.onload = () => {
        const paperProject = new paper.Project('image-annotate-canvas')
        paperProject.activate()
        paperProject.currentStyle.selectedColor = new paper.Color(computedStyle.getPropertyValue('--success'))
        const imgRaster = new paper.Raster(this.image)
        imgRaster.bounds = new paper.Rectangle(
          new paper.Point(0, 0),
          new paper.Point(this.image.width, this.image.height),
        )

        const widthRatio = this.canvas.nativeElement.clientWidth / this.image.width
        const heightRatio = this.canvas.nativeElement.clientHeight / this.image.height
        if (widthRatio < heightRatio) {
          const verticalGutterSpace = this.canvas.nativeElement.clientHeight - this.image.height * widthRatio
          paperProject.view.scale(widthRatio, new paper.Point(0, verticalGutterSpace / 2))
        } else {
          const horizontalGutterSpace = this.canvas.nativeElement.clientWidth - this.image.width * heightRatio
          paperProject.view.scale(heightRatio, new paper.Point(horizontalGutterSpace / 2, 0))
        }

        paperProject.view.viewSize.width = this.canvas.nativeElement.clientWidth
        paperProject.view.viewSize.height = this.canvas.nativeElement.clientHeight

        const annotationLayer = new paper.Layer()

        paperProject.addLayer(annotationLayer)
        annotationLayer.activate()
        this.clearRects(paperProject)
        this.drawAllRects()

        this.canvasModes.setValue({
          panzoom: true,
          draw: false,
          select: false,
        })

        subscriber.next(paperProject)
        subscriber.complete()
      }
      this.image.src = imageUrl
    })
  }

  /**
   * clears all of the box annotations on the image by clearing the entire canvas and then redrawing the image
   *
   * @private
   * @memberof ImageAnnotateComponent
   */
  private clearRects(paperProject: paper.Project): void {
    paperProject?.activeLayer?.removeChildren()
  }

  /**
   * Draw a rectangle on the canvas
   *
   * @private
   * @param {Rect} rect
   * @param {boolean} [highlighted]
   * @memberof ImageAnnotateComponent
   */
  private drawRect(rect: Rect, annotationId: string, selected: boolean, highlighted: boolean): void {
    const { x, y, w, h } = rect
    const color =
      selected || highlighted
        ? new paper.Color(computedStyle.getPropertyValue('--success'))
        : new paper.Color(computedStyle.getPropertyValue('--alt-light-gray'))
    color.alpha = 0.3
    new paper.Path.Rectangle({
      x,
      y,
      width: w,
      height: h,
      strokeWidth: 6,
      strokeColor: color,
      selected,
      data: {
        annotationId,
      },
    })
  }

  /**
   * Draw all rectangles on the canvas
   *
   * @private
   * @param {string} selected
   * @memberof ImageAnnotateComponent
   */
  private drawAllRects(): void {
    this.annotations.forEach((a) => {
      this.drawRect(a.rect, a.id, a.id == this.selectedAnnotationId, a.highlighted)
    })
  }

  /**
   * Add a new annotation
   *
   * @private
   * @param {*} rect
   * @memberof ImageAnnotateComponent
   */
  private addNode(rect: Rect): Annotation {
    let annotation: Annotation = {
      id: uuid(),
      rect,
      highlighted: true,
      key: undefined,
      value: undefined,
      isLoading: false,
      ocrTextIds: [],
      confidence: undefined,
      required: false,
      anchor: false,
    }
    this.onAnnotateCreate.emit(annotation)
    return annotation
  }

  /**
   * Handles cases where the user didn't draw the box from top left to bottom right.
   * Returns the rectangle in all positive values
   *
   * @private
   * @param {Rect} rect
   * @return {*}  {Rect}
   * @memberof ImageAnnotateComponent
   */
  private getRectInAllPositiveValues(rect: Rect): Rect {
    let postiveValuesRect = cloneDeep(rect)
    if (postiveValuesRect.w < 0) {
      postiveValuesRect.w = -1 * postiveValuesRect.w
      postiveValuesRect.x -= postiveValuesRect.w
    }
    if (postiveValuesRect.h < 0) {
      postiveValuesRect.h = -1 * postiveValuesRect.h
      postiveValuesRect.y -= postiveValuesRect.h
    }

    return postiveValuesRect
  }

  /**
   * Set the canvas mode for editing
   *
   * @private
   * @param {('clear' | 'draw' | 'select' | 'panzoom')} mode
   * @memberof ImageAnnotateComponent
   */
  private setCanvasMode(mode: 'clear' | 'draw' | 'select' | 'panzoom'): void {
    this.canvas.nativeElement.onwheel = null
    const paperProject = this.paperProject$.getValue()
    if (paperProject) {
      paperProject.view.onClick = null
      paperProject.view.onMouseDown = null
      paperProject.view.onMouseMove = null
      paperProject.view.onMouseDrag = null
      paperProject.view.onMouseUp = null
    }
    this.panningTool?.remove()

    if (mode === 'clear') {
      this.canvasClass = ''
    } else if (mode === 'panzoom') {
      // stolen from https://codepen.io/hichem147/pen/dExxNK
      this.canvas.nativeElement.onwheel = function (event) {
        let newZoom = paper.view.zoom
        const oldZoom = paper.view.zoom

        if (event.deltaY < 0) {
          newZoom = paper.view.zoom * 1.05
        } else {
          newZoom = paper.view.zoom * 0.95
        }

        const beta = oldZoom / newZoom

        const mousePosition = new paper.Point(event.offsetX, event.offsetY)

        //viewToProject: gives the coordinates in the Project space from the Screen Coordinates
        const viewPosition = paper.view.viewToProject(mousePosition)

        const mpos = viewPosition
        const ctr = paper.view.center

        const pc = mpos.subtract(ctr)
        const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr)

        paper.view.zoom = newZoom
        paper.view.center = paper.view.center.add(offset)

        event.preventDefault()
      }
      this.panningTool = new paper.Tool()
      this.panningTool.onMouseDrag = function (e: paper.ToolEvent) {
        const pan_offset = e.point.subtract(e.downPoint)
        paper.view.center = paper.view.center.subtract(pan_offset)
      }
      this.canvasClass = 'grabbable'
    } else if (mode === 'draw') {
      let initPoint: paper.Point
      let drawnRect: paper.Shape.Rectangle
      paperProject.view.onMouseDown = (event: paper.MouseEvent) => {
        initPoint = event.point
      }
      paperProject.view.onMouseDrag = (event: paper.MouseEvent) => {
        if (!drawnRect) {
          drawnRect = new paper.Shape.Rectangle(initPoint, event.point)
          drawnRect.strokeWidth = Math.max(this.image?.width / 400, 4) || 4
          drawnRect.strokeColor = new paper.Color(computedStyle.getPropertyValue('--success'))
          drawnRect.selected = true
        } else {
          drawnRect.bounds.left = Math.min(initPoint.x, event.point.x)
          drawnRect.bounds.top = Math.min(initPoint.y, event.point.y)
          drawnRect.size.width = Math.abs(initPoint.x - event.point.x)
          drawnRect.size.height = Math.abs(initPoint.y - event.point.y)
        }
      }
      paperProject.view.onMouseUp = (event: paper.MouseEvent) => {
        const newAnnotation = this.addNode(
          this.getRectInAllPositiveValues({
            x: initPoint.x,
            y: initPoint.y,
            w: event.point.x - initPoint.x,
            h: event.point.y - initPoint.y,
          }),
        )
        this.drawRect(newAnnotation.rect, newAnnotation.id, true, true)
        drawnRect?.remove()
        drawnRect = null
        initPoint = null
      }
      this.canvasClass = 'crosshairs'
    } else if (mode === 'select') {
      let itemBeingResized: paper.Item
      let preventAnnotationSelect = false // used to not have resize and select events occur at same time
      paperProject.view.onMouseDown = (e: paper.MouseEvent) => {
        const itemWithEdgeClicked = paperProject.activeLayer.getItem((item: paper.Item) => item.hitTest(e.point))
        if (itemWithEdgeClicked && itemWithEdgeClicked.data.annotationId === this.selectedAnnotationId) {
          itemBeingResized = itemWithEdgeClicked
          itemBeingResized.data.furthestCorner = maxBy(
            [
              itemBeingResized.bounds.topLeft,
              itemBeingResized.bounds.topRight,
              itemBeingResized.bounds.bottomLeft,
              itemBeingResized.bounds.bottomRight,
            ],
            (point: paper.Point) => point.getDistance(e.point),
          )
        }
      }
      paperProject.view.onMouseDrag = (e: paper.MouseEvent) => {
        if (itemBeingResized) {
          itemBeingResized.bounds = new paper.Rectangle(itemBeingResized.data.furthestCorner, e.point)
        }
      }
      paperProject.view.onMouseUp = (e: paper.MouseEvent) => {
        if (itemBeingResized) {
          let updatedAnnotation = this.annotations.find((a) => a.id === itemBeingResized.data.annotationId)
          // extra check here in case the annotation is deleted while it is being resized
          if (updatedAnnotation) {
            updatedAnnotation.rect = {
              x: itemBeingResized.bounds.x,
              y: itemBeingResized.bounds.y,
              w: itemBeingResized.bounds.width,
              h: itemBeingResized.bounds.height,
            }
            this.onAnnotateEdit.emit(updatedAnnotation)
          }
          itemBeingResized.data.furthestCorner = undefined
          itemBeingResized = null
          preventAnnotationSelect = true
        }
      }
      paperProject.view.onClick = (e: paper.MouseEvent) => {
        if (!preventAnnotationSelect) {
          paperProject.activeLayer
            .getItems((item: paper.Item) => item.hitTest(e.point) || item.contains(e.point))
            .forEach((item) => {
              this.onAnnotateSelect.emit(this.annotations.find((a) => a.id === item.data.annotationId))
            })
        } else {
          preventAnnotationSelect = false
        }
      }
      let tooltipGroup: paper.Group
      paperProject.view.onMouseMove = (e: paper.MouseEvent) => {
        let rectUnderHover = paperProject.activeLayer.getItem(
          (item: paper.Item) => item.hitTest(e.point) || item.contains(e.point),
        )
        if (rectUnderHover) {
          const matchingAnnotation = this.annotations.find((a) => a.id === rectUnderHover.data.annotationId)
          if (matchingAnnotation) {
            let tooltipText = this.annotationTooltipCallback
              ? this.annotationTooltipCallback(matchingAnnotation)
              : [matchingAnnotation.value]
            this.clearRects(paperProject)
            this.drawAllRects()
            tooltipGroup = this.drawTooltip(rectUnderHover, tooltipText)
          }
        } else if (tooltipGroup) {
          tooltipGroup.remove()
          tooltipGroup = null
        }
      }
      this.canvasClass = 'pointer'
    }
  }

  private drawTooltip(paperItem: paper.Item, texts: string[]): paper.Group {
    const white = computedStyle.getPropertyValue('--alt-white')
    const gray = '#4d5554'
    const tooltipTrianglePosition = new paper.Point(paperItem.position)
    tooltipTrianglePosition.y -= paperItem.bounds.height
    const tooltipTriangle = new paper.Path.RegularPolygon(tooltipTrianglePosition, 3, 50)
    tooltipTriangle.rotate(180)
    tooltipTriangle.fillColor = new paper.Color(gray)
    const tooltipPosition = new paper.Point(tooltipTrianglePosition)
    tooltipPosition.y -= 10 + 64 * texts.length
    const tooltipText = new paper.PointText(tooltipPosition)
    tooltipText.content = texts.join('\n')
    tooltipText.fontSize = '64px'
    tooltipText.fontFamily = 'serif'
    tooltipText.strokeColor = new paper.Color(white)
    tooltipText.fillColor = new paper.Color(white)
    tooltipText.position.x -= tooltipText.bounds.width / 2
    const backgroundRectangle = new paper.Path.Rectangle({
      x: tooltipPosition.x - tooltipText.bounds.width / 2 - 10,
      y: tooltipPosition.y - 60,
      width: tooltipText.bounds.width + 20,
      height: tooltipText.bounds.height + 10,
      strokeColor: new paper.Color(gray),
      fillColor: new paper.Color(gray),
    })
    tooltipText.bringToFront()
    return new paper.Group([tooltipTriangle, backgroundRectangle, tooltipText])
  }

  /**
   * Copy an existing node
   *
   * @memberof ImageAnnotateComponent
   */
  copyNode(): void {
    let og = this.annotations.find((node) => node.id === this.selectedAnnotationId)
    let rect = { x: og.rect.x, w: og.rect.w, y: og.rect.y - 10, h: og.rect.h }
    if (rect.y < 0) rect.y += 20
    this.addNode(rect)
    this.btnCopy.nativeElement.blur()
  }

  /**
   * Remove an existing node
   *
   * @memberof ImageAnnotateComponent
   */
  deleteNode(): void {
    this.onAnnotateDelete.emit(this.selectedAnnotationId)
    this.btnDelete.nativeElement.blur()
  }
}
