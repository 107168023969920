<h2>Page In development...</h2>
<section class="row mb-4">
    <!-- Database Selection -->
    <label for="select-table" class="col-sm-3 col-form-label">Select Table:</label>
    <div class="col-sm-9">
    <select id="select-table" class="form-control" (change)="onDatabaseSelect($event)">
        <option disabled selected>Select Table</option>
      <option *ngFor="let endpoint of endpoints" value="{{ endpoint.value }}" >
        {{ endpoint.name }}
      </option>
    </select></div>
  </section>
  <viewer-component [selectedEndpoint] = "selectedEndpoint"></viewer-component>
