import { Component, OnDestroy, OnInit } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { VxRuleTemplateService } from 'app/vision-x/services/vx-rule-template.service'
import { VxRuleEditorStateService } from 'app/vx-rule-editor/vx-rule-editor-state.service'
import {
  VxDocumentType,
  VxPageTypeFormField,
  VxRuleTemplate,
  VxRuleTemplateField,
  VxRuleTemplateList,
} from 'generated/graphql'
import { isNil, sortBy } from 'lodash'
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs'
import { catchError, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'

export const NEW_FORM_FIElD_PREFIX = 'new-formField'

type PotentialFormField = {
  id: string
  highlighted: boolean
  checked: boolean
  checkboxDisabled: boolean
  // only purpose of this field is to uncheck it when a template is changed/removed
  checkForcedBySelectedTemplate: boolean
  vxRuleTemplateFieldId: string
  key: string
  name: string
  label: string
}

@Component({
  selector: 'app-vx-form-rule-template-pane',
  templateUrl: './vx-form-rule-template-pane.component.html',
  host: {
    class: 'd-flex flex-column p-2',
  },
})
export class VxFormRuleTemplatePaneComponent implements OnInit, OnDestroy {
  isOpen: boolean = true
  isShowAll$: BehaviorSubject<boolean> = new BehaviorSubject(true)
  selectedFields: string[] = []

  destroy$ = new Subject<void>()
  selectedVxDocumentType$: BehaviorSubject<VxDocumentType> = new BehaviorSubject(null)
  vxRuleTemplates$: Observable<VxRuleTemplate[]> = this.vxRuleTemplateService.fetchVxRuleTemplates().pipe(
    catchError((err): Observable<ApolloQueryResult<{ vxRuleTemplates: VxRuleTemplateList }>> => {
      this.toast.error(parseGraphQLError(err, 'Could not find rule templates to display'), JSON.stringify(err))
      return of({ data: null, error: null, loading: false, networkStatus: 7 })
    }),
    map((result) => result?.data?.vxRuleTemplates?.entities ?? []),
    takeUntil(this.destroy$),
  )
  potentialFormFields$: BehaviorSubject<PotentialFormField[]> = new BehaviorSubject([])
  visibleFormFields$: Observable<PotentialFormField[]> = combineLatest([
    this.potentialFormFields$,
    this.isShowAll$,
  ]).pipe(
    map(([potentialFormFields, isShowAll]) => {
      if (isShowAll) {
        return potentialFormFields
      } else {
        return potentialFormFields.filter((potentialFormField) => potentialFormField.checked)
      }
    }),
    takeUntil(this.destroy$),
  )

  // whenever we get new page type form fields, we want to clear our potentialFormFields in order to get a "clean working slate"
  vxPageTypeFormFields$: Observable<VxPageTypeFormField[]> = this.state.vxPageTypeFormFields$.pipe(
    tap(() => this.potentialFormFields$.next([])),
    takeUntil(this.destroy$),
  )

  onPotentialFormFieldChange: Subject<{ potentialFormFieldId: string; checked?: boolean; label?: string }> =
    new Subject()

  convertRuleTemplatesAndSelectedDocumentTypeToPotentialFormFields = (
    vxRuleTemplates: VxRuleTemplate[],
    selectedVxDocumentType: VxDocumentType,
    vxPageTypeFormFields: VxPageTypeFormField[],
  ): PotentialFormField[] => {
    // If a specific document type is selected, we want to highlight those fields
    // Since one field can be in multiple templates but we only want to show every field once,
    // We add the selected template fields first to ensure they will be highlighted, then add the fields from the other templates
    let { potentialFormFields, nonSelectedRuleTemplates } = this.populateFormFieldsFromSelectedVxDocumentType(
      vxRuleTemplates,
      selectedVxDocumentType,
      vxPageTypeFormFields,
    )

    // Now take care of the non-selected templates
    potentialFormFields = this.convertRuleTemplateFieldsToPotentialFormFields(
      nonSelectedRuleTemplates,
      potentialFormFields,
      vxPageTypeFormFields,
    )

    // we auto-select form fields if a rule template is selected (i.e. form field is highlighted) and there are no form fields selected yet
    if (!potentialFormFields.some((potentialFormField) => potentialFormField.checked)) {
      potentialFormFields.forEach((potentialFormField) => (potentialFormField.checked = potentialFormField.highlighted))
    }

    // Sort the fields alphabetically
    return sortBy(potentialFormFields, (potentialFormField) => potentialFormField.name.toLowerCase())
  }

  /**
   * Creates form fields based off the currently selected VxDocumentType by linking the VxDocumentType to a VxRuleTemplate (giving them special "highlighted" treatment because their corresponding VxDocumentType is selected)
   * It only creates the fields for the selected VxDocumentType and reports back the rule templates that do not correspond to the selected rule template so that {@link convertRuleTemplateFieldsToPotentialFormFields} can properly populate the rest
   * It also takes into account the existing form fields so that it can display to the user the form fields that already have been configured
   *
   * @param {VxRuleTemplate[]} vxRuleTemplates All of the rule templates whose fields we must convert to potential form fields
   * @param {VxDocumentType} selectedVxDocumentType The currently selected document type
   * @param {VxPageTypeFormField[]} vxPageTypeFormFields Form fields that already exist that may correspond to a vxRuleTemplateField
   */
  private populateFormFieldsFromSelectedVxDocumentType = (
    vxRuleTemplates: VxRuleTemplate[],
    selectedVxDocumentType: VxDocumentType,
    vxPageTypeFormFields: VxPageTypeFormField[],
  ): { nonSelectedRuleTemplates: VxRuleTemplate[]; potentialFormFields: PotentialFormField[] } => {
    if (selectedVxDocumentType) {
      const templateForVxDocumentType = vxRuleTemplates.find(
        (ruleTemplate) => ruleTemplate.vxDocumentTypeId === selectedVxDocumentType.id,
      )
      if (templateForVxDocumentType) {
        const potentialFormFields: PotentialFormField[] = templateForVxDocumentType.fields.map((vxRuleTemplateField) =>
          this.convertSingleVxRuleTemplateFieldToPotentialFormField(
            vxRuleTemplateField,
            vxPageTypeFormFields,
            true,
            vxRuleTemplateField.required,
          ),
        )

        // since we already added the fields for the highlighted template, we do not need to consider this template again when adding the rest of the fields
        const nonSelectedRuleTemplates = vxRuleTemplates.filter(
          (vxRuleTemplate) => vxRuleTemplate.id !== templateForVxDocumentType.id,
        )

        return { potentialFormFields, nonSelectedRuleTemplates }
      }
    }

    return { potentialFormFields: [], nonSelectedRuleTemplates: vxRuleTemplates }
  }

  /**
   * Creates form fields based off all of the VxRuleTemplates that do not correspond to the selected VxDocumentType
   * Because one VxRuleTemplateField can possibly correspond to multiple VxRuleTemplates, it takes care to not duplicate any fields that appear in multiple rule templates
   * It also takes into account the existing form fields so that it can display to the user the form fields that already have been configured
   *
   * @param {VxRuleTemplate[]} vxRuleTemplates All of the non-selected rule templates whose fields we must convert to potential form fields
   * @param {PotentialFormField[]} previouslyPopulatedFormFields All of the form fields created from the rule template corresponding to the selected document type
   * @param {VxPageTypeFormField[]} vxPageTypeFormFields Form fields that already exist that may correspond to a vxRuleTemplateField
   */
  private convertRuleTemplateFieldsToPotentialFormFields = (
    vxRuleTemplates: VxRuleTemplate[],
    previouslyPopulatedFormFields: PotentialFormField[],
    vxPageTypeFormFields: VxPageTypeFormField[],
  ): PotentialFormField[] => {
    return vxRuleTemplates.reduce((allPotentialFormFields, vxRuleTemplate) => {
      // figure out which fields in current template haven't already been included
      const fieldIdsAlreadyIncluded = allPotentialFormFields.map((field) => field.vxRuleTemplateFieldId)
      const fieldsNotYetAdded = vxRuleTemplate.fields.filter((field) => !fieldIdsAlreadyIncluded.includes(field.id))
      // since the template in this reduce is not the one selected, all fields from this template that haven't already been included shouldn't be highlighted
      allPotentialFormFields.push(
        ...fieldsNotYetAdded.map((vxRuleTemplateField) =>
          this.convertSingleVxRuleTemplateFieldToPotentialFormField(
            vxRuleTemplateField,
            vxPageTypeFormFields,
            false,
            false,
          ),
        ),
      )
      return allPotentialFormFields
    }, previouslyPopulatedFormFields)
  }

  /**
   * Creates a potential form field by taking a vxRuleTemplateField and cross-referencing it with the list of existing VxPageTypeFormFields
   * It prepopulates an ID and label text if there is a VxPageTypeFormField that matches the VxRuleTemplateField, otherwise it gives it a mock ID and empty label
   *
   * @param vxRuleTemplateField The VxRuleTemplateField for which we make a potential form field
   * @param existingVxPageTypeFormFields A list of existing VxPageTypeFormFields so we can tell if there has already been configuration for that particular VxRuleTemplateField
   * @param highlighted Whether the form field should be highlighted (as of time of writing, only happens when a rule template field's template is selected and the field itself is required in that template)
   */
  private convertSingleVxRuleTemplateFieldToPotentialFormField = (
    vxRuleTemplateField: VxRuleTemplateField,
    existingVxPageTypeFormFields: VxPageTypeFormField[],
    highlighted: boolean,
    fieldRequiredByTemplate: boolean,
  ): PotentialFormField => {
    const matchingVxPageTypeFormField = existingVxPageTypeFormFields.find(
      (vxPageTypeFormField) => vxPageTypeFormField.key === vxRuleTemplateField.key,
    )
    const id = matchingVxPageTypeFormField?.id ?? `${NEW_FORM_FIElD_PREFIX}-${uuid()}`

    // if we have a potentialFormField already populated, we will want to keep its changes instead of what came from API
    const existingFormField = this.potentialFormFields$
      .getValue()
      .find((existingFormField) => existingFormField.key === vxRuleTemplateField.key)
    if (existingFormField) {
      return {
        ...existingFormField,
        // handle situation where somebody picked a template that checked a field, but then changed away from that template and we should uncheck the field
        checked:
          fieldRequiredByTemplate || (existingFormField.checked && !existingFormField.checkForcedBySelectedTemplate),
        checkboxDisabled: fieldRequiredByTemplate,
        checkForcedBySelectedTemplate: fieldRequiredByTemplate && !existingFormField.checked,
        highlighted,
      }
    }

    // returning here should only happen on sync with updatedFormFields
    const label = matchingVxPageTypeFormField?.pageText ?? ''
    return {
      id,
      vxRuleTemplateFieldId: vxRuleTemplateField.id,
      key: vxRuleTemplateField.key,
      name: vxRuleTemplateField.name,
      label,
      checked: fieldRequiredByTemplate || !!matchingVxPageTypeFormField,
      checkboxDisabled: fieldRequiredByTemplate,
      checkForcedBySelectedTemplate: fieldRequiredByTemplate && !label,
      highlighted,
    }
  }

  toggleListView(view: string) {
    this.isShowAll$.next(view === 'all')
  }

  constructor(
    private vxRuleTemplateService: VxRuleTemplateService,
    private toast: ToastService,
    private state: VxRuleEditorStateService,
  ) {}

  ngOnInit(): void {
    // whenever selected page types change we want to clear the selected document type
    this.state.vxPageType$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.selectedVxDocumentType$.next(null)
    })

    // Whenever the rule templates, selectedVxDocument, or loaded form fields change, we need to update our list of potential form fields
    combineLatest([this.vxRuleTemplates$, this.selectedVxDocumentType$, this.vxPageTypeFormFields$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([vxRuleTemplates, selectedVxDocumentType, vxPageTypeFormFields]) => {
        this.potentialFormFields$.next(
          this.convertRuleTemplatesAndSelectedDocumentTypeToPotentialFormFields(
            vxRuleTemplates,
            selectedVxDocumentType,
            vxPageTypeFormFields,
          ),
        )
      })

    // track changes from potential form field cards and correctly update state
    this.onPotentialFormFieldChange
      .pipe(withLatestFrom(this.potentialFormFields$))
      .subscribe(([potentialFormFieldChange, potentialFormFields]) => {
        const potentialFormField = potentialFormFields.find(
          (potentialFormField) => potentialFormField.id === potentialFormFieldChange.potentialFormFieldId,
        )
        if (potentialFormField) {
          // we should only get one update at a time
          if (!isNil(potentialFormFieldChange.checked)) {
            potentialFormField.highlighted = potentialFormFieldChange.checked
            this.onPotentialFormFieldCheckboxToggle(potentialFormField, potentialFormFieldChange.checked)
          } else if (!isNil(potentialFormFieldChange.label)) {
            this.onPotentialFormFieldLabelUpdate(potentialFormField, potentialFormFieldChange.label)
          }
        }
      })
  }

  /**
   * Handles the checkbox changing of a form field
   * When a box is checked: If it already existed we update state to not delete the field. Otherwise we update state to try and create the form field when the user clicks save
   * When a box is unchecked: If it already existed we update state to mark the field for deletion. Otherwise we update state to not create data for this potentialFormField
   *
   * @param {PotentialFormField} potentialFormField The form field whose checkbox was toggled
   * @param {boolean} checked Denotes whether the checkbox was checked or unchecked
   */
  private onPotentialFormFieldCheckboxToggle(potentialFormField: PotentialFormField, checked: boolean) {
    let formFieldUpdates = this.state.editedVxFormFields$.getValue()
    const fieldsAlreadySavedToApi = this.state.vxPageTypeFormFields$.getValue()
    const matchingFieldAlreadySaved = fieldsAlreadySavedToApi.find(
      (vxFormField) => vxFormField.key === potentialFormField.key,
    )
    if (checked) {
      if (matchingFieldAlreadySaved) {
        this.state.removePendingRemovalFormFieldId(matchingFieldAlreadySaved.id)
      } else {
        formFieldUpdates.set(potentialFormField.id, {
          id: potentialFormField.id,
          key: potentialFormField.key,
          pageText: '',
          vxPageTypeId: this.state.vxPageTypeId$.getValue(),
        })
        this.state.editedVxFormFields$.next(formFieldUpdates)
      }
    } else {
      formFieldUpdates.delete(potentialFormField.id)
      this.state.editedVxFormFields$.next(formFieldUpdates)
      if (matchingFieldAlreadySaved) {
        this.state.addPendingRemovalFormFieldId(matchingFieldAlreadySaved.id)
      }
    }
    // Now we need to update this.potentialFormFields to reflect the update from checked
    const updatedPotentialFormFields = this.potentialFormFields$.getValue()
    const formFieldToUpdate = updatedPotentialFormFields.find((formField) => formField.id === potentialFormField.id)
    formFieldToUpdate.checked = checked
    this.potentialFormFields$.next(updatedPotentialFormFields)
  }

  /**
   * Handles the label changing of a for a potential field
   * If the label change corresponds to a VxPageTypeFormField that already exists, we mark the field for update UNLESS the label update already matches the saved label for the field, in which case we remove the updates for the field from state
   * Otherwise we update state to have the latest label update for creating/updating the VxPageTypeFormField
   *
   * @param {PotentialFormField} potentialFormField The form field whose label was updated
   * @param {string} pageText The update made to the label
   */
  private onPotentialFormFieldLabelUpdate(potentialFormField: PotentialFormField, pageText: string) {
    let formFieldUpdates = this.state.editedVxFormFields$.getValue()
    const fieldsAlreadySavedToApi = this.state.vxPageTypeFormFields$.getValue()
    const matchingFieldAlreadySaved = fieldsAlreadySavedToApi.find(
      (vxFormField) => vxFormField.id === potentialFormField.id,
    )
    if (matchingFieldAlreadySaved?.pageText === pageText) {
      // in this case the field is the same state that it came from so we want to remove it from the update list
      formFieldUpdates.delete(potentialFormField.id)
      this.state.editedVxFormFields$.next(formFieldUpdates)
    } else {
      formFieldUpdates.set(potentialFormField.id, {
        id: potentialFormField.id,
        key: potentialFormField.key,
        pageText,
        vxPageTypeId: this.state.vxPageTypeId$.getValue(),
      })
      this.state.editedVxFormFields$.next(formFieldUpdates)
    }
    // Now we need to update this.potentialFormFields to reflect the update from pageText
    const updatedPotentialFormFields = this.potentialFormFields$.getValue()
    const formFieldToUpdate = updatedPotentialFormFields.find((formField) => formField.id === potentialFormField.id)
    formFieldToUpdate.label = pageText
    this.potentialFormFields$.next(updatedPotentialFormFields)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onSelectVxDocumentType(vxDocumentType: VxDocumentType) {
    this.selectedVxDocumentType$.next(vxDocumentType)
  }
}
