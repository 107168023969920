import { Injectable } from '@angular/core'
import { debug } from 'app/shared/utils/debug'
import { VxExtractionService } from 'app/vision-x/services/vx-extraction.service'
import { ExtractionRuleNode } from 'app/vx-rule-editor/utils/VxExtractionRuleNode'
import { VxRuleEditorStateService } from 'app/vx-rule-editor/vx-rule-editor-state.service'
import { ExtractionRule } from 'generated/graphql'
import { flatten } from 'lodash'
import { combineLatest, concat, from, of } from 'rxjs'
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ExtractionRuleNodeFactoryService {
  constructor(private state: VxRuleEditorStateService, private vxExtractionService: VxExtractionService) {}

  build(extractionRule: ExtractionRule, fieldGroupId: string, parentNode?: ExtractionRuleNode): ExtractionRuleNode {
    const node = new ExtractionRuleNode(extractionRule, fieldGroupId)
    node.parentNode = parentNode

    node.repeatRanges$ = combineLatest([
      this.state.vxPage$,
      node?.parentRepeatRanges$,
      node?.conditionChanges$ ?? from(null),
    ]).pipe(
      debounceTime(500),
      tap(() => debug('vxRuleEditor', `getting repeatRanges for ${node.value.dataKey}`)),
      switchMap(([vxPage, parentRepeatRanges]) =>
        concat(
          of(null), // immediately push out a new value to clear existing repeatRanges while waiting for new ones
          vxPage && (!node.hasParent || parentRepeatRanges)
            ? from(
                this.vxExtractionService.testExtractionRuleConditions(
                  node.value,
                  vxPage.processedImageFileId,
                  parentRepeatRanges,
                ),
              ).pipe(
                map((response) =>
                  flatten(
                    response.map((responseItem) =>
                      responseItem.foundRanges.map((foundRange) => ({
                        left: foundRange.left + responseItem.parentRange.left,
                        start: foundRange.start + responseItem.parentRange.start,
                        end: foundRange.end + responseItem.parentRange.start,
                      })),
                    ),
                  ),
                ),
                tap((repeatRanges) =>
                  debug('vxRuleEditor', `got repeatRanges for ${node.value.dataKey}: ${JSON.stringify(repeatRanges)}`),
                ),
              )
            : of(null).pipe(tap(() => debug('vxRuleEditor', `got no repeatRanges for ${node.value.dataKey}`))),
        ),
      ),
      shareReplay(),
    )

    if (extractionRule?.childGroups?.length) {
      for (const childNode of extractionRule.childGroups) {
        const childExtractionNode = this.build(childNode, fieldGroupId, node)
        node.childNodes[childExtractionNode.hash] = childExtractionNode
      }
    }

    return node
  }
}
