import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { NgbModule, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { UsersService } from 'app/shared/services/users.service'
import { debug } from 'app/shared/utils/debug'
import { User } from 'generated/graphql'
import { defer } from 'lodash'
import { from, merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'
import { LoadingComponent } from '../loading/loading.component'
import { CommonModule } from '@angular/common'

type DisplayedUser = { name?: string; email?: string }

/**
 * Component to search users
 *
 * @export
 * @class UserSearchComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbModule, LoadingComponent],
})
export class UserSearchComponent implements OnChanges {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Input() disabledUserIds: string[] = []
  @Input() allowedUserIds: string[] = []
  @Input() clearInput: boolean = true
  @Input() container: boolean = false
  @Input() selectedUser: string = ''

  @Output() onSelect = new EventEmitter<User>()

  searching: boolean = false

  constructor(private userService: UsersService) {}

  ngOnChanges(): void {
    if (this.selectedUser?.length) {
      setTimeout(() => {
        this.element.nativeElement.value = this.selectedUser
      })
    }
  }

  /**
   * Get users based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @return {*} {Observable<User[]>}
   * @memberof UserSearchComponent
   */
  search = (text$: Observable<string>): Observable<User[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$.pipe(delay(0))

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((term) =>
        from(this.userService.getUsers(term)).pipe(
          catchError(() => {
            return of([])
          }),
          map((users) =>
            users?.filter((user) => {
              if (this.disabledUserIds?.length && this.allowedUserIds?.length) {
                return !this.disabledUserIds?.includes(user?.id) && this.allowedUserIds.includes(user?.id)
              }
              if (this.disabledUserIds?.length) {
                return !this.disabledUserIds?.includes(user?.id)
              }
              if (this.allowedUserIds?.length) {
                return this.allowedUserIds.includes(user?.id)
              }
              return user
            }),
          ),
        ),
      ),
      tap(() => (this.searching = false)),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @param {DisplayedUser} user
   * @return {*} {string}
   * @memberof UserSearchComponent
   */
  inputFormatter = (user: DisplayedUser): string => this.displayUser(user)

  /**
   * Format typeahead results list
   *
   * @param {DisplayedUser} user
   * @return {*} {string}
   * @memberof UserSearchComponent
   */
  resultFormatter = (user: DisplayedUser): string => this.displayUser(user)

  /**
   * Display user with name & email in parens if name exists
   * otherwise only display email
   *
   * @param {DisplayedUser} user
   * @return {*} {string}
   * @memberof UserSearchComponent
   */
  displayUser(user: DisplayedUser): string {
    const name = user?.name
    const email = user?.email

    if (name) {
      return `${name} (${email})`
    }

    // The || is a guard, that hopefully we never have to hit
    return email || 'Unknown User'
  }

  /**
   * Notify consumers that a user has been selected
   *
   * @param {*} event
   * @memberof UserSearchComponent
   */
  onSelectUser(event: { item: User }): void {
    let user = event.item as User
    debug('users', 'selected a user', user)
    this.onSelect.emit(user)

    if (this.clearInput === true) {
      defer(() => (this.element.nativeElement.value = ''))
    } else {
      this.element.nativeElement.value = user?.name || user?.email
    }
  }

  /**
   * Clear typeahead input
   *
   * @param {*} event
   * @memberof UserSearchComponent
   */
  clearUser(event: Event): void {
    event.preventDefault()

    this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
