<section class ="d-flex flex-column my-2 h-100">
  <!-- Data Table -->
  <div class="d-flex flex-column flex-shrink-1 flex-basis-0 table-responsive-md">
<table *ngIf="entries.length > 0" class = "table bg-white">
  <thead class="bg-alt-light-blue text-alt-white">
  <tr>
    <th *ngFor="let field of formFields" scope="col" class="width-1 text-nowrap">{{ camelToNormalCase(field) }}</th>
    <th scope="col" class="width-1 text-nowrap">Edit</th>
  </tr>
</thead>
  <tr *ngFor="let entry of entries">
    <ng-container>
    <td *ngFor="let field of formFields">
      {{updateEntry(entry[field], field)}}
    </td>
    <td>
      <button class="btn bg-alt-light-blue text-alt-white text-nowrap" (click)="editEntry(entry)">Edit</button>
    </td>
  </ng-container>
  </tr>
</table>
<div class="col-3 d-flex justify-content-end">
  <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
</div>
<div *ngIf="entries.length > 0">
  <button class="btn btn-primary btn sm mx-2"  (click)="prevPage()" [disabled]="page === 1">Previous</button>
  <span>Page {{ page }}</span>
  <button class="btn btn-primary btn sm mx-2"  (click)="nextPage()">Next</button>
  </div>
</div>
</section>