import { Component, OnInit } from '@angular/core'
import { ExtractionRuleNodeFactoryService } from 'app/vx-rule-editor/services/extraction-rule-node-factory.service'
import { ExtractionRuleNode } from 'app/vx-rule-editor/utils/VxExtractionRuleNode'
import { ExtractionRuleTree } from 'app/vx-rule-editor/utils/VxExtractionRuleTree'
import { VxRuleEditorStateService } from 'app/vx-rule-editor/vx-rule-editor-state.service'
import { ExtractionRule } from 'generated/graphql'
import { sortBy } from 'lodash'
import md5 from 'md5'
import moment from 'moment'
import { of } from 'rxjs'
import { shareReplay, tap } from 'rxjs/operators'

export const NEW_FIELD_GROUP_PREFIX = 'new-fieldGroup'

@Component({
  selector: 'app-rule-editor-positional-rules-pane',
  templateUrl: './rule-editor-positional-rules-pane.component.html',
  styleUrls: ['./rule-editor-positional-rules-pane.component.scss'],
})
export class RuleEditorPositionalRulesPaneComponent implements OnInit {
  activeExtractionRuleNode$ = of<ExtractionRuleNode | null>(null)
  tabExtractionRulesList: ExtractionRuleNode[] = []

  constructor(public state: VxRuleEditorStateService, private nodeFactory: ExtractionRuleNodeFactoryService) {}

  ngOnInit(): void {
    this.activeExtractionRuleNode$ = this.state.activeExtractionRuleNode$.pipe(
      tap((extractionRuleNode) => this.updateTabList(extractionRuleNode)),
      shareReplay(1),
    )
  }

  showExtractionRuleParent(): void {
    const extractionRuleNode = this.state.activeExtractionRuleNode$?.getValue()
    if (!extractionRuleNode?.hasParent) {
      return
    }
    this.handleRuleSelection(extractionRuleNode.parentNode)
  }

  handleRuleSelection(selectedExtractionRuleNode?: ExtractionRuleNode): void {
    if (!selectedExtractionRuleNode && !this.state.vxExtractionRuleTree$.getValue()?.hasNodes) {
      // There is nothing to select
      this.state.activeExtractionRuleNode$.next(null)
      return
    }

    if (!selectedExtractionRuleNode) {
      this.state.setDefaultActiveExtractionRuleNode(this.state.vxExtractionRuleTree$.getValue())
      return
    }

    this.state.activeExtractionRuleNode$.next(selectedExtractionRuleNode)
  }

  addGroup(): void {
    const currentExtractionRuleNode = this.state.activeExtractionRuleNode$?.getValue()
    let currentExtractionRuleTree: ExtractionRuleTree = this.state.vxExtractionRuleTree$.getValue()
    let newExtractionRule: ExtractionRule = {
      dataKey: 'newExtractionGroup',
      __typename: 'ExtractionRule',
      repeating: false,
      startCondition: {
        __typename: 'ExtractionRuleCondition',
        text: [],
        ignore: [],
        minStartingX: 100,
        maxStartingX: 200,
        minStartingY: 100,
        maxStartingY: 200,
      },
      fields: [],
      childGroups: [],
    }

    let newExtractionRuleNode: ExtractionRuleNode
    if (!currentExtractionRuleNode?.hasParent) {
      // Does not contain parent, thus must be a root level rule
      const currentNumberOfSiblings = Object.keys(currentExtractionRuleTree.childNodes).length
      const timestamp: number = moment().unix()
      const tempFieldGroupId = `${NEW_FIELD_GROUP_PREFIX}-${md5(timestamp.toString() + (currentNumberOfSiblings + 1))}`
      newExtractionRule.dataKey = `${newExtractionRule.dataKey}${currentNumberOfSiblings + 1}`
      newExtractionRuleNode = this.nodeFactory.build(newExtractionRule, tempFieldGroupId)
      currentExtractionRuleTree = currentExtractionRuleTree.addNode(newExtractionRuleNode)
    } else {
      const currentNumberOfSiblings = Object.keys(currentExtractionRuleNode.parentNode.childNodes).length
      newExtractionRule.dataKey = `${newExtractionRule.dataKey}${currentNumberOfSiblings + 1}`
      newExtractionRuleNode = this.nodeFactory.build(
        newExtractionRule,
        currentExtractionRuleNode.fieldGroupId,
        currentExtractionRuleNode.parentNode,
      )
      currentExtractionRuleTree = currentExtractionRuleTree.addSiblingNode(
        currentExtractionRuleNode,
        newExtractionRuleNode,
      )
    }

    this.state.vxExtractionRuleTree$.next(currentExtractionRuleTree)
    this.state.activeExtractionRuleNode$.next(newExtractionRuleNode)
  }

  private updateTabList(activeExtractionRuleNode: ExtractionRuleNode) {
    if (!activeExtractionRuleNode) {
      this.tabExtractionRulesList = []
      return
    }

    if (activeExtractionRuleNode.hasParent) {
      this.tabExtractionRulesList = sortBy(
        Object.values(activeExtractionRuleNode.parentNode.childNodes),
        (node: ExtractionRuleNode) => {
          return node.value.dataKey
        },
      )
    } else {
      /* The extraction rule tree gets shuffled out of order after clicking on one of the new extraction groups
       * sortBy is to prevent shuffling out of order. Ideally we would have a date to sort by
       * but we don't maintain that on the extracionRuleNode object.
       */
      this.tabExtractionRulesList = sortBy(
        Object.values(this.state.vxExtractionRuleTree$.getValue()?.childNodes),
        (extractionRule) => {
          return extractionRule.value.dataKey
        },
      )
    }
  }
}
