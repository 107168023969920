import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { SharedModule } from 'app/shared/shared.module'
import { BotJobsListPage } from './bot-jobs-list/bot-jobs-list.page'
import { EditBotJobPage } from './edit/edit-bot-job.page'
import { BotJobsRoutingModule } from './bot-jobs-routing.module'
import { PayerPlanPipe } from 'app/shared/pipes/payer-plan.pipe'

@NgModule({
  declarations: [BotJobsListPage, EditBotJobPage],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    BotJobsRoutingModule,
    PayerPlanPipe,
  ],
  providers: [PayerPlanPipe],
})
export class BotJobsAdminModule {}
