import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CopyFieldGroups,
  CreateVxPageTypeFieldGroup,
  DeleteVxPageTypeFieldGroup,
  MatchTextPatternToFileQuery,
  UpdateVxPageTypeFieldGroup,
  VxPageTypeFieldGroupsQuery,
} from 'app/vision-x/vision-x.gql'
import {
  ExtractionRule,
  MutationCreateVxPageTypeFieldGroupArgs,
  MutationDeleteVxPageTypeFieldGroupArgs,
  MutationUpdateVxPageTypeFieldGroupArgs,
  QueryVxPageTypeFieldGroupsArgs,
  TemplateMatchCoordinates,
  VxPageTypeFieldGroup,
  VxPageTypeFieldGroupCreate,
  VxPageTypeFieldGroupList,
  VxPageTypeFieldGroupUpdate,
} from 'generated/graphql'
import { cloneDeep, remove } from 'lodash'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class VxFieldGroupService {
  constructor(private apollo: Apollo) {}

  fetchVxFieldGroups(
    vxPageTypeId: string,
  ): Observable<ApolloQueryResult<{ vxPageTypeFieldGroups: VxPageTypeFieldGroupList }>> {
    return this.apollo.watchQuery<{ vxPageTypeFieldGroups: VxPageTypeFieldGroupList }, QueryVxPageTypeFieldGroupsArgs>({
      query: VxPageTypeFieldGroupsQuery,
      variables: {
        vxPageTypeId,
      },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  async createVxPageTypeFieldGroup(request: VxPageTypeFieldGroupCreate): Promise<VxPageTypeFieldGroup> {
    const result = await this.apollo
      .mutate<{ createVxPageTypeFieldGroup: VxPageTypeFieldGroup }, MutationCreateVxPageTypeFieldGroupArgs>({
        mutation: CreateVxPageTypeFieldGroup,
        variables: {
          data: request,
        },
      })
      .toPromise()
    return result.data?.createVxPageTypeFieldGroup
  }

  async updateVxPageTypeFieldGroup(id: string, request: VxPageTypeFieldGroupUpdate): Promise<VxPageTypeFieldGroup> {
    const result = await this.apollo
      .mutate<{ updateVxPageTypeFieldGroup: VxPageTypeFieldGroup }, MutationUpdateVxPageTypeFieldGroupArgs>({
        mutation: UpdateVxPageTypeFieldGroup,
        variables: {
          id,
          data: request,
        },
        update(cache, { data: { updateVxPageTypeFieldGroup } }) {
          cache.modify({
            id: cache.identify({ id: updateVxPageTypeFieldGroup?.id, __typename: 'VxPageTypeFieldGroup' }),
            fields: {
              extractionRules(): ExtractionRule {
                return updateVxPageTypeFieldGroup?.extractionRules
              },
            },
          })
        },
      })
      .toPromise()
    return result.data?.updateVxPageTypeFieldGroup
  }

  async deleteVxPageTypeFieldGroup(
    fieldGroupIds: string[],
    extractionRule?: ExtractionRule,
    extractionRuleDataKeys?: string[],
  ): Promise<boolean> {
    if (fieldGroupIds.length === 1) {
      const result = await this.apollo
        .mutate<{ deleteResult: boolean }, MutationDeleteVxPageTypeFieldGroupArgs>({
          mutation: DeleteVxPageTypeFieldGroup,
          variables: {
            id: fieldGroupIds[0],
          },
          update: () => {
            let cache = this.apollo.client.cache
            cache.evict({ id: cache.identify({ id: fieldGroupIds[0], __typename: 'VxPageTypeFieldGroup' }) })
            cache.gc()
          },
        })
        .toPromise()
      return result.data?.deleteResult
    } else if (fieldGroupIds.length > 1) {
      if (extractionRule && extractionRuleDataKeys?.length > 0) {
        const updatedExtractionRules: ExtractionRule = cloneDeep(extractionRule)
        let nestedRuleParent: ExtractionRule
        let nestedRules: ExtractionRule[] = updatedExtractionRules.childGroups
        for (let i = 1; i < extractionRuleDataKeys.length - 1; i++) {
          nestedRuleParent = nestedRules.find((rule) => rule.dataKey === extractionRuleDataKeys[i])
          nestedRules = nestedRuleParent.childGroups
        }
        remove(
          nestedRuleParent.childGroups,
          (childGroup) => childGroup.dataKey === extractionRuleDataKeys[extractionRuleDataKeys.length - 1],
        )

        const result = await this.apollo
          .mutate<{ updateResult: VxPageTypeFieldGroup }, MutationUpdateVxPageTypeFieldGroupArgs>({
            mutation: UpdateVxPageTypeFieldGroup,
            variables: {
              id: fieldGroupIds[0],
              data: { extractionRules: updatedExtractionRules },
            },
          })
          .toPromise()
        return !!result.data?.updateResult
      }
    }
  }

  async copyFieldGroups(sourceVxPageTypeId: string, destinationVxPageTypeId: string): Promise<boolean> {
    const result = await this.apollo
      .mutate<{ copyFieldGroups: boolean }, { sourcePageTypeId: string; destinationPageTypeId: string }>({
        mutation: CopyFieldGroups,
        variables: {
          sourcePageTypeId: sourceVxPageTypeId,
          destinationPageTypeId: destinationVxPageTypeId,
        },
      })
      .toPromise()
    return result.data?.copyFieldGroups
  }

  /**
   *  Search for matching text templates on page
   */
  matchTextPatternToFile(
    processedFileId: string,
    textPattern: string,
  ): Observable<ApolloQueryResult<{ matchTextPatternToFile: TemplateMatchCoordinates[] }>> {
    return this.apollo.query<{ matchTextPatternToFile: TemplateMatchCoordinates[] }>({
      query: MatchTextPatternToFileQuery,
      variables: {
        processedFileId,
        textPattern,
      },
    })
  }
}
