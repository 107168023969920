/// <reference types="chrome" />
import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { BotJobsService } from 'app/admin/bot-jobs/bot-jobs.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ClaimsService } from 'app/claims/claims.service'
import { ProcedureSetsService } from 'app/procedure-sets/procedure-sets.service'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { PayerPlanService } from 'app/shared/services/payer-plan.service'
import { PortalMapService } from 'app/shared/services/portal-map.service'
import { debug } from 'app/shared/utils/debug'
import { environment } from 'environments/environment'
import {
  BotJob,
  Claim,
  CreateTeleportFeedbackInput,
  FeatureFlag,
  Payer,
  PayerPlan,
  PortalMapOutDto,
  PriorAuthPortalMapOutDto,
  ProcedureSet,
  ProcedureSetTest,
} from 'generated/graphql'
import { isNil, pickBy, toString } from 'lodash'
import moment, { utc } from 'moment'
import { Observable, Subject, combineLatest, defer, firstValueFrom, forkJoin, from, of } from 'rxjs'
import { map, switchMap, take, takeUntil } from 'rxjs/operators'

const TELEPORT_INIT_PATH = 'https://app.janus-ai.com/teleport?'
export interface ProcedureSetRowDef {
  // uuid for the procedure set
  id: string
  procedures: {
    // The procedure set code
    code?: string
    description?: string
    status?: string
  }[]
  payerPlan?: PayerPlan
  contactPayer?: boolean
  completed?: boolean
}

interface ClaimRowDef {
  // uuid for the claim
  id: string
  // The outstanding amount on the claim
  outstandingAmount: number
  // Total amount that the claim was for
  claimAmount: number
  // The provider relevant version of the claimId
  providerClaimId: string
}

export interface BaseRowConfig<RowType extends TeleportEntityType, TypeDef> {
  // The unique listId for the calling list context
  listId: number
  portal: string
  /** The `bot_job.payer` value for claim status Teleport runs */
  botPayer?: string
  /** The `Payer` record related to the entity that is being Teleported on */
  payer: Payer
  serviceDates: {
    startDate: Date
    endDate: Date
  }
  // The type of row this is - informs what type of typeDef we have, and row html
  rowType: RowType
  // The additional field relevant to this row's construction
  typeDef: TypeDef
  // The various bot types that can be applied for teleporting on the specific rowType
  isTest?: boolean
  botType: EntityToBotTypeMap[RowType]
  docUploadRequired: boolean
  epicReferralId?: number
  canTeleport?: boolean
  reuseTab?: boolean
}

export type ClaimRowConfig = BaseRowConfig<'claim', ClaimRowDef>

export type ProcedureSetRowConfig = BaseRowConfig<'procedureSet', ProcedureSetRowDef>

export type TeleportRowMetaData = ClaimRowConfig | ProcedureSetRowConfig

export type TeleportEntityType = 'claim' | 'procedureSet' | 'providerClaimId'

export type ClaimBotType = 'getClaimStatus' | 'findPayments' | 'eligibilityRequest'
export type ProcedureSetBotType = 'submitAuthRequest' | 'priorAuthFollowUp'

export type TeleportBotTypes = ClaimBotType | ProcedureSetBotType

export type BrowserType = 'edge' | 'chrome' | 'safari' | 'firefox' | 'opera' | 'unknown'

type BrowserTypeMapping = { userAgentIncludes: string; userAgentExcludes: string[]; browserType: BrowserType }

type LaunchTeleportArgs<T extends TeleportEntityType> = {
  url?: string
  refId?: string
  refType?: T
  botType: EntityToBotTypeMap[T]
  portal?: string
  payer?: string
  isTest?: boolean
  reuseTab?: boolean
}

type TeleportFeatureFlags = { [key: string]: boolean }

/**
 * The arguments needed to send a start message to the Teleport
 * extension. This matches the `JanusStartMessage` type from the
 * `teleport` package in the automation repo.
 */
type TeleportStartArgs = {
  /** The type of entity to launch teleport on */
  refType: TeleportEntityType
  /** The id of the entity to launch teleport on */
  refId: string
  /** The type of Teleport bot to launch */
  botType: TeleportBotTypes
  /** The portal of the automation */
  portal: string
  /** Whether to reuse the tab */
  reuseTab: boolean
  /** Whether the Teleport run is a test */
  isTest: boolean
  /** The teleport_history id for the run */
  teleportHistoryId?: string
  /** Feature flags to pass to the extension */
  featureFlags: TeleportFeatureFlags
}

const browserTypeMapping: BrowserTypeMapping[] = [
  {
    userAgentIncludes: 'chrome',
    userAgentExcludes: ['edg', 'opr'],
    browserType: 'chrome',
  },
  {
    userAgentIncludes: 'edg',
    userAgentExcludes: [],
    browserType: 'edge',
  },
  {
    userAgentIncludes: 'opr',
    userAgentExcludes: [],
    browserType: 'opera',
  },
  {
    userAgentIncludes: 'firefox',
    userAgentExcludes: [],
    browserType: 'firefox',
  },
  {
    userAgentIncludes: 'safari',
    userAgentExcludes: ['chrome'],
    browserType: 'safari',
  },
]

export interface EntityToBotTypeMap {
  claim: ClaimBotType
  providerClaimId: ClaimBotType
  procedureSet: ProcedureSetBotType
}

/**
 * @export
 * @class TeleportService
 */
@Injectable({
  providedIn: 'root',
})
export class TeleportService implements OnDestroy {
  private API_URL = '/api'
  private TELEPORT_URL = this.API_URL + '/teleport'
  private FEEDBACK_URL = this.TELEPORT_URL + '/feedback'

  private destroy$ = new Subject<void>()
  private allowUploadDocs$: Observable<boolean>
  private onlyShowAuthRequiredProcedureSet$: Observable<boolean>
  private useTeleportSessions$: Observable<boolean>

  zyncAllowed: boolean = false
  useNewPortalMap: boolean = false

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private botJobsService: BotJobsService,
    private procedureSetsService: ProcedureSetsService,
    private claimsService: ClaimsService,
    private featureFlagService: FeatureFlagService,
    private payerPlanService: PayerPlanService,
    private portalMapService: PortalMapService,
    private ngZone: NgZone,
    private router: Router,
  ) {
    this.allowUploadDocs$ = defer(() => from(this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.uploadDocs)))
    this.onlyShowAuthRequiredProcedureSet$ = defer(() =>
      from(this.featureFlagService.getFeatureFlagValue(FeatureFlags.edi.onlyShowAuthRequiredProcedureSet)),
    )
    this.useTeleportSessions$ = defer(() =>
      from(this.featureFlagService.getFeatureFlagValue(FeatureFlags.bot.teleportUseSessions)),
    )
    this.fetchIsZyncAllowed()
    this.fetchUseNewPortalMap()
  }

  async fetchIsZyncAllowed(): Promise<void> {
    this.zyncAllowed = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.api.priorAuth.zync)
  }

  async fetchUseNewPortalMap(): Promise<void> {
    this.useNewPortalMap = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.api.portalMap)
  }

  /**
   * Add Teleport user feedback
   *
   * @param {CreateTeleportFeedbackInput} data
   * @return {*}  {Promise<boolean>}
   * @memberof TeleportService
   */
  async createTeleportFeedback(data: CreateTeleportFeedbackInput): Promise<boolean> {
    try {
      await this.http.post(this.FEEDBACK_URL, data).toPromise()
      return true
    } catch (e) {
      debug('teleportFeedback', 'could not create teleport feedback', e)
      throw new Error('Could not create Teleport Feedback')
    }
  }

  getBrowserType(): BrowserType {
    const { userAgent } = window.navigator
    const userAgentLowercase = userAgent.toLowerCase()
    // userAgent strings for Edge & Opera also contain "Chrome", so we check these first
    const mapping = browserTypeMapping.find(
      (map) =>
        userAgentLowercase.includes(map.userAgentIncludes) &&
        !map.userAgentExcludes.some((excludeStr) => userAgentLowercase.includes(excludeStr)),
    )
    const browserType = mapping ? mapping.browserType : 'unknown'
    return browserType
  }

  getNetworkDetails() {
    const navigator = window.navigator as any
    if (!navigator.connection) {
      return null
    }

    return {
      rtt: navigator.connection.rtt,
      downlinkSpeed: navigator.connection.downlink,
      effectiveSpeedType: navigator.connection.effectiveType,
      computerPlatform: navigator.platform,
      userAgent: navigator.userAgent,
    }
  }

  /**
   *
   * @param type - The type of look up entity that we are looking up a url for
   * @param id - the uuid of the entity handle
   * @param botType
   * @returns
   */
  getTeleportUrlForEntity<T extends TeleportEntityType>(
    type: T,
    id: string,
    botType?: EntityToBotTypeMap[T],
    isTest?: boolean,
  ): Observable<string> {
    switch (type) {
      case 'procedureSet':
        return this.getProcedureSetTeleportUrl(id, (botType as ProcedureSetBotType) ?? 'submitAuthRequest', isTest)
      case 'claim':
        return this.getClaimTeleportUrl(id, (botType as ClaimBotType) ?? 'getClaimStatus')
      case 'providerClaimId':
        return this.getClaimTeleportUrl(id, (botType as ClaimBotType) ?? 'getClaimStatus', true)
    }
  }

  async checkForExtension(): Promise<boolean> {
    let success = false

    /*
      need chrome.runtime.sendMessage in order to trigger mv3 teleport. see
      https://developer.chrome.com/docs/extensions/reference/manifest/externally-connectable
      for details on availability of chrome runtime api as it relates to extensions
    */
    if (!chrome?.runtime?.sendMessage) {
      console.log('runtime api not present, extension not detected')
      return success
    }

    const teleportExtensionIds = [environment.teleportExtensionId, environment.testingTeleportExtensionId]
    for (let i = 0; i < teleportExtensionIds.length; i++) {
      const id = teleportExtensionIds[i]
      try {
        console.log(`checking extension with id ${id}...`)
        await chrome.runtime.sendMessage(id, { type: 'ping' })
        console.log('runtime api present, extension detected')
        success = true
        break
      } catch (e) {
        console.log(e)
        console.warn(`Could not start automation with extension ${id}`)
      }
    }
    return success
  }

  IsTeleportEnabled<T extends TeleportEntityType>(
    type: TeleportEntityType,
    id: string,
    botType?: EntityToBotTypeMap[T],
  ): Observable<boolean> {
    // For now, we just check if we can generate a url
    // Once we move away from generating urls for Teleport runs, this method needs to be updated to check botjobs/payers/etc to determine if the
    // Teleport button should be visible
    return this.getTeleportUrlForEntity(type, id, botType).pipe(
      map((url) => {
        return !!url && url.length > 0
      }),
    )
  }

  private async startTeleportHistory({ botType, refId, refType, userId, portal, payer }) {
    let teleportHistoryId: string
    let historyResponse: Response = null
    // When we migrate away from Teleport History, we should be able to replace this whole part by just generating a trace id to pass to the extension
    try {
      historyResponse = await fetch('/api/teleport/history', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          botType,
          payer: payer ? payer : '',
          portal: portal ? portal : '',
          networkDetails: this.getNetworkDetails(),
          logs: ['janus ui launching teleport'],
          browserType: this.getBrowserType(),
          relatedEntityId: refId,
          relatedEntityType: refType,
          startedAt: new Date(),
          userId,
        }),
      })
      if (historyResponse.ok) {
        const historyData = await historyResponse.json()
        teleportHistoryId = historyData.id
        return teleportHistoryId
      } else {
        console.warn(
          `Teleport history record creation failed with http status ${historyResponse.status}, continuing to launch automation`,
        )
        return null
      }
    } catch (err) {
      console.warn(err, 'Teleport history failed to start, continuing to launch automation')
      return null
    }
  }

  /**
   * Retrieves any existing teleport extension feature flags. Any feature flags containing the teleport extension flag prefix
   * 'teleportExtension', is retrieved here. This allows devs to create feature flags that will automatically be picked up and
   * passed to the extension.
   * @returns Promise<TeleportFeatureFlags> - array of simplified teleport feature flags
   */
  async getTeleportExtensionFeatureFlags(): Promise<TeleportFeatureFlags> {
    let teleportExtensionFeatureFlags: FeatureFlag[] = []
    const queryRes = await firstValueFrom(
      this.featureFlagService.getFeatureFlags([FeatureFlags.teleportExtension.flagPrefix]),
    )
    const flags = queryRes.data.featureFlags
    if (flags) {
      teleportExtensionFeatureFlags = [...flags]
    }
    const simpleFlags: TeleportFeatureFlags = {}
    teleportExtensionFeatureFlags.forEach((flag) => {
      simpleFlags[flag.name] = flag.value
    })
    console.log(`teleport extension feature flags: ${JSON.stringify(simpleFlags)}`)
    return simpleFlags
  }

  public async launchTeleport<T extends TeleportEntityType>({
    url,
    refId,
    refType,
    botType,
    portal,
    payer,
    isTest,
    reuseTab,
  }: LaunchTeleportArgs<T>) {
    const user = this.authenticationService.getUser()

    const teleportHistoryId = await this.startTeleportHistory({
      botType,
      refId,
      refType,
      userId: user.id,
      portal,
      payer,
    })

    const navigateToDocUpload = url?.includes('teleport/prior-auth-docs-upload?')
    if (!(refId && refType)) {
      throw new Error('Cannot run Teleport without refId and refType')
    }

    if (navigateToDocUpload) {
      const urlWithHistory = new URL(url, window.location.href)
      teleportHistoryId ? urlWithHistory.searchParams.set('teleportHistoryId', teleportHistoryId) : null
      window.open(urlWithHistory.toString(), '_blank')
      return
    }

    const teleportExtensionFeatureFlags = await this.getTeleportExtensionFeatureFlags()
    const teleportArgs: TeleportStartArgs = {
      refType,
      refId,
      botType,
      portal,
      reuseTab,
      isTest: isTest ?? false,
      ...(teleportHistoryId && { teleportHistoryId }), // don't include if undefined
      featureFlags: teleportExtensionFeatureFlags,
    }
    await this.startTeleportAutomation(teleportArgs, user.isSuperuser)
  }

  /**
   * Sends a start message to the Teleport automation to kick off the automation
   *
   * @param teleportArgs The arguments to use for the Teleport run
   * @param isSuperUser Flag stating if the calling user is a super user
   */
  async startTeleportAutomation(teleportArgs: TeleportStartArgs, isSuperUser: boolean): Promise<void> {
    console.log('Sending message to start Teleport automation...')

    let hasLaunched = await this.tryStartExtension(environment.teleportExtensionId, teleportArgs)
    if (hasLaunched) {
      console.log('Launched production Teleport extension')
      return
    }
    if (isSuperUser) {
      // If the user is a super user, try to launch a testing extension if the prod one didn't launch
      hasLaunched = await this.tryStartExtension(environment.testingTeleportExtensionId, teleportArgs)
      if (hasLaunched) {
        console.log('Launched testing teleport extension')
        return
      }
    }

    /*
      we should have already ensured that the extension is available in the teleport guard by this time.
      extension could have been disabled/removed between that check and the actual launch attempt.
      this shouldn't usually happen.
    */
    if (!hasLaunched) {
      this.router.navigate(['teleport', 'warning'], {
        queryParams: { errorType: 'TeleportExtensionUnresponsive' },
      })
    }
    console.warn('Unable to start teleport automation - no extension received message')
    return
  }

  /**
   * Tries to start a Teleport automation by sending a 'start' message to the given extension
   * @param extensionId The id of the extension to send the start message to
   * @param teleportArgs The arguments to pass
   * @returns True if the message was sent successfully, false if not
   */
  async tryStartExtension(extensionId: string, teleportArgs: TeleportStartArgs): Promise<boolean> {
    try {
      await chrome.runtime.sendMessage(extensionId, { type: 'start', data: teleportArgs })
      return true
    } catch (e) {
      console.warn(`Could not start automation with extension ${extensionId}`)
      return false
    }
  }

  /**
   * Abstraction of logic to determine if ProcedureSet requires documents to be uploaded
   * For the case when a ProcedureSet is created through initipath
   *
   * implements featureflag (`bot.upload-docs`) to enable redirect and docs indicator
   */
  public docUploadRequired = (procedureSet: ProcedureSet | ProcedureSetTest): Observable<boolean> => {
    return this.allowUploadDocs$.pipe(
      map((allowUploadDocs) => {
        if (!allowUploadDocs) {
          return false
        }

        if (procedureSet.priorAuthCaseEvents?.some((ps) => ps.authorizationStatusCode === 'INTEGRATION_ERROR')) {
          return false
        }

        return procedureSet.priorAuthCaseEvents.length > 0
      }),
    )
  }

  private getProcedureSetTeleportUrl(id: string, botType: ProcedureSetBotType, isTest: boolean): Observable<string> {
    return this.useTeleportSessions$.pipe(
      switchMap((useTeleportSessions) => {
        if (useTeleportSessions) {
          return this.createSessionUrl('procedureSet', id)
        } else {
          return this.getProcedureSetBaseUrl(id, botType, isTest)
        }
      }),
    )
  }

  private getProcedureSetBaseUrl(id: string, botType: ProcedureSetBotType, isTest: boolean): Observable<string> {
    const procedureSet$ = isTest
      ? this.procedureSetsService.getOneProcedureSetTest(id)
      : this.procedureSetsService.getProcedureSet(id)

    return forkJoin([
      procedureSet$,
      of(this.authenticationService.getUser()),
      this.onlyShowAuthRequiredProcedureSet$,
    ]).pipe(
      switchMap(([ps, user, onlyShowAuthRequiredProcedureSet]) => {
        return forkJoin([of(ps), of(user), of(onlyShowAuthRequiredProcedureSet), this.docUploadRequired(ps)])
      }),
      take(1),
      switchMap(([ps, user, onlyShowAuthRequiredProcedureSet, docUploadRequired]) => {
        if (!ps) return of(null)
        // Only allow a ProcedureSet with a Procedure with ARC status
        if (onlyShowAuthRequiredProcedureSet && !docUploadRequired) {
          const hasArcStatus = ps.procedures.some((p) => p.status === 'ARC')
          if (!hasArcStatus) return of(null)
        }

        if (docUploadRequired) {
          return of(`/teleport/prior-auth-docs-upload?procedure_set_id=${ps.id}`)
        }

        if (this.useNewPortalMap) {
          return this.getPriorAuthPortalMap(ps, user.orgId).pipe(
            switchMap((pm) => {
              // check if portal map exists and group is enabled
              if (!pm || !pm.enabled || !pm.initiationTeleportEnabled) return of(null)
              return this.createUrlForProcedureSet(ps, pm.initiationSubName, botType, docUploadRequired)
            }),
            takeUntil(this.destroy$),
          )
        }

        return this.getPortalMap(ps, user.orgId, ps.id, botType).pipe(
          switchMap((pm) => {
            // check if portal map exists and group is enabled
            if (!pm || !pm.enabled || !pm.teleportIsEnabled) return of(null)
            return this.createUrlForProcedureSet(ps, pm.groupName, botType, docUploadRequired)
          }),
          takeUntil(this.destroy$),
        )
      }),
    )
  }

  private getPriorAuthPortalMap(
    procedureSet: ProcedureSet | ProcedureSetTest,
    orgId: string,
  ): Observable<PriorAuthPortalMapOutDto> {
    const codes = procedureSet.procedures.sort((a, b) => a.setIndex - b.setIndex).find((i) => !isNil(i.setIndex))
    const primaryProcedureCode = codes?.code
    const payerName = procedureSet.payerName

    return this.portalMapService.getPriorAuthPortalMap(orgId, primaryProcedureCode, payerName)
  }

  private getPortalMap(
    procedureSet: ProcedureSet | ProcedureSetTest,
    orgId: string,
    procedureSetId: string,
    botType: ProcedureSetBotType,
  ): Observable<PortalMapOutDto> {
    const codes = procedureSet.procedures.filter((i) => !isNil(i.setIndex)).sort((a, b) => a.setIndex - b.setIndex)
    const primaryProcedureCode = codes?.[0].code

    return this.portalMapService.getPortalMap(procedureSet.payerPlanId, orgId, primaryProcedureCode, botType)
  }

  private getClaimTeleportUrl(
    id: string,
    botType: ClaimBotType,
    asProviderClaimId: boolean = false,
  ): Observable<string> {
    return this.useTeleportSessions$.pipe(
      switchMap((useTeleportSessions) => {
        if (useTeleportSessions) {
          return this.createSessionUrl('claim', id)
        } else {
          const getClaim$ = asProviderClaimId ? this.claimsService.getClaim(id) : this.claimsService.getClaimById(id)
          return combineLatest([from(this.payerPlanService.getLegacyEnabled()), getClaim$]).pipe(
            take(1),
            switchMap(([isLegacy, result]) => {
              const claim = result?.data?.claim
              if (!claim) return of('')

              return this.botJobsService.botJobFromClaim({ claim }).pipe(
                map((botjob) => {
                  if (!botjob) return null
                  return this.createUrlForClaim(claim, botjob, botType)
                }),
                takeUntil(this.destroy$),
              )
            }),
            takeUntil(this.destroy$),
          )
        }
      }),
    )
  }

  private createSessionUrl(refType: 'claim' | 'procedureSet', refId: string) {
    return this.http
      .post<{ token: string }>(
        `${this.TELEPORT_URL}/session/handoff`,
        { refType, refId },
        { withCredentials: true, responseType: 'json', observe: 'body' },
      )
      .pipe(
        map((response) => {
          return `${TELEPORT_INIT_PATH}${new URLSearchParams({
            type: response.token,
          })}`
        }),
        takeUntil(this.destroy$),
      )
  }

  private createUrlForProcedureSet(
    ps: ProcedureSet | ProcedureSetTest,
    payerName: string,
    botType: ProcedureSetBotType,
    docUploadRequired: boolean = false,
  ): Observable<string | null> {
    if (!ps || !payerName) return of(null)
    const user = this.authenticationService.getUser()
    const diagnosis = ps.diagnosisCodes
    const codes = ps.procedures.filter((i) => !isNil(i.setIndex)).sort((a, b) => a.setIndex - b.setIndex)

    if (docUploadRequired) {
      return of(`/teleport/prior-auth-docs-upload?procedure_set_id=${ps.id}`)
    }
    const params = new URLSearchParams(
      pickBy(
        {
          userId: user.id,
          procedureSetId: ps.id,
          botType,
          credPool: ps.credentialType,
          patientDob: utc(ps?.dependentDob || ps?.patientDob)?.format('MM/DD/YYYY'),
          patientFirstName: ps?.dependentFirstName || ps?.patientFirstName,
          patientLastName: ps?.dependentLastName || ps?.patientLastName,
          patientMemberId: ps?.dependentMemberId || ps?.patientSubscriberId,
          payer: payerName,
          portal: ps.priorAuthPortal,
          billingNpi: ps.billingProviderNpi ?? ps.providerBilling?.npi,
          referringNpi: ps.referringProviderNpi ?? ps.providerReferring?.npi,
          renderingNpi: ps.renderingProviderNpi ?? ps.providerRendering?.npi,
          billingAddress: ps.billingProviderAddress ?? ps.providerBilling?.address,
          referringAddress: ps.referringProviderAddress ?? ps.providerReferring?.address,
          renderingAddress: ps.renderingProviderAddress ?? ps.providerRendering?.address,
          serviceDatesEnd: ps.serviceDateEnd ? moment.utc(ps.serviceDateEnd).format('MM/DD/YYYY') : null,
          serviceDatesStart: ps.serviceDateStart ? moment.utc(ps.serviceDateStart).format('MM/DD/YYYY') : null,
          orgId: user.orgId,
          urgency: ps.urgency,
          diagnosisCode: diagnosis?.[0] || null,
          diagnosisCodeSecondary: diagnosis?.slice(1)?.join(','),
          primaryProcedureCode: toString(codes?.[0]?.code),
          tertiaryProcedureCodes: codes
            ?.slice(1)
            ?.map((c) => String(c.code))
            ?.join(','),
          zyncAllowed: toString(this.zyncAllowed),
          providerBillingName: ps.billingProviderName,
          providerReferringName: ps.referringProviderName,
          providerRenderingName: ps.renderingProviderName,
          billingTaxId: ps.billingProviderTin,
          isTest: toString(ps.isTest),
        },
        (value) => !isNil(value),
      ),
    )
    return of(TELEPORT_INIT_PATH + params)
  }

  /**
   *
   * @param claim
   * @param botjob
   * @param botType
   * @returns
   */
  private createUrlForClaim(claim: Claim, botjob: BotJob, botType: string = 'getClaimStatus'): string {
    // Keep needed claim fields sync with ClaimsForTeleport in 'app/claims/claims-list/claims-list.gql'
    if (!claim || !botjob) {
      return ''
    }

    const user = this.authenticationService.getUser()
    let name = claim.patientName?.split(',') || []
    // Remove junior or senior if it exists
    if (name.length > 2) {
      name = name.filter((n) => !['JR', 'SR'].includes(n.toUpperCase().replace('.', '')))
    }
    const patientLastName = claim?.patientNameLast?.trim()
    const patientFirstName = claim?.patientNameFirst?.trim()
    const alternateFirstName = name[name.length - 1]?.trim()
    const alternateLastName = name[0]?.trim()
    let serviceDates = this.claimsService.getClaimServiceDates(claim)
    let params = new URLSearchParams(
      pickBy(
        {
          userId: user.id,
          claimId: claim.id,
          attendingNpi: claim.providerAttending?.npi,
          availityOrgId: claim?.availityOrgId,
          billingModule: claim?.billingModule,
          billingNpi: claim.providerBilling?.npi,
          billingTaxId: claim.taxId?.replace('-', '') || claim.providerBilling?.taxId?.replace('-', ''),
          botType,
          claimAmount: claim?.claimAmount?.toString(),
          credentialTypeModifier: botjob?.credentialTypeModifier,
          credPool: botjob.credentialType,
          operatingNpi: claim.providerOperating?.npi,
          patientAccountNumber: claim?.providerClaimId,
          patientDob: utc(claim?.patientDob)?.format('MM/DD/YYYY'),
          patientFirstName: patientFirstName ?? alternateFirstName,
          patientLastName: patientLastName ?? alternateLastName,
          patientMemberId: claim.memberId,
          payer: botjob.payer?.toLowerCase(),
          payerName: claim.payer?.name,
          subscriberName: claim.subscriberName,
          patientRelationship: claim.patientRelationship,
          payerAddress: claim.payer?.address,
          portal: botjob.portal?.toLowerCase(),
          providerGroupNpi: claim?.providerOperating?.groupNpi,
          providerNpi: claim.providerOperating?.npi,
          providerRenderingName: claim?.providerRendering?.name,
          providerTaxId: claim?.taxId?.replace('-', ''),
          referringNpi: claim.providerReferring?.npi,
          renderingNpi: claim.providerRendering?.npi,
          billingAddress: claim.providerBilling?.address,
          referringAddress: claim.providerReferring?.address,
          renderingAddress: claim.providerRendering?.address,
          serviceDatesEnd: serviceDates.endDate ? moment(serviceDates.endDate).format('MM/DD/YYYY') : null,
          serviceDatesStart: serviceDates.startDate ? moment(serviceDates.startDate).format('MM/DD/YYYY') : null,
          serviceDate: claim.serviceDate ? moment(claim.serviceDate).format('MM/DD/YYYY') : null,
          submissionDate: claim.submissionDate ? moment(claim.submissionDate).format('MM/DD/YYYY') : null,
          subscriberId: claim?.subscriberId,
          patientSex: claim.patientSex,
          orgId: user.orgId,
          groupNumber: claim.groupNumber,
          formType: claim.formType,
          zyncAllowed: toString(botjob.zyncAllowed),
          reuseTab: toString(botjob.reuseTab),
          renderingProviderTaxonomyCode: claim.renderingProviderTaxonomyCode,
          billingProviderTaxonomyCode: claim.billingProviderTaxonomyCode,
        },
        (value) => value != null,
      ),
    )
    return TELEPORT_INIT_PATH + params
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
