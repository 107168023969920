/**
 * Omit a given given from an object and all its children recursively
 *
 * Taken from https://gist.github.com/Billy-/d94b65998501736bfe6521eadc1ab538
 * and modified slightly
 *
 * @param   {[type]}  value  [value description]
 * @param   {[type]}  key    [key description]
 *
 * @return  {[type]}         [return description]
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function omitDeep(value: any, key: string): any {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key))
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k == key) return newObject
      return Object.assign({ [k]: omitDeep(value[k], key) }, newObject)
    }, {})
  }
  return value
}
