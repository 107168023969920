import { Injectable } from '@angular/core'
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  TestFhirApiQuery,
  TestVxAutomationQuery,
  UpdateVxDocument,
  VxDocumentQuery,
  VxDocumentsByNameQuery,
  VxDocumentsQuery,
  VxDocumentWithUnmatchedVxPageTypeFieldGroupsQuery,
} from 'app/vision-x/vision-x.gql'
import {
  FhirApiResponses,
  MutationUpdateVxDocumentArgs,
  QueryDryRunAutomationArgs,
  QueryTestFhirApiArgs,
  QueryVxDocumentArgs,
  QueryVxDocumentsArgs,
  QueryVxDocumentsByNameArgs,
  TestVxAutomation,
  VxDocument,
  VxDocumentList,
  VxDocumentUpdate,
} from 'generated/graphql'
import { Observable } from 'rxjs'
import { VisionXStateService } from '../vision-x-state.service'

@Injectable({
  providedIn: 'root',
})
export class VxDocumentService {
  constructor(private apollo: Apollo, public state: VisionXStateService) {}

  fetchVxDocument(id: string): Observable<ApolloQueryResult<{ vxDocument: VxDocument }>> {
    return this.apollo.watchQuery<{ vxDocument: VxDocument }, QueryVxDocumentArgs>({
      query: VxDocumentQuery,
      variables: {
        id,
      },
    }).valueChanges
  }

  fetchVxDocumentWithUnmatchedVxPageTypeFieldGroups(
    id: string,
  ): Observable<ApolloQueryResult<{ vxDocument: VxDocument }>> {
    return this.apollo.watchQuery<{ vxDocument: VxDocument }, QueryVxDocumentArgs>({
      query: VxDocumentWithUnmatchedVxPageTypeFieldGroupsQuery,
      variables: {
        id,
      },
    }).valueChanges
  }

  fetchVxDocuments(
    filters: {
      vxPageTypeIds?: string
      vxDocumentTypeId?: string
      vxDocumentId?: string
      onlyUncategorized?: boolean
      search?: string
      confidenceLevels?: string
      statuses?: string
      date?: string
      beginDate?: string
      endDate?: string
      needsAdditionalReview?: boolean
    },
    options: {
      fetchPolicy?: WatchQueryFetchPolicy
      limit?: number
      offset?: number
      sort?: string
    },
  ): Observable<ApolloQueryResult<{ vxDocuments: VxDocumentList }>> {
    options = { ...{ fetchPolicy: 'cache-and-network', limit: 100, offset: 0, sort: 'createdAt:asc' }, ...options }
    return this.apollo.watchQuery<{ vxDocuments: VxDocumentList }, QueryVxDocumentsArgs>({
      fetchPolicy: options.fetchPolicy,
      query: VxDocumentsQuery,
      variables: {
        search: filters.search,
        vxPageTypeIds: filters.vxPageTypeIds,
        vxDocumentTypeId: filters.vxDocumentTypeId,
        onlyUncategorized: filters.onlyUncategorized,
        confidenceLevels: filters.confidenceLevels,
        statuses: filters.statuses,
        date: filters.date,
        beginDate: filters.beginDate,
        endDate: filters.endDate,
        needsAdditionalReview: filters.needsAdditionalReview,
        limit: options.limit,
        offset: options.offset,
        sort: options.sort,
      },
    }).valueChanges
  }

  fetchVxDocumentsByName(search?: string): Observable<ApolloQueryResult<{ vxDocumentsByName: VxDocument[] }>> {
    return this.apollo.watchQuery<{ vxDocumentsByName: VxDocument[] }, QueryVxDocumentsByNameArgs>({
      fetchPolicy: 'cache-first',
      query: VxDocumentsByNameQuery,
      variables: {
        search,
      },
    }).valueChanges
  }

  async updateVxDocument(id: string, data: VxDocumentUpdate): Promise<VxDocument> {
    let result = await this.apollo
      .mutate<{ updateVxDocument: VxDocument }, MutationUpdateVxDocumentArgs>({
        mutation: UpdateVxDocument,
        variables: {
          id: id,
          data: data,
        },
        async update(store, updateVxDocumentResponse: { data: { updateVxDocument: VxDocument } }) {
          await Promise.all([
            store.evict({
              fieldName: 'vxPages',
              id: 'ROOT_QUERY',
            }),
            store.evict({
              fieldName: 'vxDocuments',
              id: 'ROOT_QUERY',
            }),
            store.modify({
              id: store.identify({ id, __typename: 'VxDocument' }),
              fields: {
                status() {
                  return updateVxDocumentResponse.data?.updateVxDocument?.status
                },
              },
            }),
          ])
        },
      })
      .toPromise()

    return result?.data?.updateVxDocument
  }

  dryRunAutomation(id: string): Observable<ApolloQueryResult<{ dryRunAutomation: TestVxAutomation[] }>> {
    return this.apollo.watchQuery<{ dryRunAutomation: TestVxAutomation[] }, QueryDryRunAutomationArgs>({
      query: TestVxAutomationQuery,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  testFhirApi(id: string): Observable<ApolloQueryResult<{ testFhirApi: FhirApiResponses }>> {
    return this.apollo.watchQuery<{ testFhirApi: FhirApiResponses }, QueryTestFhirApiArgs>({
      query: TestFhirApiQuery,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    }).valueChanges
  }
}
