import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'vx-file-thumbnail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vx-file-thumbnail.component.html',
})
export class VxFileThumbnailComponent {
  @Input() vxFileId: string
  @Input() caption: string
  @Input() isActive: boolean
}
