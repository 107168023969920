import { CommonModule } from '@angular/common'
import { Input, Output, EventEmitter, Component } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-canvas-controls',
  templateUrl: './canvas-controls.component.html',
  styleUrls: ['./canvas-controls.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class CanvasControlsComponent {
  @Input() isPanning: boolean = false
  @Input() zoomLevel: number = 1
  @Input() showResetZoom: boolean = true

  @Output()
  zoomIn = new EventEmitter()
  @Output()
  zoomOut = new EventEmitter()
  @Output()
  resetZoom = new EventEmitter()
  @Output()
  setZoom = new EventEmitter<number>()
  @Output()
  togglePanning = new EventEmitter()

  zoomLevels: number[] = [0.1, 0.25, 0.5, 0.75, 1, 1.5, 2, 3, 4]
}
