import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ApolloQueryResult } from '@apollo/client'
import { ToastService } from 'app/shared/services/toast.service'
import { OrgService } from 'app/admin/org/org.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import {
  ClientCredential,
  ClientCredentialsList,
  ListResponseMetaData,
  Organization,
  QueryClientCredentialsArgs,
  User,
} from 'generated/graphql'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { combineLatest, of, Subject, first } from 'rxjs'
import { debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil, catchError } from 'rxjs/operators'
import { ClientCredentialsService } from '../client-credentials.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ClientCredentialFilterComponent } from '../components/client-credentials-filter/client-credential-filter.component'
import { BotJobsService } from 'app/admin/bot-jobs/bot-jobs.service'

/**
 * Page to display a list of client credentials
 *
 * @export
 * @class ClientCredentialsListPage
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-client-credentials-list',
  templateUrl: './client-credentials-list.page.html',
  styleUrl: './client-credentials-list.page.scss',
  host: { class: 'd-flex flex-column h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientCredentialsListPage implements OnInit, OnDestroy {
  isLoading: boolean = true
  clientCredentialsSearch = new UntypedFormControl('')
  clientCredentialsSearchByEnabled = new UntypedFormControl('all')
  clientCredentialsSearchByPortal = new UntypedFormControl(null)
  clientCredentialsSearchByOrg = new UntypedFormControl(null)

  enabledFilterOptions: Record<string, { desc: string; apiValue: string | undefined }> = {
    all: { desc: 'All creds, no filter', apiValue: undefined },
    enabledOnly: { desc: 'Enabled only', apiValue: 'enabledOnly' },
    disabledOnly: { desc: 'Disabled only', apiValue: 'disabledOnly' },
    disabledByUser: { desc: 'Disabled by users', apiValue: 'disabledByUser' },
    disabledByBot: { desc: 'Disabled automatically', apiValue: 'disabledByBot' },
  }

  clientCredentials: { credential: ClientCredential; checkedOutBy: User }[] = []
  selectedOrg: string
  portals: string[]
  meta: ListResponseMetaData
  page = new UntypedFormControl(1)
  pageSize: number = 25
  destroy$ = new Subject<void>()

  public Math = Math

  constructor(
    private clientCredentialsService: ClientCredentialsService,
    private toast: ToastService,
    private cdr: ChangeDetectorRef,
    private orgService: OrgService,
    private botJobService: BotJobsService,
    private authenticationService: AuthenticationService,
    private modal: NgbModal,
  ) {}

  async ngOnInit(): Promise<void> {
    const orgResult = await this.orgService
      .getOrg(this.authenticationService.getUser()?.orgId)
      .pipe(first())
      .toPromise()
    this.selectedOrg = orgResult?.data?.organization?.name

    this.portals = await this.botJobService.getListOfPortals()

    combineLatest([
      this.clientCredentialsSearch.valueChanges.pipe(startWith(''), debounceTime(500)),
      this.clientCredentialsSearchByEnabled.valueChanges.pipe(startWith('')),
      this.clientCredentialsSearchByPortal.valueChanges.pipe(startWith('')),
      this.clientCredentialsSearchByOrg.valueChanges.pipe(startWith('')),
      this.page.valueChanges.pipe(startWith(1)),
    ])
      .pipe(
        // Prevents re-searching if the user hits enter after finishing typing
        // their query.
        distinctUntilChanged(
          (prev: [string, string, string, any, number], curr: [string, string, string, any, number]) => {
            return prev.join('') === curr.join('')
          },
        ),
        switchMap(([search, credEnabledFilter, portalFilter, orgFilter, page]) => {
          this.isLoading = true
          this.cdr.markForCheck() // Trigger change detection

          let enabledFilter = undefined
          if (credEnabledFilter in this.enabledFilterOptions) {
            enabledFilter = this.enabledFilterOptions[credEnabledFilter].apiValue
          }

          const portal = portalFilter.includes('No portal filter') ? undefined : portalFilter

          let offset = (page - 1) * this.pageSize
          let variables: QueryClientCredentialsArgs = {
            sort: this.meta?.sort,
            offset,
            search,
            limit: this.pageSize,
            enabledFilter,
            portal,
            orgId: orgFilter?.id ?? undefined,
          }

          return this.clientCredentialsService.searchClientCredentials(variables).pipe(
            catchError((e) => {
              this.toast.error(parseGraphQLError(e, 'Could not load client credentials'), JSON.stringify(e))
              this.isLoading = false
              this.cdr.markForCheck() // Trigger change detection
              return of({ data: { clientCredentials: { entities: [], meta: {} } } })
            }),
          )
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        async (
          result: ApolloQueryResult<{
            clientCredentials: ClientCredentialsList
          }>,
        ) => {
          this.meta = result?.data?.clientCredentials?.meta

          let credentials: ClientCredential[] = result?.data?.clientCredentials?.entities ?? []
          this.clientCredentials = await this.clientCredentialsService.saturateCredentialList(credentials)

          this.isLoading = false
          this.cdr.markForCheck() // Trigger change detection
        },
        (error) => {
          // Handle any unexpected errors
          this.toast.error(parseGraphQLError(error, 'An error occurred'), JSON.stringify(error))
          this.isLoading = false
          this.cdr.markForCheck() // Trigger change detection
        },
      )
  }

  ngOnDestroy(): void {
    this.destroy$?.next()
    this.destroy$?.complete()
  }

  /**
   * TrackBy function for optimizing ngFor rendering
   *
   * @param {number} index
   * @param {{ credential: ClientCredential; checkedOutBy: User }} item
   * @return {*}  {string}
   * @memberof ClientCredentialsListPage
   */
  trackByCredentialId(index: number, item: { credential: ClientCredential; checkedOutBy: User }): string {
    return item.credential.id
  }

  /**
   * Filter search by Organization
   *
   * @param {Organization} event
   */
  pickedOrg(event: Organization): void {
    this.clientCredentialsSearchByOrg.setValue(event)
    this.page.setValue(1)
  }

  /**
   * Toggle modal for filters
   *
   */
  toggleFilters(): void {
    const modalRef = this.modal.open(ClientCredentialFilterComponent, {
      centered: true,
      backdrop: true,
      windowClass: 'modal-extra-padding',
    })

    modalRef.componentInstance.userSelectionForEnabledDisabledFilter = this.clientCredentialsSearchByEnabled.value
    modalRef.componentInstance.selectedPortal = this.clientCredentialsSearchByPortal.value
    modalRef.componentInstance.portals = ['No portal filter', ...this.portals]

    modalRef.result.then(
      (closed) => {
        let hasChanges = false
        if (closed?.portal) {
          this.clientCredentialsSearchByPortal.setValue(closed.portal)
          hasChanges = true
        }
        if (closed?.enabledFilter && closed.enabledFilter in this.enabledFilterOptions) {
          this.clientCredentialsSearchByEnabled.setValue(closed.enabledFilter)
          hasChanges = true
        }
        // Reset page to 1 if filters are changed
        if (hasChanges) {
          this.page.setValue(1)
        }
      },
      (dismissed) => {},
    )
  }

  /**
   * Checks out selected client credential to the current user
   *
   * @param {string} id
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialsListPage
   */
  async checkOutCredentials(id: string): Promise<void> {
    try {
      let result = await this.clientCredentialsService.checkOutClientCredentials(id)
      if (result) {
        this.toast.success('Successfully checked out client credential')
        this.isLoading = true
        this.page.setValue(this.page.value)
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not check out client credentials'), JSON.stringify(e))
    }
  }

  /**
   * Checks in the selected client credential
   *
   * @param {string} id
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialsListPage
   */
  async checkInCredentials(id: string): Promise<void> {
    try {
      let result = await this.clientCredentialsService.checkInClientCredentials(id)
      if (result) {
        this.toast.success('Successfully checked in client credential')
        this.isLoading = true
        this.page.setValue(this.page.value)
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not check in client credentials'), JSON.stringify(e))
    }
  }

  /**
   * Helper method to get display value for 'Checked Out By' column
   *
   * @param {{ credential: ClientCredential; checkedOutBy: User }} clientCred
   * @return {*}  {string}
   * @memberof ClientCredentialsListPage
   */
  getCheckedOutByDisplay(clientCred: { credential: ClientCredential; checkedOutBy: User }): string {
    return clientCred.checkedOutBy?.email || clientCred.checkedOutBy?.name || 'N/A'
  }

  /**
   * Helper method to get display value for 'Concurrency' column
   *
   * @param {{ credential: ClientCredential }} clientCred
   * @return {*}  {string}
   * @memberof ClientCredentialsListPage
   */
  getConcurrencyDisplay(clientCred: { credential: ClientCredential }): string {
    // If the concurrency is null or undefined, display 'N/A', else display the value
    return clientCred.credential.concurrency != null ? clientCred.credential.concurrency.toString() : 'N/A'
  }
}
