import { Component, Input, OnInit } from '@angular/core'
import { EmbeddingContext, createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import { AuthenticationService } from 'app/auth/authentication.service'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { debug } from 'app/shared/utils/debug'
import { QuicksightDashboardType } from './quicksight-dashboard'
import { LoadingComponent } from '../loading/loading.component'
import { CommonModule } from '@angular/common'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

const DASHBOARD_DEBUG_CHANNEL = 'dashboard' as const
@Component({
  selector: 'app-quicksight-dashboard',
  templateUrl: './quicksight-dashboard.component.html',
  host: {
    class: 'h-100 w-100',
  },
  standalone: true,
  imports: [LoadingComponent, CommonModule, NgbModule],
})
export class QuicksightDashboardComponent implements OnInit {
  @Input() dashboardType: QuicksightDashboardType
  destinationDivId = 'quicksight-dashboard-id'
  embedUrl: string
  destinationDiv: HTMLElement
  isLoading = false
  embeddingContext: EmbeddingContext

  _embedUrlPromise: Promise<void>

  constructor(private authenticationService: AuthenticationService, private featureFlagService: FeatureFlagService) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true
    try {
      const [embedUrl, bookmarksFeatureFlag] = await Promise.all([
        this.authenticationService.getQuicksightDashboardUrl(this.dashboardType),
        this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.quicksightDashboard.bookmarks),
      ])
      this.embedUrl = embedUrl
      if (this.embedUrl) {
        this.destinationDiv = document.getElementById(this.destinationDivId)
        await this.initEmbeddingContext()
        await this.embedDashboard(bookmarksFeatureFlag)
      }
    } catch (e) {
      debug('dashboard', 'error getting dashboard URL', e)
    } finally {
      this.isLoading = false
    }
  }

  async initEmbeddingContext(): Promise<void> {
    this.embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent) => {
        if (changeEvent.eventName === 'ERROR_OCCURRED') {
          debug(DASHBOARD_DEBUG_CHANNEL, 'error embedding dashboard', changeEvent)
          this.isLoading = false
          return
        }

        if (changeEvent.eventName === 'FRAME_LOADED') {
          this.isLoading = false
        }
      },
    })
  }

  /**
   * Asynchronously embeds a QuickSight dashboard into a specified HTML div.
   *
   * This function uses the Amazon QuickSight Embedding SDK to embed a dashboard.
   * This involves the creation of an embedding context, and then using this context to
   * embed the dashboard into a specified HTML div. The function also sets
   * up listeners for certain events related to the embedded dashboard and logs these
   * for monitoring the dashboard's behavior.
   *
   * Reasons for embedding options:
   * - withIframePlaceholder: Shows a loading spinner while the dashboard is loading.
   * - toolbarOptions: Shows the toolbar with the options our users have requested.
   * - onChange: Logs events related to the dashboard's behavior.
   * - onMessage: Logs events related to the dashboard's behavior.
   *
   * @see {@link https://www.npmjs.com/package/amazon-quicksight-embedding-sdk}
   *      for more details on embedding options.
   */
  async embedDashboard(bookmarks: boolean = true): Promise<void> {
    if (this.embedUrl && this.destinationDiv) {
      try {
        const { embedDashboard } = this.embeddingContext
        await embedDashboard(
          {
            url: this.embedUrl,
            container: this.destinationDiv,
            withIframePlaceholder: true,
            // These may be useful so leaving them commented out here
            // height: '100%',
            // width: '100%',
            // resizeHeightOnSizeChangedEvent: true,
          },
          {
            toolbarOptions: {
              bookmarks,
              export: true,
              reset: true,
              undoRedo: true,
            },
            onMessage: (message) => {
              switch (message.eventName) {
                case 'CONTENT_LOADED': {
                  debug(DASHBOARD_DEBUG_CHANNEL, 'dashboard content loaded', message)
                  break
                }
                case 'ERROR_OCCURRED': {
                  debug(DASHBOARD_DEBUG_CHANNEL, 'dashboard error occurred', message)
                  break
                }
              }
            },
          },
        )
      } catch (e) {
        debug(DASHBOARD_DEBUG_CHANNEL, 'error embedding dashboard', e)
        return null
      }
    }
  }
}
