import { DIALOG_DATA } from '@angular/cdk/dialog'
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Output,
  Signal,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core'
import { JuiButtonComponent, JuiDialogComponent } from '@janushealthinc/janus-ui-kit'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { User } from 'generated/graphql'
import { take, timer } from 'rxjs'

export interface ConfirmDeleteUsersDialogData {
  users: User[]
}

/**
 * TODO: Refactor the timeout logic out of this component.
 */
@Component({
  imports: [JuiButtonComponent, JuiDialogComponent, NgbModule],
  selector: 'app-confirm-delete-users-modal',
  standalone: true,
  styleUrl: './confirm-delete-users-modal.component.scss',
  templateUrl: './confirm-delete-users-modal.component.html',
})
export class ConfirmDeleteUsersDialogComponent implements AfterViewInit {
  data: ConfirmDeleteUsersDialogData = inject(DIALOG_DATA)

  /**
   * Returns `true` when the disable timeout is non-zero.
   */
  deleteIsDisabled: Signal<boolean> = computed(() => {
    return this.disableTimeout() > 0
  })

  /**
   * How long (in seconds) to disable the delete button. We do this to prevent
   * accidental misclicks on modal popup.
   */
  disableTimeout: WritableSignal<number> = signal(2)

  @Output()
  dialogCancel = new EventEmitter<void>()

  @Output()
  dialogDelete = new EventEmitter<void>()

  ngAfterViewInit(): void {
    // After 1 second, every 1 second, decrement the disable timeout.
    timer(1000, 1000)
      .pipe(take(this.disableTimeout()))
      .subscribe(() => {
        this.disableTimeout.update((previous) => previous - 1)
      })
  }

  onCancel(): void {
    this.dialogCancel.emit()
  }

  onDelete(): void {
    this.dialogDelete.emit()
  }
}
