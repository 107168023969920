import { Component } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'vx-dropdown-button',
  templateUrl: './vx-dropdown-button.component.html',
  standalone: true,
  imports: [NgbModule],
})
export class VxDropdownButtonComponent {}
