import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { LoadingComponent } from 'app/shared/components/loading/loading.component'
import { ProgressModalComponent } from 'app/shared/components/progress-modal/progress-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { VxFileService } from 'app/vision-x/services/vx-file.service'
import { saveAs } from 'file-saver'
import { VxDocument } from 'generated/graphql'

@Component({
  selector: 'app-export-vx-document-file-button',
  standalone: true,
  imports: [CommonModule, LoadingComponent],
  templateUrl: './export-vx-document-file-button.component.html',
})
export class ExportVxDocumentFileButtonComponent {
  @Input() vxDocument: VxDocument

  isDownloadingDocumentFile: boolean = false

  constructor(private vxFileService: VxFileService, private modal: NgbModal, private toast: ToastService) {}

  async exportDocumentFile(): Promise<void> {
    this.isDownloadingDocumentFile = true

    if (this.vxDocument.concatenatedPageFileId) {
      this.downloadConcatenatedPageFile(this.vxDocument)
      this.isDownloadingDocumentFile = false
      return
    }

    const downloadStartTime = Date.now()
    const modalRef = this.modal.open(ProgressModalComponent, {
      centered: true,
    })
    modalRef.componentInstance.title = this.vxDocument.name
    modalRef.componentInstance.total = 100
    this.isDownloadingDocumentFile = true
    this.vxFileService
      .generateVxDocumentConcatenatedPageFile(this.vxDocument.id)
      .then(async (updatedDocument) => {
        if (!updatedDocument) {
          this.toast.error('Error generating the document file')
          modalRef.close()
          return
        }
        // if componentInstance does not exist, modal has been closed and we do not want download
        if (modalRef.componentInstance) {
          await this.downloadConcatenatedPageFile(updatedDocument)
          modalRef.close()
        }
      })
      .catch(() => [this.toast.error('Could not export document file')])
      .finally(() => {
        this.isDownloadingDocumentFile = false
      })

    // these constants are guesses based off limited research
    const PROJECTED_GEN_FIXED_COST_TIME = 2500
    const PROJECTED_GEN_PAGE_TIME = 2000
    const calculateProgressBar = () => {
      const expectedTime = PROJECTED_GEN_FIXED_COST_TIME + this.vxDocument.vxPages.length * PROJECTED_GEN_PAGE_TIME
      const timeFromStart = Date.now() - downloadStartTime
      const percentToComplete = Math.min(1, timeFromStart / expectedTime)
      // 80% of the progress bar is what our projected time is, leaving 20% for unanticipated longer times
      let downloadPercentProgress = 80 * percentToComplete
      if (timeFromStart > expectedTime) {
        let numMillisecondsOver = timeFromStart - expectedTime
        // every 2 seconds over our projected time, we want to get halfway closer to the end of the progress bar (i.e 2 seconds longer to 90%, 4 seconds longer to 95%, etc.)
        downloadPercentProgress =
          downloadPercentProgress + (100 - downloadPercentProgress) / 2 ** Math.floor(numMillisecondsOver / 2000)
        // we don't want the progress bar to ever get to the very end in case things take way longer than expected
        downloadPercentProgress = 0.98 * downloadPercentProgress
      }
      // if componentInstance does not exist, modal has been closed
      if (modalRef.componentInstance) {
        modalRef.componentInstance.current = downloadPercentProgress
        setTimeout(() => {
          calculateProgressBar()
        }, 250)
      }
    }

    calculateProgressBar()
  }

  private async downloadConcatenatedPageFile(updatedDocument: VxDocument) {
    const vxDocument = await this.vxFileService.generateVxDocumentConcatenatedPageFile(updatedDocument.id)
    const fileContents = await this.vxFileService.loadVxFileContents(vxDocument.concatenatedPageFileId)
    const { contentType, extension } = this.getFileType(vxDocument.concatenatedPageFile.name)

    const blob = new Blob([fileContents], { type: contentType })
    saveAs(blob, updatedDocument.name + extension)
  }

  private getFileType(
    filename: string,
  ): { contentType: 'image/tiff'; extension: '.tif' } | { contentType: 'application/pdf'; extension: '.pdf' } {
    const fileExtension = filename.toLowerCase().split('.').at(-1)

    if (fileExtension === 'tif' || fileExtension === 'tiff') {
      return {
        contentType: 'image/tiff',
        extension: '.tif',
      }
    }

    if (fileExtension === 'pdf') {
      return {
        contentType: 'application/pdf',
        extension: '.pdf',
      }
    }
  }
}
