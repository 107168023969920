import { CommonModule } from '@angular/common'
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { CanvasControlsComponent } from 'app/shared/components/canvas-controls/canvas-controls.component'
import { ZOOM_DELTA } from 'app/vx-rule-editor/components/rule-editor-toolbar/rule-editor-toolbar.component'
import { VxDocument, VxDocumentExtractionField, VxPage } from 'generated/graphql'
import { zip } from 'lodash'
import { BehaviorSubject, Observable, Subject, fromEvent, merge } from 'rxjs'
import { debounceTime, delayWhen, filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators'

interface CanvasContextImage {
  canvas: HTMLCanvasElement
  context: CanvasRenderingContext2D
  image: HTMLImageElement
}

const MAX_PAGE_WIDTH: number = 1000
const CLIENT_WIDTH_OFFSET: number = 40

const TEXT_LABEL_HEIGHT: number = 20
const TEXT_FILL: string = 'rgba(0, 0, 0, 1)'
const TEXT_BACKGROUND_FILL: string = 'rgba(190, 190, 190, 1)'

const DEFAULT_ZOOM: number = 1

const HIGHLIGHT_FILL = 'rgba(40, 140, 191, 0.5)'

@Component({
  selector: 'vx-referral-viewer',
  standalone: true,
  imports: [CommonModule, CanvasControlsComponent],
  templateUrl: './vx-referral-viewer.component.html',
  styleUrl: './vx-referral-viewer.component.scss',
  host: {
    class: 'pt-4 d-flex flex-column align-items-center pl-3',
  },
})
export class VxReferralViewerComponent implements OnInit {
  @Input() vxDocument$: Observable<VxDocument | null>
  @Input() vxPages: VxPage[]
  @Input() uncategorizedVxPageLabel: string

  @Input() highlightExtraction$: Observable<VxDocumentExtractionField | null> = new Subject()

  @Output() setActiveVxPageFileId = new EventEmitter<string>()

  /** <VxPageId, RotationDegrees> */
  pageRotations: Record<string, number> = {}
  contextMap: Map<string, CanvasContextImage> = new Map<string, CanvasContextImage>()
  /**
   * Instead of recreating the canvas rendering context each time the
   * `renderImages` method is called, store the rendering context in this
   * array by the page index. The contexts will only be recreated when the
   * `vxDocument$` changes.
   */
  private canvasCache: Array<{
    canvas: HTMLCanvasElement
    context: CanvasRenderingContext2D
    loadingImage: Promise<HTMLImageElement>
  }> = []

  destroy$ = new Subject<void>()
  intersectionObserver = new IntersectionObserver(
    (entries) => {
      if (entries.length) {
        const found = entries.find((entry) => entry.isIntersecting)
        if (found) {
          this.setActiveVxPageFileId.emit(found.target?.id)
        }
      }
    },
    {
      root: document.getElementById('vxDocumentPage-vxPages'),
      threshold: 0.5,
    },
  )

  @ViewChildren('pageContainer') pageContainer: QueryList<ElementRef<HTMLElement>>
  @ViewChildren('canvasItem') canvasItems: QueryList<ElementRef<HTMLElement>>

  ngOnInit(): void {
    this.vxPages.forEach((vxPage) => (this.pageRotations[vxPage.id] = 0))
  }

  ngAfterViewInit(): void {
    // Creates an `Observable<HTMLElement>` so that a reference to the page
    // container can be passed directly to canvas-related methods so that those
    // methods can avoid having to get a reference to that element on their own.
    const firstPageContainerElement$ = this.canvasItems.changes.pipe(
      startWith(this.canvasItems),
      map(() => this.pageContainer.first.nativeElement),
      filter((pageContainer) => pageContainer instanceof HTMLDivElement),
    )

    // This observable emits when the <canvas /> elements change,
    // usually when the vxDocument$ observable emits from extraction data being updated.
    const canvases$ = this.canvasItems.changes.pipe(
      startWith(this.canvasItems),
      debounceTime(5),
      map(() => this.canvasItems.first.nativeElement),
      filter((canvasItems) => canvasItems instanceof HTMLCanvasElement),
    )

    // This observable emits when the underlying page data may have changed and
    // thus existing canvases will need to be scraped and rebuilt.
    //
    // *Note:* this pipe uses the `delayWhen` operator so that the underlying
    // `Observable<VxDocument>` only emits _after_ the `canvasItems` elements
    // have been updated in the DOM. This is necessary because the
    // `createRenderingContexts` method requires the `<canvas>` elements to
    // exist before it's called.
    const rebuildCanvases$: Observable<[VxDocument, HTMLElement]> = this.vxDocument$.pipe(
      delayWhen(() => canvases$),
      withLatestFrom(firstPageContainerElement$),
    )

    // This is a helper subject that emits *after* the canvas elements have been
    // rebuilt and the 2d rendering cache has been updated.
    const afterCanvasesRebuilt$ = new Subject<void>()

    // This observable emits for events that should cause existing canvases to
    // be redrawn. This is different from `rebuildCanvases$` because these
    // changes don't indicate that the underlying page data is different, just
    // that its appearance has changed.
    const redrawCanvasesOnly$ = merge(
      afterCanvasesRebuilt$,
      fromEvent(window, 'resize').pipe(debounceTime(100)),
      this.zoomLevel$,
      this.highlightExtraction$.pipe(startWith(null)),
    ).pipe(
      withLatestFrom(this.vxDocument$, firstPageContainerElement$, this.highlightExtraction$.pipe(startWith(null))),
      map(([, vxDocument, pageContainer, highlightExtraction]) => {
        return [vxDocument, pageContainer, highlightExtraction] as const
      }),

      // Sometimes (like when the view first loads) a bunch of these events
      // can fire at once. Add 0ms debounce so if all the events land at the
      // same time this observable re-renders the canvas only once.
      debounceTime(0),
    )

    rebuildCanvases$.pipe(takeUntil(this.destroy$)).subscribe(([vxDocument, pageContainer]) => {
      this.createRenderingContexts(vxDocument.vxPages, pageContainer)
      afterCanvasesRebuilt$.next()
    })

    // Listen for events that need the canvases to be redrawn.
    redrawCanvasesOnly$.pipe(takeUntil(this.destroy$)).subscribe(([vxDocument, pageContainer, highlightExtraction]) => {
      this.renderImages(vxDocument, pageContainer, highlightExtraction)
    })
  }

  ngOnDestroy(): void {
    this.intersectionObserver?.disconnect()
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Render images each within their own canvases.
   */
  async renderImages(
    vxDocument: VxDocument,
    pageContainer: HTMLElement,
    highlightExtraction: VxDocumentExtractionField | null,
  ): Promise<void> {
    this.intersectionObserver?.disconnect()

    const zoomLevel = this.zoomLevel$.value
    const scaledPageWidth = zoomLevel * Math.min(pageContainer.clientWidth - CLIENT_WIDTH_OFFSET, MAX_PAGE_WIDTH)

    for (const [page, { canvas, context, loadingImage }] of zip(vxDocument.vxPages, this.canvasCache)) {
      const img = await loadingImage
      const scale = scaledPageWidth / img.naturalWidth
      const scaledImageHeight = img.naturalHeight * scale

      canvas.height = scaledImageHeight
      canvas.width = scaledPageWidth
      context.drawImage(img, 0, 0, canvas.width, canvas.height)
      // background for page type text
      context.fillStyle = TEXT_BACKGROUND_FILL
      context.rect(0, canvas.height - (TEXT_LABEL_HEIGHT + 6), canvas.width, TEXT_LABEL_HEIGHT + 6)
      context.fill()
      // page type text
      context.fillStyle = TEXT_FILL
      context.font = `${TEXT_LABEL_HEIGHT}px sans-serif`
      context.fillText(page?.vxPageType?.name ?? this.uncategorizedVxPageLabel, 8, canvas.height - 6)
      this.intersectionObserver.observe(canvas)

      if (highlightExtraction?.vxPageId === page.id) {
        const { x, y, w, h } = this.scaleExtractionCoordinates(pageContainer, img, highlightExtraction)
        context.fillStyle = HIGHLIGHT_FILL
        context.rect(x, y, w, h)
        context.fill()
      }

      this.contextMap.set(page.id, { context, canvas, image: img })
    }
  }

  private createRenderingContexts(pages: VxPage[], pageContainer: HTMLElement): void {
    const canvases = pageContainer.querySelectorAll('canvas')
    const cache: VxReferralViewerComponent['canvasCache'] = []

    // Create a new rendering context for each page. This doesn't render
    // anything to the context since all that logic happens in the
    // `renderImages` method.
    for (let i = 0; i < canvases.length; i++) {
      const canvas = canvases[i]
      const page = pages[i]
      canvas.id = page.unprocessedImageFileId
      cache[i] = {
        canvas,
        context: canvas.getContext('2d'),
        loadingImage: new Promise((resolve) => {
          const img = new Image()
          img.src = `/api/vx-file/${page.unprocessedImageFileId}`
          img.onload = () => resolve(img)
        }),
      }
    }

    this.canvasCache = cache
  }

  rotatePage(vxPage: VxPage, rotationDegrees: number): void {
    const zoomLevel = this.zoomLevel$.value
    const scaledPageWidth =
      zoomLevel * Math.min(this.pageContainer.first.nativeElement.clientWidth - CLIENT_WIDTH_OFFSET, MAX_PAGE_WIDTH)

    this.pageRotations[vxPage.id] += rotationDegrees
    if (this.pageRotations[vxPage.id] >= 360) {
      this.pageRotations[vxPage.id] -= 360
    } else if (this.pageRotations[vxPage.id] < 0) {
      this.pageRotations[vxPage.id] += 360
    }

    const { context, canvas, image } = this.contextMap.get(vxPage.id)
    const isLandscape = image.naturalHeight < image.naturalWidth
    const scale = scaledPageWidth / image.naturalWidth
    const scaledImageHeight = (isLandscape ? image.naturalWidth : image.naturalHeight) * scale

    context.clearRect(0, 0, canvas.width, canvas.height)
    canvas.height = isLandscape ? scaledPageWidth : scaledImageHeight
    canvas.width = isLandscape ? scaledImageHeight : scaledPageWidth

    context.drawImage(image, 0, 0, canvas.width, canvas.height)
    // background for page type text
    context.fillStyle = TEXT_BACKGROUND_FILL
    context.rect(0, canvas.height - (TEXT_LABEL_HEIGHT + 6), canvas.width, TEXT_LABEL_HEIGHT + 6)
    context.fill()
    // page type text
    context.fillStyle = TEXT_FILL
    context.font = `${TEXT_LABEL_HEIGHT}px sans-serif`
    context.fillText(vxPage?.vxPageType?.name ?? this.uncategorizedVxPageLabel, 8, canvas.height - 6)
    this.intersectionObserver.observe(canvas)

    context.rotate(this.pageRotations[vxPage.id])
  }

  private scaleExtractionCoordinates(
    pageContainer: HTMLElement,
    image: HTMLImageElement,
    extraction: VxDocumentExtractionField,
  ): { x: number; y: number; w: number; h: number } {
    const zoomLevel = this.zoomLevel$.value
    const scaledPageWidth = zoomLevel * Math.min(pageContainer.clientWidth - CLIENT_WIDTH_OFFSET, MAX_PAGE_WIDTH)
    const scale = scaledPageWidth / image.naturalWidth
    return {
      x: scale * extraction.extractionLocationLeft,
      y: scale * extraction.extractionLocationTop,
      w: scale * extraction.extractionLocationWidth,
      h: scale * extraction.extractionLocationHeight,
    }
  }

  // ZOOM AND PAN CONTROLS

  zoomLevel$ = new BehaviorSubject<number>(DEFAULT_ZOOM)
  isPanningEnabled = false
  isCurrentlyPanning = false
  panningInitialMouseClientCoord = { x: 0, y: 0 }
  panningInitialScrollOffset = { left: 0, top: 0 }

  /**
   * zoom all documents in
   */
  zoomIn(): void {
    this.zoomLevel$.next(this.zoomLevel$.value * ZOOM_DELTA)
  }

  /**
   * zoom all documents out
   */
  zoomOut(): void {
    this.zoomLevel$.next(this.zoomLevel$.value / ZOOM_DELTA)
  }

  /**
   * Set zoom to a specific level
   * @param zoomLevel {number}
   */
  setZoom(zoomLevel: number): void {
    this.zoomLevel$.next(zoomLevel)
  }

  getCanvasClasses(shiftDown: boolean): { grabbable: boolean; grabbing: boolean; 'mt-5': boolean; 'pt-5': boolean } {
    return {
      grabbable: this.isPanningEnabled && !this.isCurrentlyPanning,
      grabbing: this.isPanningEnabled && this.isCurrentlyPanning,
      'mt-5': shiftDown,
      'pt-5': shiftDown,
    }
  }

  /**
   * Toggle document panning
   */
  togglePanning(): void {
    this.isPanningEnabled = !this.isPanningEnabled
  }

  handleMouseDown(event: MouseEvent): void {
    if (!this.isPanningEnabled) {
      // Ignore if the panning tool is disabled
      return
    }

    event.preventDefault()
    event.stopPropagation()

    this.isCurrentlyPanning = true

    // Record the clientX and clientY coordinates of the mouse when it began
    // panning. The clientX/clientY are relative to the *viewport* (i.e. the
    // browser window) so these coordinates will use the same coordinate
    // system no matter whether the mouse event originated from an HTML element
    // or from the document.
    this.panningInitialMouseClientCoord = {
      x: event.clientX,
      y: event.clientY,
    }

    // Record the top and left scroll offsets of the page container element. This
    // will be useful to ensure that as the user pans around the scrollable region,
    // all of those scrolling updates will be relative to the scroll position when
    // they began panning.
    this.panningInitialScrollOffset = {
      left: this.pageContainer.first.nativeElement.scrollLeft,
      top: this.pageContainer.first.nativeElement.scrollTop,
    }
  }

  // Listen for the mouse move event on the document so that even if the cursor
  // exits page element--as long as the mouse remains down--the panning behavior
  // will continue.
  @HostListener('document:mousemove', ['$event'])
  handleMouseMove(event: MouseEvent): void {
    if (!this.isCurrentlyPanning) {
      // Ignore if user is not panning at this moment
      return
    }

    event.preventDefault()
    event.stopPropagation()

    // What is the mouse's current coordinates relative to the viewport?
    const currentClientX = event.clientX
    const currentClientY = event.clientY

    // What is the difference betwen the mouse's current coordinates and
    // the mouse coordinates when they began panning?
    const deltaX = currentClientX - this.panningInitialMouseClientCoord.x
    const deltaY = currentClientY - this.panningInitialMouseClientCoord.y

    // Combine the mouse delta with the initial scroll offsets to determine
    // the desired scroll offsets after this mouse movement.
    //
    // *Note:* the deltas are *subtracted* from the scroll offsets because
    // in order for the pan physics to be intuitive, the scroll direction is
    // *opposite* of the mouse movement direction.
    const desiredScrollLeft = this.panningInitialScrollOffset.left - deltaX
    const desiredScrollTop = this.panningInitialScrollOffset.top - deltaY

    // Apply the newly computed scroll offsets
    this.pageContainer.first.nativeElement.scrollTo({
      left: desiredScrollLeft,
      top: desiredScrollTop,
    })
  }

  // Listen for the mouse up event on the document so that even if the cursor
  // exits page element and lifts the mouse the panning behavior will cease.
  //
  // This prevents the bug where moving the mouse outside the element and
  // releasing the button then returning the mouse to the element causes
  // the element to keep behaving as if the mouse was being pressed.
  @HostListener('document:mouseup')
  handleMouseUp(): void {
    if (!this.isCurrentlyPanning) {
      return
    }

    this.isCurrentlyPanning = false
  }
}
