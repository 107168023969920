<!-- host (referral-viewer-component) styled with: 'pt-4 d-flex flex-column align-items-center pl-3' -->
<div id="vx-page-view" class="pt-4 position-absolute top-0 bottom-0 overflow-auto left-0 right-0 pl-3" #pageContainer>
  <div class="position-relative justify-content-center mx-auto" *ngFor="let vxPage of vxPages; let isFirst = first">
    <div class="d-flex justify-content-center mx-auto">
      <div *ngIf="isFirst" class="my-2 position-absolute right-0 z-10 mr-4">
        <app-canvas-controls
          [showResetZoom]="false"
          [isPanning]="isPanningEnabled"
          [zoomLevel]="zoomLevel$ | async"
          (zoomIn)="zoomIn()"
          (zoomOut)="zoomOut()"
          (setZoom)="setZoom($event)"
          (togglePanning)="togglePanning()"
        ></app-canvas-controls>
      </div>
      <div class="position-relative">
        <canvas
          [ngStyle]="{ transform: 'rotate(' + pageRotations[vxPage.id] + 'deg)' }"
          [ngClass]="getCanvasClasses(isFirst)"
          class="mb-4"
          (mousedown)="handleMouseDown($event)"
          #canvasItem
        ></canvas>
        <div
          class="position-absolute m-2 top-0 right-0 d-flex flex-row"
          [ngClass]="{ 'pt-5': isFirst, 'mt-5': isFirst }"
        >
          <i (click)="rotatePage(vxPage, -90)" class="fa fa-lg fa-rotate-left m-2 cursor-pointer"></i>
          <i (click)="rotatePage(vxPage, 90)" class="fa fa-lg fa-rotate-right m-2 cursor-pointer"></i>
        </div>
      </div>
    </div>
  </div>
</div>
