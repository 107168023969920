<div class="modal-header">
  <h4 class="modal-title">Filter Client Credentials</h4>
</div>
<form [formGroup]="filters" (ngSubmit)="applyFilters()">
  <div class="modal-body" id="client-credentials-filter-modal">
    <fieldset class="form-group py-3">
      <legend>Filter by credential enabled/disabled</legend>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          formControlName="enabledFilterControl"
          name="enabledFilterControl"
          value="all"
          id="all"
        />
        <label class="form-check-label" for="all">All</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          formControlName="enabledFilterControl"
          name="enabledFilterControl"
          value="enabledOnly"
          id="enabledOnly"
          data-t="enabled-filter-option"
        />
        <label class="form-check-label" for="enabledOnly">Enabled</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          formControlName="enabledFilterControl"
          name="enabledFilterControl"
          value="disabledOnly"
          id="disabledOnly"
          data-t="disabled-filter-option"
        />
        <label class="form-check-label" for="disabledOnly">Disabled</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          name="enabledFilterControl"
          formControlName="enabledFilterControl"
          value="disabledByUser"
          id="disabledByUserOnly"
          data-t="disabled-by-user-filter-option"
        />
        <label class="form-check-label" for="disabledByUserOnly">Disabled by user</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          name="enabledFilterControl"
          class="form-check-input"
          formControlName="enabledFilterControl"
          value="disabledByBot"
          id="disabledByBotOnly"
          data-t="disabled-by-bot-filter-option"
        />
        <label class="form-check-label" for="disabledByBotOnly">Disabled automatically</label>
      </div>
      <hr class="my-3" />
      <legend>Filter by portal</legend>
      <span class="portal-filter-disclaimer">
        <i class="fa fa-info-circle"> </i>
        Only works for portals that are tied to a bot job.
      </span>
      <select class="custom-select w-100" (change)="changePortal($event)">
        <option *ngFor="let op of portals" [ngValue]="op" [value]="op">{{ op }}</option>
      </select>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="resetFilters()">Reset</button>
    <button
      type="button"
      class="btn btn-cancel"
      (click)="modal.dismiss('Close click')"
      data-t="cancel-task-group-filters-button"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-brand-green px-5" data-t="apply-task-group-filters-button" ngbAutofocus>
      Apply
    </button>
  </div>
</form>
