import { Component } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { OrgService } from 'app/admin/org/org.service'
import { ReactiveFormsModule, FormGroup, FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { ViewerComponent } from './viewer/viewer.page'

@Component({
  selector: 'app-integrations-table-manager',
  templateUrl: './integrations-table-manager.page.html',
  styleUrls: ['./integrations-table-manager.page.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, ViewerComponent],
})
export class IntegrationTableManager {
  // Database selection
  constructor(private apollo: Apollo, private orgService: OrgService) {}
  selectedEndpoint: string = ''
  endpoints = [
    { name: 'Integration Billing Provider', value: 'integrationBillingprovider' },
    { name: 'Integration Payer Plan Mapping (TBD)', value: null },
  ] //starting databases
  form: FormGroup = new FormGroup({})
  formFields: string[] = []
  entries: any[] = []
  page: number = 1
  pageSize: number = 5
  totalEntries: number = 0
  orgs = []
  dbService: any

  onDatabaseSelect(event: Event) {
    const input = event.target as HTMLInputElement
    this.selectedEndpoint = input.value
    this.loadEntries()
  }

  loadEntries() {
    if (!this.selectedEndpoint) return
    //View-component functions
  }
}
