import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ToastService } from 'app/shared/services/toast.service'

@Injectable({
  providedIn: 'root',
})
export class EdiDashboardService {
  constructor(
    private http: HttpClient,
    private toast: ToastService
  ) {}

  sendEdi(transactions: any[]) {
    return this.http.post('/api/sendEdi', { transactions }).subscribe({
      next: (response) => {
        this.toast.success('Transactions processed successfully')
      },
      error: (err) => {
        this.toast.error('Unable to process transactions', JSON.stringify(err))
      }
    })
  }
}
