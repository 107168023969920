<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between row">
    <div class="col-sm">
      <div class="input-group form-group">
        <app-search-field [label]="'Search Npi'" [search]="npiSearch" [searching]="isSearchingNPIs">
        </app-search-field>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
    </div>
  </div>
  <div class="d-flex flex-column flex-shrink-1 flex-basis-0 table-responsive-md">
    <table class="table">
      <thead class="bg-alt-light-blue text-alt-white">
        <tr>
          <th scope="col" class="width-1" sortable="groupName" direction="asc" (sort)="onSort($event)">NPI</th>
          <th scope="col" class="width-1 text-nowrap">Tax Ids</th>
          <th scope="col" class="width-1 text-nowrap">Facility Name</th>
          <th scope="col" class="width-1 text-nowrap">Edit</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mapping of npiToTaxIdMappings">
          <ng-container>
            <td>{{mapping.npi}}</td>
            <td>{{mapping.taxId}}</td>
            <td>{{mapping.facilityName}}</td>
            <td>
              <button class="btn bg-alt-light-blue text-alt-white text-nowrap" routerLink="/admin/npi-mappings/{{mapping.id}}">
                Edit
              </button>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    <app-loading *ngIf="isLoading" innerClass="fa-4x" outerClass="align-self-center text-primary"
      class="d-flex flex-column flex-grow-1 justify-content-center"></app-loading>
  </div>
  <div class="d-flex justify-content-end row">
    <div class="col-3 d-flex justify-content-end">
      <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
    </div>
  </div>
  <div class="d-flex row">
    <div class="col-sm-4">
      <div class="input-group form-group justify-content-start">
        <ng-container *ngIf="!isAddingMapping; else addMappingSection">
          <button class="btn bg-alt-light-blue text-alt-white text-nowrap" (click)="toggleAddingMapping()">
            Add Mapping
          </button>
        </ng-container>
        <ng-template #addMappingSection>
          <div [formGroup]="npiMappingForm" class="flex-center align-items-center gap-2">
            <div class="form-group">
              <input type="text" placeholder="NPI" formControlName="npiToAdd" class="fixed-input form-control input-size"
                container="body" />
              <div *ngIf="npiMappingForm.get('npiToAdd').invalid && npiMappingForm.get('npiToAdd').touched" class="text-danger">
                NPI must be a 10-digit number.
              </div>
            </div>
            <div class="form-group">
              <input type="text" placeholder="TaxId" formControlName="taxIdToAdd" class="fixed-input form-control input-size"
                container="body" />
              <div *ngIf="npiMappingForm.get('taxIdToAdd').invalid && npiMappingForm.get('taxIdToAdd').touched" class="text-danger">
                Tax ID must be a 9-digit number.
              </div>
            </div>
            <div class="form-group">
              <button class="btn bg-alt-light-blue text-alt-white text-nowrap"
                [disabled]="npiMappingForm.get('npiToAdd').invalid || npiMappingForm.get('taxIdToAdd').invalid"
                (click)="createMapping()">
                Save
              </button>
            </div>
            <div class="form-group">
              <button class="btn bg-alt-dark-gray text-alt-white text-nowrap" (click)="toggleAddingMapping()">
                Cancel
              </button>
            </div>
            </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
