import { Injectable } from '@angular/core'
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { VxDocumentTypeQuery, VxDocumentTypesQuery } from 'app/vision-x/vision-x.gql'
import { QueryVxDocumentTypeArgs, VxDocumentType, VxDocumentTypeList, VxProduct } from 'generated/graphql'
import { isNil } from 'lodash'
import { Observable } from 'rxjs'

type NameSortOptions = 'name:asc' | 'name:desc'

export enum NeedsExtraction {
  Always = 'always',
  OnlyWhenAutoCategorized = 'onlyWhenAutoCategorized',
  Never = 'never',
}

@Injectable({
  providedIn: 'root',
})
export class VxDocumentTypeService {
  constructor(private apollo: Apollo) {}

  fetchVxDocumentType(id: string): Observable<ApolloQueryResult<{ vxDocumentType: VxDocumentType }>> {
    return this.apollo.watchQuery<{ vxDocumentType: VxDocumentType }, QueryVxDocumentTypeArgs>({
      query: VxDocumentTypeQuery,
      variables: {
        id,
      },
    }).valueChanges
  }

  /**
   * Note: offset seems to be applied _after_ limiting the number of records.
   * So limit 1 and offset 1 will return 0 records.
   */
  fetchVxDocumentTypes(
    filters: {
      search?: string
      product?: VxProduct
    },
    limit?: number,
    offset?: number,
    sort?: NameSortOptions | null,
    options?: {
      fetchPolicy: WatchQueryFetchPolicy
    },
  ): Observable<ApolloQueryResult<{ vxDocumentTypes: VxDocumentTypeList }>> {
    options = { ...{ fetchPolicy: 'cache-first' }, ...options }
    return this.apollo.watchQuery<{ vxDocumentTypes: VxDocumentTypeList }, any>({
      fetchPolicy: options.fetchPolicy,
      query: VxDocumentTypesQuery,
      variables: {
        search: filters.search,
        product: filters.product,
        limit: !isNil(limit) ? limit : 100,
        offset: !isNil(offset) ? offset : 0,
        sort: sort || 'createdAt:asc',
      },
    }).valueChanges
  }
}
