<div class="form-group input-group">
  <input
    [type]="isPasswordVisible ? 'string' : 'password'"
    class="form-control shadow-sm"
    [formControl]="passwordFormControl"
    id="password"
    [appFcBsValidation]="passwordFormControl"
  />
  <div class="input-group-append" *ngIf="clientCredentialId">
    <span class="in-input-icon d-flex align-items-center">
      <button type="button" class="btn btn-link bg-transparent" (click)="passwordClickHandler()">
        <i
          class="fa fw fa-lg text-reset"
          [class.fa-eye]="!isPasswordVisible"
          [class.fa-eye-slash]="isPasswordVisible"
        ></i>
      </button>
    </span>
  </div>
</div>
