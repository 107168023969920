import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ToastService } from 'app/shared/services/toast.service'
import { omitDeep } from 'app/shared/utils/omitDeep'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { VxFieldGroupService } from 'app/vision-x/services/vx-field-group.service'
import { VxFormFieldService } from 'app/vision-x/services/vx-form-field.service'
import { VxPageTypeService } from 'app/vision-x/services/vx-page-type.service'
import { VxRuleEditorStateService } from 'app/vx-rule-editor/vx-rule-editor-state.service'
import {
  RegexField,
  VxPageType,
  VxPageTypeFieldGroup,
  VxPageTypeFieldGroupCreate,
  VxPageTypeFormField,
} from 'generated/graphql'
import { cloneDeep, omit } from 'lodash'
import { combineLatest, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { NEW_FIELD_GROUP_PREFIX } from '../rule-editor-positional-rules-pane/rule-editor-positional-rules-pane.component'
import { NEW_FORM_FIElD_PREFIX } from '../vx-form-rule-template-pane/vx-form-rule-template-pane.component'
export const ZOOM_DELTA = 1.1

@Component({
  selector: 'app-rule-editor-toolbar',
  templateUrl: './rule-editor-toolbar.component.html',
  styleUrls: ['./rule-editor-toolbar.component.scss'],
  host: {
    class: 'border-bottom-1 border-alt-light-gray p-2 d-flex flex-row',
  },
})
export class RuleEditorToolbarComponent implements OnInit, OnDestroy {
  constructor(
    public state: VxRuleEditorStateService,
    public toast: ToastService,
    public vxPageTypeService: VxPageTypeService,
    public vxFieldGroupService: VxFieldGroupService,
    public vxFormFieldService: VxFormFieldService,
  ) {}
  displayRelativeScale = 1
  destroyed$ = new Subject<void>()
  isPageVisibleCheckbox = new UntypedFormControl()

  ngOnInit(): void {
    combineLatest([this.state.zoomLevel$, this.state.zoomToFitLevel$])
      .pipe(
        filter(([zoomLevel, zoomToFitLevel]) => {
          // Only allow numbers
          return !isNaN(zoomLevel) && zoomLevel !== null && !isNaN(zoomToFitLevel) && zoomToFitLevel !== null
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(([zoomLevel, zoomToFitLevel]) => {
        /*
         * The percentage we display to the user is actually a relative value.
         *
         * ZoomToFit is calculated as soon as the image has been loaded and it's a scale value
         * that fits best on the screen. So users with different screens will see this
         * percentage be different. This value never changes after load and unique to each page and
         * a monitor size.
         *
         * ZoomLevel is the scaling value applied to the image with respect to user's input.
         * So what we display to the user is a current image scaled compared to it's initial loading
         * state.
         *
         */
        this.displayRelativeScale = zoomLevel / zoomToFitLevel
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  zoomIn(): void {
    const currentZoomLevel = this.state.zoomLevel$.getValue()
    const newZoomLevel = currentZoomLevel * ZOOM_DELTA
    this.state.zoomLevel$.next(newZoomLevel)
  }

  zoomOut(): void {
    const currentZoomLevel = this.state.zoomLevel$.getValue()
    const newZoomLevel = currentZoomLevel / ZOOM_DELTA
    this.state.zoomLevel$.next(newZoomLevel)
  }

  setZoom(level: number): void {
    this.state.zoomLevel$.next(level)
  }

  resetZoom(): void {
    this.state.editorCanvasResize$.next()
  }

  togglePanning(): void {
    const currentPanMode = this.state.isPanningEnabled$.getValue()
    this.state.isPanningEnabled$.next(!currentPanMode)
  }

  async saveChanges(): Promise<void> {
    const pendingFieldGroupUpdatesIds = this.state.vxFieldGroupsPendingUpdates$.getValue()
    const pendingFieldGroupRemovalIds = this.state.vxFieldGroupsPendingRemovals$.getValue()
    const fieldGroups = this.state.vxPageTypeFieldGroups$.getValue()
    const updatedFormFields = this.state.editedVxFormFields$.getValue()
    const removedFormFieldIds = this.state.vxFormFieldsPendingRemovals$.getValue()
    const existingFormFields = this.state.vxPageTypeFormFields$.getValue()
    const vxPageTypePendingUpdates = this.state.vxPageTypePendingUpdates$.getValue()
    try {
      /*
       * Some of these handlers are batched in different groups so they won't interfere with one another.
       * Simple rule of thumb to follow is to separate update and remove logic. Due to these functions
       * most likely would affect the same key of one model.
       */
      const [fieldGroupsAfterCreateAndUpdates, formFieldsAfterCreateAndUpdates] = await Promise.all([
        this.performFieldGroupUpdates(pendingFieldGroupUpdatesIds, fieldGroups),
        this.performFormFieldsUpdates(updatedFormFields, existingFormFields),
      ])

      await Promise.all([
        this.performFieldGroupRemoval(pendingFieldGroupRemovalIds, fieldGroupsAfterCreateAndUpdates),
        this.performFormFieldRemovals(removedFormFieldIds, formFieldsAfterCreateAndUpdates),
      ])

      await this.performPageTypeUpdates(vxPageTypePendingUpdates)

      /*
       * If all of the sets are empty, icon to save changes in the toolbar
       * should be in the default state.
       */
      this.state.clearAllChanges()
      this.toast.success('Successfully saved')
    } catch (err) {
      this.toast.error(parseGraphQLError(err, 'There was an error saving'), JSON.stringify(err))
    }
  }

  /**
   * Creates or updates field groups for the page type based off whether the updates correspond to something that already exists or not
   * This function will also update rule editor state to reflect the result of the API calls
   *
   * @param {Set<string>} fieldGroupUpdates The changes requested to be saved
   * @param {VxPageTypeFieldGroup[]} fieldGroups The state of the field groups before the saved changes
   */
  async performFieldGroupUpdates(
    fieldGroupUpdates: Set<string>,
    fieldGroups: VxPageTypeFieldGroup[],
  ): Promise<VxPageTypeFieldGroup[]> {
    if (!fieldGroupUpdates.size) {
      // There is nothing to update. So return successful operation response
      return fieldGroups
    }

    // Get a list of field groups that require updates so we can use as a vessel to save our changes to the db.
    const fieldGroupsRequireUpdates = fieldGroups.filter((fieldGroup) => fieldGroupUpdates.has(fieldGroup.id))
    const fieldGroupIdsRequireCreation = new Set(
      [...fieldGroupUpdates].filter((catids) => catids.includes(NEW_FIELD_GROUP_PREFIX)),
    )

    // Next we need prepare each field group for db inserts or updates
    const tree = this.state.vxExtractionRuleTree$.getValue()
    const pendingPromises: Promise<VxPageTypeFieldGroup>[] = []

    for (const fieldGroupRequiringUpdate of fieldGroupsRequireUpdates) {
      let preppedFieldGroup = cloneDeep(fieldGroupRequiringUpdate)
      delete preppedFieldGroup.id
      preppedFieldGroup.extractionRules = tree.exportExtractionRulesForFieldGroup(fieldGroupRequiringUpdate.id)
      // Make sure we keep the first extraction rule name the same as the field group
      if (preppedFieldGroup?.extractionRules?.dataKey) {
        preppedFieldGroup.name = preppedFieldGroup.extractionRules.dataKey
      }
      // prevents blank field errors
      preppedFieldGroup = omitDeep(preppedFieldGroup, '__typename')
      // for each prep field group there are extraction rules
      // on every extraction rule there can be multiple regex fields
      // for each regex field check its regex property
      // if the regex property is invalid
      // remove the field from the rules
      let safeRegexFields: RegexField[] =
        preppedFieldGroup.extractionRules.regexFields?.filter((f) => f && f.regex) || []
      preppedFieldGroup.extractionRules.regexFields = safeRegexFields
      // Derive extraction rule from the tree
      pendingPromises.push(
        this.vxFieldGroupService.updateVxPageTypeFieldGroup(fieldGroupRequiringUpdate.id, preppedFieldGroup),
      )
    }

    for (const fieldGroupId of fieldGroupIdsRequireCreation) {
      // Derive extraction rule from the tree
      const compiledExtractionRule = omitDeep(tree.exportExtractionRulesForFieldGroup(fieldGroupId), '__typename')
      const preppedFormGroup: VxPageTypeFieldGroupCreate = {
        name: compiledExtractionRule.dataKey,
        extractionRules: compiledExtractionRule,
        vxPageTypeId: this.state.vxPageTypeId$.getValue(),
      }

      pendingPromises.push(
        this.vxFieldGroupService.createVxPageTypeFieldGroup(preppedFormGroup).then((newFieldGroup) => {
          /*
           * We need to rebuild the tree for the brand new field groups. So that on the subsequent
           * save the extraction rules get updated for the correct id
           */
          const updatedTree = tree.updateFieldGroupId(fieldGroupId, newFieldGroup.id)
          this.state.vxExtractionRuleTree$.next(updatedTree)
          return newFieldGroup
        }),
      )
    }

    const createdAndUpdatedFieldGroups = await Promise.all([...pendingPromises])

    // update state with the response from the API calls
    const updatedFieldGroupsFromApi = cloneDeep(fieldGroups)
    createdAndUpdatedFieldGroups.forEach((createdOrUpdatedFieldGroup) => {
      const indexOfFieldGroupInState = updatedFieldGroupsFromApi.findIndex(
        (fieldGroup) => fieldGroup.id === createdOrUpdatedFieldGroup.id,
      )
      // replace state with what is updated from API calls
      if (indexOfFieldGroupInState > -1) {
        updatedFieldGroupsFromApi.splice(indexOfFieldGroupInState, 1, createdOrUpdatedFieldGroup)
      } else {
        updatedFieldGroupsFromApi.push(createdOrUpdatedFieldGroup)
      }
    })
    this.state.vxPageTypeFieldGroups$.next(updatedFieldGroupsFromApi)
    return updatedFieldGroupsFromApi
  }

  /**
   * Deletes field groups for the page type based off the requested field group IDs passed in
   * This function will also update rule editor state to reflect the deletions
   *
   * @param {Set<string>} fieldGroupRemovalIds A set of IDs of field groups that we want to delete
   * @param {VxPageTypeFieldGroup[]} fieldGroups The state of the field groups before the saved changes
   */
  async performFieldGroupRemoval(
    fieldGroupRemovalIds: Set<string>,
    fieldGroups: VxPageTypeFieldGroup[],
  ): Promise<void> {
    if (!fieldGroupRemovalIds.size) {
      /*
       * There is nothing to remove from DB so treat it as if
       * the response succeeded.
       */
      return
    }

    // make an API call to delete every field group in fieldGroupRemovalIds
    await Promise.all(
      [...fieldGroupRemovalIds.values()].map((fieldGroupId) =>
        this.vxFieldGroupService.deleteVxPageTypeFieldGroup([fieldGroupId]),
      ),
    )

    // update state with the effect from the API calls
    const updatedFieldGroups = fieldGroups.filter((fieldGroup) => !fieldGroupRemovalIds.has(fieldGroup.id))
    this.state.vxPageTypeFieldGroups$.next(updatedFieldGroups)
  }

  /**
   * Creates or updates form fields for the page type based off whether the updates correspond to something that already exists or not
   * This function will also update rule editor state to reflect the result of the API calls
   *
   * @param {Map<string, VxPageTypeFormField>} formFieldUpdates
   * @param {VxPageTypeFormField[]} existingFormFields
   */
  async performFormFieldsUpdates(
    formFieldUpdates: Map<string, VxPageTypeFormField>,
    existingFormFields: VxPageTypeFormField[],
  ): Promise<VxPageTypeFormField[]> {
    // create or update a form field for each entry in formFieldUpdates
    const createdAndUpdatedFormFields = await Promise.all(
      [...formFieldUpdates.entries()].map(([formFieldId, formFieldUpdates]): Promise<VxPageTypeFormField> => {
        const formFieldAlreadyExistsInDb = !formFieldId.includes(NEW_FORM_FIElD_PREFIX)
        if (formFieldAlreadyExistsInDb) {
          // form field already exists, just update it
          return this.vxFormFieldService.updateVxPageTypeFormField(formFieldId, omit(formFieldUpdates, 'id'))
        } else {
          // form field does not already exist, we need to create it
          return this.vxFormFieldService.createVxPageTypeFormField(omit(formFieldUpdates, 'id'))
        }
      }),
    )

    // update state with the response from the API calls
    const updatedFormFieldsFromApi = cloneDeep(existingFormFields)
    createdAndUpdatedFormFields.forEach((createdOrUpdatedFormField) => {
      const indexOfFieldGroupInState = updatedFormFieldsFromApi.findIndex(
        (fieldGroup) => fieldGroup.id === createdOrUpdatedFormField.id,
      )
      if (indexOfFieldGroupInState > -1) {
        updatedFormFieldsFromApi.splice(indexOfFieldGroupInState, 1, createdOrUpdatedFormField)
      } else {
        updatedFormFieldsFromApi.push(createdOrUpdatedFormField)
      }
    })
    this.state.vxPageTypeFormFields$.next(updatedFormFieldsFromApi)
    return updatedFormFieldsFromApi
  }

  /**
   * Deletes form fields for the page type based off the requested field group IDs passed in
   * This function will also update rule editor state to reflect the deletions
   *
   * @param {Set<string>} formFieldIdsForRemoval A set of IDs of form fields that we want to delete
   * @param {VxPageTypeFormField[]} formFields The form field state before we run the deletion
   */
  async performFormFieldRemovals(
    formFieldIdsForRemoval: Set<string>,
    formFields: VxPageTypeFormField[],
  ): Promise<void> {
    // Make API calls to delete every form field passed in
    await Promise.all(
      [...formFieldIdsForRemoval.values()].map((formFieldId) =>
        this.vxFormFieldService.deleteVxPageTypeFormField(formFieldId),
      ),
    )

    // update state with the effect from the API calls
    const updatedFormFields = formFields.filter((formField) => !formFieldIdsForRemoval.has(formField.id))
    this.state.vxPageTypeFormFields$.next(updatedFormFields)
  }

  async performPageTypeUpdates(vxPageType: Partial<VxPageType>): Promise<VxPageType | void> {
    if (!vxPageType) {
      /*
       * There is nothing to remove from DB so treat it as if
       * the response succeeded.
       */
      return
    }
    return this.vxPageTypeService
      .updateVxPageType(this.state.vxPageTypeId$.getValue(), vxPageType)
      .then((vxpagetype) => {
        this.state.vxPageType$.next(vxpagetype)
        return vxpagetype
      })
  }
}
