<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-basis-0 flex-grow-1 pt-2 scrolling-sidebar">
    <div class="card detail-card mb-5" *ngIf="authenticationService.isSuper()">
      <div class="card-header">Super Admin</div>
      <div class="card-body">
        <div class="row mt-5">
          <div class="col-md-4">
            <h3>Organization</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/org/logo">Change logo</a></li>
              <li><a routerLink="/admin/org/edit">Edit Orgs</a></li>
              <li><a routerLink="/admin/org/saml">SAML Configuration</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Feature Flags</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/feature-flags">List</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <app-observe-admin-links></app-observe-admin-links>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4">
            <h3>Bot Jobs</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/bot-jobs">List</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Payers</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/payers">List</a></li>
              <li><a routerLink="/admin/payers/combine">Combine</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Client Credentials</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/client-credentials">List</a></li>
            </ul>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4">
            <h3>Dashboard Publishing</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/dashboard-publishing">Dashboard Publication</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Prior-Auth</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/payer-plan-groups">Payer Plan Group Configuration</a></li>
              <li><a routerLink="/admin/edi-reader">EDI Reader</a></li>
              <li><a routerLink="/admin/edi-dashboard">EDI Dashboard</a></li>
              <li><a routerLink="/admin/npi-mappings">NPI Facility Configuration</a></li>
              <li><a routerLink="/admin/integration-table-manager">Integration Table Manager</a></li>
            </ul>
          </div>
          <div class="col-md-4" *ngIf="isQediEnabled">
            <app-qedi-admin-links></app-qedi-admin-links>
          </div>
        </div>
      </div>
    </div>
    <div class="card detail-card">
      <div class="card-header">Admin</div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <h3>Task Types</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/task-types/edit">Edit</a></li>
              <li><a routerLink="/admin/task-types/merge">Merge</a></li>
              <li><a routerLink="/admin/task-types/assign">Assign</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Task Groups</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/task-groups/edit">Edit</a></li>
              <li><a routerLink="/admin/task-groups/assign">Assign</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Tasks</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/tasks/delete" data-t="task-delete-link">Delete</a></li>
            </ul>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4" *ngIf="isWorkflowsEnabled">
            <h3>Workflows</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/workflows">List</a></li>
              <li><a routerLink="/admin/workflows/assign">Assign</a></li>
              <li><a routerLink="/admin/workflows/delete">Delete</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Observe Access Controls</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/observe/access-controls">List</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Query Center</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/automations-query-center">Automations</a></li>
            </ul>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4">
            <h3>Claim Searches</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/searches/edit">Edit</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Users</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/users">List</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h3>Teams</h3>
            <ul class="list-unstyled pl-4">
              <li><a routerLink="/admin/teams">List</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
