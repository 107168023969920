import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import {
  GetOneProcedureSetTest,
  GetProcedureSet,
  GetProcedureSetTests,
  GetProcedureSets,
  updateProcedureSetandProcedureStatus,
} from 'app/procedure-sets/procedure-sets.gql'
import { debug } from 'app/shared/utils/debug'
import {
  Procedure,
  ProcedureSet,
  ProcedureSetList,
  ProcedureSetTest,
  ProcedureSetTestList,
  QueryOneProcedureSetTestArgs,
  QueryProcedureSetArgs,
  QueryProcedureSetTestsArgs,
  QueryProcedureSetsArgs
} from 'generated/graphql'
import { uniq } from 'lodash'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

interface ProcedureSetsQueryOptions {
  requiresPriorAuth?: boolean // Will match the state of needing prior auth when provided
  limit?: number
}

@Injectable({
  providedIn: 'root',
})
export class ProcedureSetsService {
  constructor(private apollo: Apollo) {}

  /**
   * Fetch up to 100 procedure sets by patient MRN
   *
   * @param {string} patientMrn
   * @return {*}  {Promise<ProcedureSet[]>}
   * @memberof TeleportService
   */
  getProcedureSets(patientMrn: string, options?: ProcedureSetsQueryOptions): Observable<ProcedureSet[]> {
    return this.apollo
      .query<{ procedureSets: ProcedureSetList }, QueryProcedureSetsArgs>({
        query: GetProcedureSets,
        variables: {
          patientMrn: patientMrn,
          ...options,
        },
      })
      .pipe(map((result) => result.data?.procedureSets?.entities))
  }

  /**
   * Fetch up to 100 procedure sets tests by patient MRN
   *
   * @param {string} patientMrn
   * @return {*}  {Promise<ProcedureSet[]>}
   * @memberof TeleportService
   */
  getProcedureSetTests(patientMrn: string, options?: ProcedureSetsQueryOptions): Observable<ProcedureSetTest[]> {
    return this.apollo
      .query<{ procedureSetTests: ProcedureSetTestList }, QueryProcedureSetTestsArgs>({
        query: GetProcedureSetTests,
        variables: {
          patientMrn: patientMrn,
          ...options,
        },
      })
      .pipe(map((result) => result.data?.procedureSetTests?.entities))
  }

  /*
  *
  * To be updated once related changes are merged to janus-api
  *
  */
  getOneProcedureSetTest(id: string): Observable<ProcedureSetTest> {
    return this.apollo
      .query<{ oneProcedureSetTest: ProcedureSetTest }, QueryOneProcedureSetTestArgs>({
        query: GetOneProcedureSetTest,
        variables: {
          id,
        },
      })
      .pipe(
        first(),
        map((result) => result.data?.oneProcedureSetTest),
      )
  }
  getProcedureSet(id: string): Observable<ProcedureSet> {
    return this.apollo
      .query<{ procedureSet: ProcedureSet }, QueryProcedureSetArgs>({
        query: GetProcedureSet,
        variables: {
          id,
        },
      })
      .pipe(
        first(),
        map((result) => result.data?.procedureSet),
      )
  }

  getPatientForProcedureSet(patientMrn: string): Observable<string> {
    return this.getProcedureSets(patientMrn).pipe(
      first(),
      map((procedureSets) => {
        let patientNames = uniq(
          procedureSets
            .map((ps) => {
              if (ps?.dependentFirstName && ps?.dependentLastName) {
                return `${ps?.dependentFirstName} ${ps?.dependentLastName}`
              } else if (ps?.patientFirstName && ps?.patientLastName) {
                return `${ps?.patientFirstName} ${ps?.patientLastName}`
              } else {
                debug('getPatientForProcedureSets', 'found procedure set with no valid name entries', ps.id)
                return null
              }
            })
            .filter((name) => !!name),
        )

        if (patientNames.length > 1) {
          debug('getPatientForProcedureSets', 'procedure sets for this mrn contain multiple patientNames', patientNames)
        }
        return patientNames[0]
      }),
    )
  }

  /**
   * Update the status of a procedure set
   *
   * @param {string} id - The ID of the procedure set to update
   * @param {string} status - The new status value
   * @return {*}
   * @memberof ProcedureSetsService
   */

  updateProcedureSetandProcedureStatus(
    id: string,
    status: string,
    procedures: Partial<Procedure>[],
  ): Observable<ProcedureSet> {
    return this.apollo
      .mutate<{ updateProcedureSetandProcedureStatus: ProcedureSet }>({
        mutation: updateProcedureSetandProcedureStatus,
        variables: {
          procedureSetInput: {
            id,
            status,
            procedures,
          },
        },
      })
      .pipe(
        map((result) => {
          return result.data?.updateProcedureSetandProcedureStatus
        }),
      )
  }
}
