import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { getIntegrationBillingProviders } from 'app/admin/integrations-table-manager/services/integrationBillingProvider/integration-billing-provider.gql'
import { IntegrationBillingProviderList } from 'generated/graphql'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

/**
 *
 * @export
 * @class IntegrationBillingProviderService
 */
@Injectable({
  providedIn: 'root',
})
export class IntegrationBillingProviderService {
  constructor(private apollo: Apollo) {}

  get(
    orgId: string,
    limit?: number,
    offset?: number,
  ): Observable<ApolloQueryResult<{ IntegrationBillingProviders: IntegrationBillingProviderList }>> {
    return this.apollo
      .watchQuery<{ IntegrationBillingProviders: IntegrationBillingProviderList }>({
        query: getIntegrationBillingProviders,
        variables: {
          limit: limit,
          offset: offset,
          orgId,
        },
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        tap((result) => {
          result.data?.IntegrationBillingProviders
        }),
      )
  }
}
