import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Apollo } from 'apollo-angular'
import { BotRunsService } from 'app/revbot/bot-runs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ApiWorkerTask, BotRun } from 'generated/graphql'
import { Subject, combineLatest, of } from 'rxjs'
import { catchError, filter, first, map, switchMap, takeUntil } from 'rxjs/operators'

/**
 * Page to display overview data for a specific bot run
 *
 * @export
 * @class BotRunOverviewPage
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-bot-run-overview',
  templateUrl: './bot-run-overview.page.html',
})
export class BotRunOverviewPage implements OnDestroy, OnInit {
  @ViewChild('content', { static: true }) content: ElementRef // Modal
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel
  botId: string
  botRun: BotRun
  botRuns: BotRun[]
  images: string[]
  isLoading: boolean = true
  rawData: Record<string, unknown> = undefined
  openImageIndex: number
  apiWorkerTask: ApiWorkerTask
  destroy$ = new Subject<void>()
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private toast: ToastService,
    private route: ActivatedRoute,
    router: Router,
    public botRunsService: BotRunsService,
    private modal: NgbModal,
    private apollo: Apollo,
  ) {
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        map(() => {
          return {
            botJobId: this.route.snapshot.paramMap.get('botJobId'),
            botRunId: this.route.snapshot.paramMap.get('botRunId'),
          }
        }),
        switchMap((route) => {
          let botRun$ = this.botRunsService.getBotRun(route?.botRunId)
          let botRuns$ = this.botRunsService.filterBotRuns(route.botJobId, 0)
          let botRunArtifacts$ = this.botRunsService.getBotRunArtifacts(route?.botRunId)
          return combineLatest([botRun$, botRuns$, botRunArtifacts$])
        }),
        catchError((e) => {
          this.toast.error(parseGraphQLError(e, 'Could not fetch bot job'), JSON.stringify(e))
          return of([])
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(async ([botRun, botRuns, botRunArtifacts]) => {
        try {
          this.botRuns = botRuns?.data?.botRuns?.entities
          await this.setBotRun(botRun?.data?.botRun)
          if (botRunArtifacts?.data?.botRunArtifacts?.entities?.length) {
            this.images = botRunArtifacts.data.botRunArtifacts.entities
              .filter((artifact) => artifact.contentType === 'image/png')
              .map((artifact) => artifact.link)
          }
          this.setRawData()
        } catch (e) {
          this.toast.error(parseGraphQLError(e, 'Could not fetch bot run'), JSON.stringify(e))
        }
        this.isLoading = botRun?.loading || botRuns?.loading || botRunArtifacts?.loading || false
      })
  }
  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }
  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  async setBotRun(botRun: BotRun): Promise<void> {
    this.botRun = botRun

    if (this.botRun?.apiWorkerTaskId) {
      try {
        let workerTask = await this.botRunsService
          .getApiWorkerTask(this.botRun?.apiWorkerTaskId)
          .pipe(first())
          .toPromise()
        this.apiWorkerTask = workerTask?.data?.apiWorkerTask
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Failed fetching API Worker Task'), JSON.stringify(e))
      }
    }
  }

  /**
   * Helper to format a bot run's raw data
   *
   * @memberof BotRunOverviewPage
   */
  setRawData(): void {
    // if (!this.botRun?.rawData) {
    //   return
    // }
    // let pairs = this.botRun?.rawData.split(/\r?\n/)
    // let obj = {}
    // let keyValues = pairs.map((pair) => pair.split('='))
    // keyValues.map((pair) => {
    //   set(obj, pair[0], pair[1])
    // })
    // this.rawData = obj
  }

  /**
   * Launch modal to display a bot run's image artifacts
   *
   * @param {number} index
   * @memberof BotRunOverviewPage
   */
  openImage(index: number): void {
    this.openImageIndex = index
    this.modal.open(this.content, {
      centered: true,
    })
  }
}
