<div [ngSwitch]="uploadStatus">
  <ng-container *ngSwitchCase="'ready'">
    <form (ngSubmit)="uploadFiles()">
      <div class="drop-zone text-center" (drop)="onFilesDropped($event)" (dragover)="$event.preventDefault()">
        <i class="fa fa-upload fa-3x"></i>
        <p>
          <label class="file-input-label">
            Upload <input type="file" class="file-input" accept=".pdf" multiple hidden (change)="onFileSelected($event)"
              [formControl]="fileControl">
          </label> or drag and drop your files
        </p>
        <p class="sub-text">PDF</p>
      </div>

      <div class="file-list">
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let file of files; let i = index">
            <div class="d-flex flex-column justify-content-around">
              {{ file.file.name }}
              <div class="sub-text">
                <ng-container *ngIf="file.dataType !== 'unsupportedFile'; else fileErroredText">
                  {{ formatFileSize(file.file.size) }}
                </ng-container>
                <ng-template #fileErroredText>File cannot be submitted.</ng-template>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-around align-items-end">
              <ng-container *ngIf="file.dataType === 'supportedFile'; else fileErroredIcon">
                <button class="btn btn-icon" (click)="removeFile(i)">
                  <i class="fa fa-trash"></i>
                </button>
                <div class="sub-text">
                  Complete
                </div>
              </ng-container>
              <ng-template #fileErroredIcon>
                <button class="btn btn-icon" (click)="removeFile(i)">
                  <i class="fa fa-times-circle-o"></i>
                </button>
                <div class="sub-text">Failed -
                  <span [ngSwitch]="file.dataType" data-test-id="errorMessage">
                    <ng-container *ngSwitchCase="'unsupportedFile'">File type not supported</ng-container>
                    <ng-container *ngSwitchCase="'hugeFile'">File size cannot be larger than 25 MB.</ng-container>
                    <ng-container *ngSwitchCase="'totalFileSizeExceeded'">Total file size cannot be larger than 25 MB.
                      Please remove some files before uploading.</ng-container>
                  </span>
                </div>
              </ng-template>
            </div>
          </li>
        </ul>
      </div>

      <div *ngIf="files.length" class="button-container align-items-center">
        <div class="flex-grow-1" [style.color]="isTotalFileSizeExceeded() ? 'red' : ''">Total Size: {{
          formatFileSize(totalFileSize) }}</div>
        <button type="button" class="btn btn-cancel" (click)="cancel()">
          Cancel
        </button>
        <button data-test-id="submitUpload" type="submit" class="btn btn-brand-light-blue"
          [disabled]="checkAllFilesForErrors()"
          [ngbTooltip]="checkAllFilesForErrors() ? uploadForbiddenHelpMessage() : ''">
          <i class="fa fa-check"></i>
          Submit
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="'uploading'">
    <div class="text-center">
      <i class="fa fa-spin fa-spinner fa-3x"></i>
      <div class="mt-2">Submitting {{files.length}} file{{files.length > 1 ? 's': ''}} ...</div>
      <div class="sub-text">Please wait while your files are being submitted</div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'success'">
    <div class="text-center">
      <i class="fa fa-check-circle-o fa-3x"></i>
      <div class="mt-2" data-test-id="uploadSuccessMessage">File{{files.length > 1 ? 's': ''}} submitted successfully!
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let file of files; let i = index">
          <div class="d-flex flex-column justify-content-around">
            {{ file.file.name }}
          </div>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'fail'">
    <div class="text-center">
      <i class="fa fa-times-circle-o fa-3x"></i>
      <div class="mt-2" data-test-id="uploadFailMessage">Failed to submit file{{files.length > 1 ? 's': ''}}</div>
    </div>
  </ng-container>

</div>