import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { VxStatusType } from 'generated/graphql'

@Component({
  selector: 'vx-document-status-badge',
  templateUrl: './vx-document-status-badge.component.html',
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class VxDocumentStatusBadgeComponent {
  @Input() status: VxStatusType
  @Input() statusReason: string
  @Input() tooltipPlacement: 'top' | 'bottom' | 'start' | 'end' = 'top'

  /**
   * Return the status with underscores replaced with spaces.
   */
  get humanReadableStatus(): string {
    return this.status.replace(/_/g, ' ')
  }

  /**
   * Return the tooltip string for the active status.
   */
  get statusTooltip(): string | null {
    if (!this.statusReason) {
      return null
    }

    return this.statusReason
  }
}
