<div>
  <app-generic-modal title="" confirmText="Save" cancelText="Cancel" (onConfirm)="confirm()" (onCancel)="cancel()">
    <div class="row">
      <div class="col text-center">
        <h3>{{ isSaving ? 'Updating Observe User' : 'Edit Observe User' }}</h3>
      </div>
    </div>
    <div>
      <div *ngIf="!isSaving">
        <form [formGroup]="observeUserForm">
          <div class="form-row p-4">
            <div class="col-3">
              <label for="windowsUserName">Windows UserName</label>
              <input formControlName="windowsUserName" class="form-control shadow-sm" id="windowsUserName" />
            </div>

            <div class="col-3">
              <label for="name">Name</label>
              <input
                formControlName="name"
                class="form-control shadow-sm"
                id="name"
                placeholder="lastName, firstName"
                [class.is-invalid]="observeUserForm.get('name').invalid"
              />
              <small *ngIf="observeUserForm.get('name').invalid" class="text-muted">
                Name needs to be in format lastName, firstName
              </small>
            </div>

            <div class="col-3">
              <label for="email">Email</label>
              <input
                formControlName="email"
                class="form-control shadow-sm"
                id="email"
                [class.is-invalid]="observeUserForm.get('email').invalid"
              />
            </div>

            <div class="form-row col-3" formArrayName="groups">
              <label for="groups">Groups</label>

              <ng-container *ngFor="let group of groupFormControls.controls; let i = index">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm m-2"
                      aria-label="Delete Group"
                      (click)="deleteGroup(i)"
                    >
                      Delete
                    </button>
                  </div>
                  <input
                    [formControlName]="i"
                    class="form-control shadow-sm"
                    id="groups"
                    [class.is-invalid]="!groupFormControls.controls[i].valid"
                  />
                </div>
                <small *ngIf="!groupFormControls.controls[i].valid" class="text-muted">
                  Groups need to contain a colon ':'
                </small>
              </ng-container>

              <div class="col">
                <button
                  *ngIf="groupFormControls.controls.length < 3"
                  type="button"
                  aria-label="Add Group"
                  (click)="addGroup()"
                  class="btn btn-sm"
                  [ngClass]="groupFormControls.controls.length ? 'btn-primary' : 'btn-secondary'"
                >
                  Add Group
                </button>
              </div>
            </div>
          </div>

          <div class="form-row p-4">
            <div class="col-3">
              <label for="team">Team</label>
              <input
                formControlName="team"
                class="form-control shadow-sm"
                id="team"
                [class.is-invalid]="observeUserForm.get('team').invalid"
              />
            </div>

            <div class="col-3">
              <label for="role">Role</label>
              <input formControlName="role" class="form-control shadow-sm" id="role" />
            </div>

            <div class="col-3">
              <label for="manager">Manager</label>
              <input
                formControlName="manager"
                class="form-control shadow-sm"
                id="manager"
                placeholder="lastName, firstName"
                [class.is-invalid]="observeUserForm.get('manager').invalid"
              />
              <small *ngIf="observeUserForm.get('manager').invalid" class="text-muted">
                Name needs to be in format lastName, firstName
              </small>
            </div>

            <div class="col-3">
              <label for="supervisor">Supervisor</label>
              <input
                formControlName="supervisor"
                class="form-control shadow-sm"
                id="supervisor"
                placeholder="lastName, firstName"
                [class.is-invalid]="observeUserForm.get('supervisor').invalid"
              />
              <small *ngIf="observeUserForm.get('supervisor').invalid" class="text-muted">
                Name needs to be in format lastName, firstName
              </small>
            </div>
          </div>

          <div class="form-row p-4">
            <div class="col-3">
              <label for="director">Director</label>
              <input
                formControlName="director"
                class="form-control shadow-sm"
                id="director"
                placeholder="lastName, firstName"
                [class.is-invalid]="observeUserForm.get('director').invalid"
              />
              <small *ngIf="observeUserForm.get('director').invalid" class="text-muted">
                Name needs to be in format lastName, firstName
              </small>
            </div>

            <div class="col-3">
              <label for="epicUserName">Epic Username</label>
              <input formControlName="epicUserName" class="form-control shadow-sm" id="epicUserName" />
            </div>

            <div class="col-3">
              <label for="resqUserName">ResQ Username</label>
              <input formControlName="resqUserName" class="form-control shadow-sm" id="resqUserName" />
            </div>

            <div class="col-3">
              <label for="enforcerUserName">Enforcer Username</label>
              <input formControlName="enforcerUserName" class="form-control shadow-sm" id="enforcerUserName" />
            </div>
          </div>

          <div class="form-row p-4">
            <div class="col-3">
              <label for="athenaUserName">AthenaIdx Username</label>
              <input formControlName="athenaUserName" class="form-control shadow-sm" id="athenaUserName" />
            </div>
            <div class="col-3">
              <label for="hourlyProductivityGoal">Hourly Productivity Goal</label>
              <input formControlName="hourlyProductivityGoal" class="form-control shadow-sm" id="hourlyProductivityGoal" />
            </div>
            <div class="col-3">
              <label for="hourlyCost">Hourly Cost</label>
              <input formControlName="hourlyCost" class="form-control shadow-sm" id="hourlyCost" />
            </div>
          </div>
        </form>
        @if (observeUser.deletedAt) {
          <div class="d-flex justify-content-start mt-2">
            <button type="button" class="btn btn-success mx-2" (click)="restoreUser(true)">Undelete</button>
          </div>
        } @else if (observeUser.deactivatedOn) {
          <div class="d-flex justify-content-start mt-2">
            <button type="button" class="btn btn-success mx-2" (click)="restoreUser()">Reactivate</button>
          </div>
        } @else {
          <div class="d-flex justify-content-start mt-2">
            <button type="button" class="btn btn-danger mx-2" (click)="removeUser()">Deactivate</button>
            <button type="button" class="btn btn-danger mx-2" (click)="removeUser(true)">Delete</button>
          </div>
        }
      </div>

      <div *ngIf="isSaving" class="col">
        <app-loading
          innerClass="fa-4x"
          outerClass="align-self-center text-brand-light-blue"
          class="d-flex flex-column flex-grow-1 justify-content-center py-4 my-4"
        ></app-loading>
      </div>
    </div>
  </app-generic-modal>
</div>
