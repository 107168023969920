import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms';
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component';
import { SortEvent } from 'app/shared/directives/sortable.directive';
import { ProviderDataService } from 'app/shared/services/provider-data.service';
import { ToastService } from 'app/shared/services/toast.service';
import { ListResponseMetaData } from 'generated/graphql';
import { debounceTime, Subject, take, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-npi-mapping',
  templateUrl: './npi-mapping.page.html',
  styleUrls: ['./npi-mapping.page.scss']
})
export class NpiMappingPage implements OnInit, OnDestroy {
  destroy$ = new Subject<void>()

  isSearchingNPIs: boolean = false
  npiSearch = new FormControl<string>('')
  npiMappingForm: FormGroup

  isAddingMapping = false
  npiToEdit: string
  username: string

  isLoading: boolean = false
  npiToTaxIdMappings: { id: string, npi: string, taxId: string, facilityName: string }[]
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 10
  sortDirection = 1
  nameLike = ''

  constructor(
    private toast: ToastService,
    private providerDataService: ProviderDataService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.npiMappingForm = this.fb.group({
      npiToAdd: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]], // 10-digit number
      taxIdToAdd: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]], // 9-digit number
    })

    this.npiSearch.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe((term) => {
        this.nameLike = term
        this.page = 1
        this.getNpiToTaxIdMappings()
      })

    this.getNpiToTaxIdMappings()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  toggleAddingMapping(): void {
    this.isAddingMapping = !this.isAddingMapping
  }

  changePage(event: PaginationUpdateEvent): void {
    this.page = event.newPageNumber
    this.getNpiToTaxIdMappings()
  }

  onSort(event: SortEvent): void {
    if (event.direction === 'asc') {
      this.sortDirection = 1
    } else if (event.direction === 'desc') {
      this.sortDirection = -1
    } else {
      this.sortDirection = 0
    }

    this.getNpiToTaxIdMappings()
  }

  createMapping(): void {
    if (this.npiMappingForm.get('npiToAdd').invalid || this.npiMappingForm.get('taxIdToAdd').invalid) {
      this.toast.error('Invalid NPI or Tax ID. Please correct the input.')
      return
    }
    this.isLoading = true
    const { npiToAdd, taxIdToAdd } = this.npiMappingForm.value
    this.providerDataService
      .createNpiTinMapping(npiToAdd, taxIdToAdd)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(
        (newMapping) => {
          this.toast.success(`Successfully added mapping: ${newMapping.npi} -> ${newMapping.tin}`)
          this.toggleAddingMapping()
          this.router.navigate(['admin/npi-mappings', newMapping.id])
        },
        (error) => {
          this.toast.error('Failed to create mapping', JSON.stringify(error))
          this.isLoading = false
        },
        () => {
          this.isLoading = false
          this.toggleAddingMapping()
          this.npiMappingForm.reset()
        }
      )
  }

  getNpiToTaxIdMappings(): void {
    this.isLoading = true
    const offset = (this.page - 1) * this.pageSize

    this.providerDataService
      .getNpiTinMappings(offset, this.pageSize, this.sortDirection === 1 ? 'asc' : 'desc', this.nameLike)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.npiToTaxIdMappings = response.entities.map((item) => ({
            id: item.id,
            npi: item.npi,
            taxId: item.tin,
            facilityName: item.facilityName,
          }))
          this.meta = response.meta
        },
        (error) => {
          this.toast.error('Failed to fetch NPI-to-TIN mappings', JSON.stringify(error));
        },
        () => {
          this.isLoading = false;
        }
      )
  }
}
