import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderDataService } from 'app/shared/services/provider-data.service';
import { ToastService } from 'app/shared/services/toast.service';
import { Subject, take, takeUntil } from 'rxjs';

interface FormInputConfig {
  formControlName: string;
  label: string;
  id: string;
  placeholder: string;
  errorMessage: string;
  type?: string
}

@Component({
  selector: 'app-npi-mapping-edit',
  templateUrl: './npi-mapping-edit.page.html',
  styleUrl: './npi-mapping-edit.page.scss'
})
export class NpiMappingEditPage {
  destroy$ = new Subject<void>()
  npiMappingForm: FormGroup
  id: string | null
  isLoading: boolean = false

  inputConfigs: FormInputConfig[] = [
    {
      formControlName: 'npi',
      label: 'NPI',
      id: 'npi',
      placeholder: 'Enter 10-digit NPI',
      errorMessage: 'Please enter a valid 10-digit NPI.',
    },
    {
      formControlName: 'tin',
      label: 'Tax ID',
      id: 'tin',
      placeholder: 'Enter 9-digit Tax ID',
      errorMessage: 'Please enter a valid 9-digit Tax ID.',
    },
    {
      formControlName: 'streetAddress',
      label: 'Street Address',
      id: 'streetAddress',
      placeholder: 'Enter street address',
      errorMessage: 'Please enter a Street address (max 255 characters).',
    },
    {
      formControlName: 'city',
      label: 'City',
      id: 'city',
      placeholder: 'Enter city',
      errorMessage: 'Please enter a valid city name (letters, spaces, hyphens; max 100 characters).',
    },
    {
      formControlName: 'state',
      label: 'State',
      id: 'state',
      placeholder: 'Enter 2-letter state code (e.g., CA)',
      errorMessage: 'Please enter a valid 2-letter state code (e.g., CA, NY).',
    },
    {
      formControlName: 'zipCode',
      label: 'ZIP Code',
      id: 'zipCode',
      placeholder: 'Enter ZIP (e.g., 12345 or 12345-6789)',
      errorMessage: 'Please enter a valid ZIP code (5 digits or 5+4 format).',
    },
    {
      formControlName: 'facilityName',
      label: 'Facility Name',
      id: 'facilityName',
      placeholder: 'Enter facility name',
      errorMessage: 'Please enter a valid facility name (max 255 characters).',
    },
    {
      formControlName: 'phoneNumber',
      label: 'Phone Number',
      id: 'phoneNumber',
      placeholder: 'Enter phone number (e.g., (123) 456-7890)',
      errorMessage: 'Please enter a valid phone number (e.g., (123) 456-7890).',
      type: 'tel'
    },
    {
      formControlName: 'faxNumber',
      label: 'Fax Number',
      id: 'faxNumber',
      placeholder: 'Enter fax number (e.g., (123) 456-7890)',
      errorMessage: 'Please enter a valid fax number (e.g., (123) 456-7890).',
      type: 'tel'
    },
    {
      formControlName: 'availityOrgName',
      label: 'Availity Org Name',
      id: 'availityOrgName',
      placeholder: 'Enter Availity organization name',
      errorMessage: 'Please enter a valid Availity org name (max 50 characters).',
    },
    {
      formControlName: 'placeOfServiceCodeInpatient',
      label: 'Inpatient Service Code',
      id: 'placeOfServiceCodeInpatient',
      placeholder: 'Enter inpatient service code (1-10 digits)',
      errorMessage: 'Please enter a valid inpatient service code (1-10 digits).',
    },
    {
      formControlName: 'placeOfServiceCodeOutpatient',
      label: 'Outpatient Service Code',
      id: 'placeOfServiceCodeOutpatient',
      placeholder: 'Enter outpatient service code (1-10 digits)',
      errorMessage: 'Please enter a valid outpatient service code (1-10 digits).',
    },
    {
      formControlName: 'uhcCareProvider',
      label: 'UHC Care Provider',
      id: 'uhcCareProvider',
      placeholder: 'Enter UHC care provider',
      errorMessage: 'Please enter a valid UHC care provider (max 100 characters).',
    },
    {
      formControlName: 'acentraContext',
      label: 'Acentra Context',
      id: 'acentraContext',
      placeholder: 'Enter Acentra context',
      errorMessage: 'Please enter a valid Acentra context (max 50 characters).',
    },
  ]

  constructor(
    private toast: ToastService,
    private router: Router,
    private providerDataService: ProviderDataService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')

    this.npiMappingForm = this.fb.group({
      npi: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]], // 10-digit number
      tin: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]], // 9-digit number
      streetAddress: ['', [Validators.maxLength(255), Validators.maxLength(255)]], // 255 characters
      city: ['', [Validators.pattern(/^[a-zA-Z\s-]{1,100}$/), Validators.maxLength(100)]], // 100 characters
      state: ['', [Validators.pattern(/^[A-Z]{2}$/)]], // 2 uppercase letters
      zipCode: ['', [Validators.pattern(/^\d{5}(-\d{4})?$/)]], // 5-digit ZIP or 5+4 ZIP+4
      facilityName: ['', [Validators.pattern(/^[\w\s&.,-]{1,255}$/), Validators.maxLength(255)]], // 255 characters
      phoneNumber: ['', [Validators.pattern(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/)]], // (123) 456-7890
      faxNumber: ['', [Validators.pattern(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/)]], // (123) 456-7890
      availityOrgName: ['', [Validators.pattern(/^[\w\s-]{1,50}$/), Validators.maxLength(50)]], // 50 characters
      placeOfServiceCodeInpatient: ['', [Validators.pattern(/^\d{1,10}$/)]], // 1 to 10 digits
      placeOfServiceCodeOutpatient: ['', [Validators.pattern(/^\d{1,10}$/)]], // 1 to 10 digits
      uhcCareProvider: ['', [Validators.pattern(/^[\w\s-]{1,100}$/), Validators.maxLength(100)]], // 100 characters
      acentraContext: ['', [Validators.pattern(/^[\w\s-]{1,50}$/), Validators.maxLength(50)]], // 50 characters
    })

    this.npiMappingForm.get('phoneNumber')?.valueChanges.subscribe(value => {
      const formatted = this.formatPhoneNumber(value)
      if (formatted !== value) {
        this.npiMappingForm.get('phoneNumber')?.setValue(formatted, { emitEvent: false })
      }
    });

    this.npiMappingForm.get('faxNumber')?.valueChanges.subscribe(value => {
      const formatted = this.formatPhoneNumber(value)
      if (formatted !== value) {
        this.npiMappingForm.get('faxNumber')?.setValue(formatted, { emitEvent: false })
      }
    });

    this.getNpiFacilityData()
  }
  
  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private stripNonNumeric(value: string): string {
    if (!value) return ''
    return value.replace(/\D/g, '')
  }

  private formatPhoneNumber(value: string): string {
    const digits = this.stripNonNumeric(value)
    if (!digits || digits.length < 10) return digits
    return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`
  }

  onSave(): void {
    this.isLoading = true

    if (this.npiMappingForm.valid) {
      const formData = { ...this.npiMappingForm.value }
      formData.id = this.id

      formData.phoneNumber = this.stripNonNumeric(formData.phoneNumber)
      formData.faxNumber = this.stripNonNumeric(formData.faxNumber)

      this.providerDataService
        .updateNpiTinMapping(formData)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(
          (updatedMapping) => {
            this.toast.success(`Successfully updated mapping ${updatedMapping.npi}`)
            this.router.navigate(['admin/npi-mappings'])
            this.isLoading = false
          },
          (error) => {
            this.toast.error('Failed to update mapping', JSON.stringify(error))
            this.isLoading = false
          },
        )
    } else {
      this.toast.error('Failed to update mapping. Form not valid')
      this.npiMappingForm.markAllAsTouched()
      this.isLoading = false
    }
  }

  onDelete(): void {
    if (!this.id) {
      this.toast.error('No ID provided for fetching facility data')
      return
    }
    this.isLoading = true
    this.providerDataService
      .deleteNpiTinMapping(this.id)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toast.success(`Successfully deleted mapping for ID: ${this.id}`)
          this.router.navigate(['admin/npi-mappings'])
          this.isLoading = false
        },
        (error) => {
          this.toast.error('Failed to delete mapping', JSON.stringify(error))
          this.isLoading = false
        },
    )
  }

  getNpiFacilityData(): void {
    if (!this.id) {
      this.toast.error('No ID provided for fetching facility data')
      return
    }
    this.isLoading = true

    this.providerDataService
      .getNpiTinMapping(this.id)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(
        (mapping) => {
          this.npiMappingForm.patchValue({
            npi: mapping.npi,
            tin: mapping.tin,
            streetAddress: mapping.streetAddress || '',
            city: mapping.city || '',
            state: mapping.state || '',
            zipCode: mapping.zipCode || '',
            facilityName: mapping.facilityName || '',
            phoneNumber: mapping.phoneNumber || '',
            faxNumber: mapping.faxNumber || '',
            availityOrgName: mapping.availityOrgName || '',
            placeOfServiceCodeInpatient: mapping.placeOfServiceCodeInpatient || '',
            placeOfServiceCodeOutpatient: mapping.placeOfServiceCodeOutpatient || '',
            uhcCareProvider: mapping.uhcCareProvider || '',
            acentraContext: mapping.acentraContext || '',
          })
        },
        (error) => {
          this.toast.error('Error fetching facility data', JSON.stringify(error))
          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
  }
}
