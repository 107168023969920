import { Component, OnInit } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { debug } from 'app/shared/utils/debug'

@Component({
  selector: 'app-org-logo',
  templateUrl: './org-logo.component.html',
  host: {
    class: 'logo-bg d-flex flex-column justify-content-center align-items-center',
  },
  standalone: true,
  imports: [NgbModule],
})
export class OrgLogoComponent implements OnInit {
  imgSrc = null

  constructor(private org: OrgService) {}

  async ngOnInit(): Promise<void> {
    let reader = new FileReader()
    reader.onload = (e) => {
      this.imgSrc = e?.target?.result
    }
    try {
      let currentLogo = await this.org.getOrgLogo()
      reader.readAsDataURL(currentLogo)
    } catch (e) {
      debug('logo', 'error loading current logo', JSON.stringify(e))
    }
  }
}
