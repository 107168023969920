import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import {
  MultiSelectTypeaheadComponent,
  MultiselectTypeaheadFilter,
  MultiselectTypeaheadSearchFunc,
} from 'app/shared/components/multiselect-typeahead/multiselect-typeahead.component'
import { VxDocumentTypeService } from 'app/vision-x/services/vx-document-type.service'
import { VxDocumentType, VxProduct } from 'generated/graphql'
import { sortBy } from 'lodash'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'

export type VxDocumentTypeFilter = MultiselectTypeaheadFilter<VxDocumentType>

export type VxDocumentTypeMap = { [documentTypeId: string]: VxDocumentType }
export const uncategorizedDocumentType = { id: 'uncategorized', name: 'Uncategorized' } as VxDocumentType

@Component({
  selector: 'app-vx-document-type-multiselect-typeahead',
  templateUrl: './vx-document-type-multiselect-typeahead.component.html',
  standalone: true,
  imports: [MultiSelectTypeaheadComponent, NgbModule],
})
export class VxDocumentTypeMultiSelectTypeaheadComponent implements OnDestroy {
  @Input() selectedVxDocumentTypeIds: string[] = []
  @Input() product: VxProduct

  /**
   * Emits changes when a `VxDocumentType` is selected or deselected as a
   * document filter.
   */
  @Output() onSelect = new EventEmitter<VxDocumentTypeFilter[]>()

  /**
   * Emitted when the component is destroyed.
   */
  destroy$ = new Subject<void>()

  /**
   * Return an observable that fetches document types for a given search string
   * and sorts them by case-insensitive name.
   *
   * Always includes the uncategorized document type at the top.
   *
   * @param search Search string
   */
  fetchOnSearch: MultiselectTypeaheadSearchFunc<VxDocumentType> = (search: string) =>
    this.vxDocumentTypeService.fetchVxDocumentTypes({ search, product: this.product }).pipe(
      map((result) => {
        const records: VxDocumentType[] = sortBy(result?.data?.vxDocumentTypes?.entities ?? [], ({ name }) =>
          name?.toLowerCase(),
        )

        return [uncategorizedDocumentType, ...records]
      }),
    )

  constructor(public vxDocumentTypeService: VxDocumentTypeService) {}

  get placeholderText(): string {
    const selectedCount = this.selectedVxDocumentTypeIds.length

    if (selectedCount === 0) {
      return 'Document Types'
    }

    return `Document Types (${selectedCount.toLocaleString()})`
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
