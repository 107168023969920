import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { FormControl, FormGroup, AbstractControl } from '@angular/forms'
import { AuthenticationService } from 'app/auth/authentication.service'
import { NgbdSortableHeader, SortEvent } from 'app/shared/directives/sortable.directive'
import { ListResponseMetaData, ObserveUserTableData } from 'generated/graphql'
import { ObserveUserService, ObserveUserTableColumns } from '../services/observe-user.service'
import { ObserveUserData, ObserveUserFilterTypes, ObserveUserFilter } from 'generated/graphql'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { first, map, startWith, takeUntil } from 'rxjs/operators'
import { Observable, Subject, combineLatest } from 'rxjs'
import { ToastService } from 'app/shared/services/toast.service'
import { saveAs } from 'file-saver'
import { format } from 'date-fns'
import { OrgService } from 'app/admin/org/org.service'
import { GenericModalComponent } from 'app/shared/components/generic-modal/generic-modal.component'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
  MultiSelectTypeaheadComponent,
  MultiselectTypeaheadSearchFunc,
} from 'app/shared/components/multiselect-typeahead/multiselect-typeahead.component'
import { ApolloQueryResult } from '@apollo/client'
import { QueryRef } from 'apollo-angular'

@Component({
  selector: 'app-observe-users-list',
  templateUrl: './observe-users-list.component.html',
  providers: [NgbActiveModal],
  styleUrls: ['./observe-users-list.component.scss'],
})
export class ObserveUsersListComponent implements OnInit, OnDestroy {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>
  @ViewChild('fileInput') fileInput: ElementRef
  @ViewChild('uploadErrorModal') uploadErrorModal: TemplateRef<GenericModalComponent>
  @ViewChildren(MultiSelectTypeaheadComponent) multiSelectFilters: QueryList<
    MultiSelectTypeaheadComponent<{ id: string }>
  >
  @ViewChild('editUserModal') editUserModal: TemplateRef<GenericModalComponent>

  COLUMN_NAMES = ObserveUserTableColumns
  ObserveUserFilterTypes = ObserveUserFilterTypes
  userlistMetaData: ListResponseMetaData = {
    offset: 0,
    limit: 50,
    total: 0,
  }
  observeUsersList: ObserveUserData[] = []
  displayRows: ObserveUserData[] = []
  filteredUsers: ObserveUserData[] = []

  observeUsers$: Observable<ApolloQueryResult<{ observeUsersByOrgId: ObserveUserTableData }>>

  fetchUsersQuery: QueryRef<{ observeUsersByOrgId: ObserveUserTableData }>

  userSearchText = new FormControl('')
  includeDeactivatedUsers = new FormControl(false)
  includeDeletedUsers = new FormControl(false)
  documentTypeSelection = new FormControl(null)
  currentPage = 1
  isLoading = false
  fileProcessing = false
  destroy$ = new Subject<void>()
  orgId: string
  errorMessages: string[] = []
  filtersLoaded = false

  filterFormControls: FormGroup = new FormGroup({
    selectedTeamIds: new FormControl([]),
    selectedRoleIds: new FormControl([]),
    selectedSupervisorIds: new FormControl([]),
    selectedManagerIds: new FormControl([]),
    selectedFunctionalAreaIds: new FormControl([]),
  })
  filterMap = new Map<ObserveUserFilterTypes, AbstractControl<string[], string[]>>()
  selectedUser: ObserveUserData = null

  // Done this way to be able to overwrite the 'saveAs' function within the unit test,
  // to avoid files being created during test runs
  saveAs = saveAs
  updatedById = ''

  constructor(
    private observeUserService: ObserveUserService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private orgService: OrgService,
    private modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.orgId = this.authenticationService.getUser().orgId

    this.fetchUsersQuery = this.observeUserService.getObserveUsers(
      this.orgId,
      this.includeDeactivatedUsers.value,
      this.includeDeletedUsers.value,
    )

    this.isLoading = true

    this.setFilterMap()

    this.documentTypeSelection.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.onDocumentSelection(val)
    })
    this.userSearchText.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val || this.isFilterPresent()) {
        this.filterUsers()
      } else {
        this.setDisplayRows()
      }
    })

    this.filterFormControls.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.filterUsers()
    })

    combineLatest([
      this.includeDeactivatedUsers.valueChanges.pipe(startWith(false)),
      this.includeDeletedUsers.valueChanges.pipe(startWith(false)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([includeDeactivated, includeDeleted]) => {
        this.fetchUsersQuery.refetch({
          orgId: this.orgId,
          includeDeactivated,
          includeDeleted,
        })
      })

    this.observeUsers$ = this.fetchUsersQuery.valueChanges.pipe(takeUntil(this.destroy$))

    this.observeUsers$.subscribe({
      next: (result) => {
        this.observeUsersList = [...result.data.observeUsersByOrgId.users]
        this.userlistMetaData = {
          ...this.userlistMetaData,
          total: this.observeUsersList.length,
        }
        this.onSort({ direction: 'asc', column: this.COLUMN_NAMES.fullName })
        this.filterUsers()
        this.isLoading = false
      },
      error: (err) => {
        this.toastService.error(`A error occurred fetching observe users ${err}`)
        this.observeUsersList = []
        this.displayRows = []
        this.isLoading = false
      },
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  setDisplayRows(): void {
    const filterSet = this.isFilterPresent() || this.userSearchText.value
    const start = this.currentPage == 1 ? 0 : this.userlistMetaData.limit * (this.currentPage - 1)
    const end = start + this.userlistMetaData.limit
    const dataSource = filterSet ? this.filteredUsers : this.observeUsersList
    this.displayRows = dataSource.slice(start, end) ?? []

    this.userlistMetaData = {
      ...this.userlistMetaData,
      offset: start,
      total: filterSet ? this.filteredUsers.length : this.observeUsersList.length,
    }
  }

  fetchPage($event: PaginationUpdateEvent): void {
    this.currentPage = $event.newPageNumber
    this.setDisplayRows()
  }

  onSort($event: SortEvent): void {
    this.headers?.forEach((header) => {
      if (header.sortable === $event.column) {
        header.direction = $event.direction
      } else {
        header.direction = ''
      }
    })
    this.observeUsersList = this.observeUserService.sortObserveUsers(
      this.observeUsersList,
      $event.column,
      $event.direction,
    )
    // reset to page 1 onSort
    this.fetchPage({ newPageNumber: 1 })
    this.setDisplayRows()
  }

  onDocumentSelection(type: string): void {
    if (type === 'template') {
      this.createCsvFile(false)
    } else if (type === 'export') {
      this.createCsvFile(true)
    } else if (type === 'import') {
      this.fileInput?.nativeElement.click()
    }
  }

  async uploadUsersEvent($event): Promise<void> {
    this.fileProcessing = true
    const file = $event.target.files[0]

    if (file) {
      this.observeUserService
        .uploadObserveUsersFile(this.orgId, file)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            if (!res.success || res?.errors.length) {
              this.errorMessages = [...res.errors]
              this.modal.open(this.uploadErrorModal)
            } else {
              this.toastService.success('successfully uploaded')
              this.errorMessages = []
            }
            this.documentTypeSelection.patchValue(null)
            this.fileProcessing = false
            this.observeUserService.getObserveUsers(this.orgId).refetch()
          },
          error: (err) => {
            this.fileProcessing = false
            this.errorMessages.push('Error occured while uploading file')
            this.modal.open(this.uploadErrorModal)
          },
        })
    }
    this.fileInput.nativeElement.value = ''
  }

  resetFilters(): void {
    this.filterFormControls.patchValue({
      selectedTeamIds: [],
      selectedRoleIds: [],
      selectedSupervisorIds: [],
      selectedManagerIds: [],
      selectedFunctionalAreaIds: [],
    })
    this.userSearchText.patchValue(null)
    this.multiSelectFilters.forEach((f) => {
      f.resetSearch()
    })
  }

  getCleanOrgName(name: string): string {
    const lowerString = name.toLowerCase()
    return lowerString.replace(/ /g, '-').replace(/'/g, '')
  }

  async createCsvFile(includeUsers: boolean): Promise<void> {
    this.fileProcessing = true
    try {
      const filters = this.isFilterPresent() ? this.filterFormControls.value : {}
      const response = await this.observeUserService
        .createCsvFileRequest(this.orgId, includeUsers, filters)
        .pipe(first())
        .toPromise()
      this.fileProcessing = false
      const blob = new Blob([response], { type: 'text/csv' })
      const date = format(new Date(), 'yyyy-MM-dd')
      const orgName = (await this.orgService.getOrg(this.orgId).pipe(first()).toPromise()).data.organization.name
      const fileName = `${this.getCleanOrgName(orgName)}-observe-users-${date}${
        this.isFilterPresent() ? '-Filtered' : ''
      }.csv`
      this.saveAs(blob, fileName)
    } catch (err) {
      this.toastService.error('Error occured generating file', err)
    } finally {
      this.documentTypeSelection.patchValue(null)
      this.fileProcessing = false
    }
  }

  closeModal() {
    this.errorMessages = []
    this.modal.dismissAll()
  }

  getUserStatus(data: ObserveUserData): string {
    if (data.deactivatedOn) {
      return 'Deactivated'
    } else if (data.deletedAt) {
      return 'Deleted'
    } else {
      return 'Active'
    }
  }
  getSearchTypeFunction(type: ObserveUserFilterTypes): MultiselectTypeaheadSearchFunc<ObserveUserFilter> {
    return (searchText: string) => {
      return this.observeUserService.fetchObserveUserFilters(this.orgId, type).pipe(
        map((result) => {
          const dataList = result.data.observeUserFilters.filter((filter) => {
            return filter.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
          })
          return dataList.sort((a, b) => a.name.localeCompare(b.name))
        }),
      )
    }
  }

  getFilterTypeControl(type: ObserveUserFilterTypes): AbstractControl<string[], string[]> {
    return this.filterMap.get(type)
  }

  isFilterPresent(): boolean {
    const values = Object.values(this.filterFormControls.value) as string[][]
    return values.some((filter) => filter && filter.length)
  }

  filterSelection($event: { control: FormControl; data: ObserveUserFilter }[], type: ObserveUserFilterTypes): void {
    const filterToUpdate = this.getFilterTypeControl(type)
    const currentFilterValue = filterToUpdate.value

    $event.forEach((filterOption) => {
      const id = filterOption.data.id

      // value selected event
      if (filterOption.control.value && !currentFilterValue.includes(id)) {
        currentFilterValue.push(id)
        // value unselected event
      } else if (!filterOption.control.value && currentFilterValue.includes(id)) {
        const idx = currentFilterValue.findIndex((id2) => id2 === id)
        currentFilterValue.splice(idx, 1)
      }
    })

    filterToUpdate.patchValue(currentFilterValue)
  }

  filterUsers(): void {
    const { selectedTeamIds, selectedRoleIds, selectedManagerIds, selectedSupervisorIds, selectedFunctionalAreaIds } =
      this.filterFormControls.value

    const searchTerm = this.userSearchText.value

    this.filteredUsers = this.observeUsersList.filter((user) => {
      if (selectedTeamIds.length && !selectedTeamIds.includes(user.team.id)) {
        return false
      }
      if (selectedRoleIds.length && !selectedRoleIds.includes(user.role.id)) {
        return false
      }
      if (selectedSupervisorIds.length && !selectedSupervisorIds.includes(user.supervisor.id)) {
        return false
      }
      if (selectedManagerIds.length && !selectedManagerIds.includes(user.manager.id)) {
        return false
      }
      if (selectedFunctionalAreaIds.length && !selectedFunctionalAreaIds.includes(user.team.functionalArea.id)) {
        return false
      }

      if (searchTerm) {
        if (
          !user.userName.toLocaleLowerCase().includes(searchTerm.trim().toLocaleLowerCase()) &&
          !user.name.toLocaleLowerCase().includes(searchTerm.trim().toLocaleLowerCase())
        ) {
          return false
        }
      }
      return true
    })
    this.setDisplayRows()
  }

  setFilterMap(): void {
    this.filterMap.set(ObserveUserFilterTypes.Teams, this.filterFormControls.controls.selectedTeamIds)
    this.filterMap.set(ObserveUserFilterTypes.Roles, this.filterFormControls.controls.selectedRoleIds)
    this.filterMap.set(
      ObserveUserFilterTypes.FunctionalAreas,
      this.filterFormControls.controls.selectedFunctionalAreaIds,
    )
    this.filterMap.set(ObserveUserFilterTypes.Supervisor, this.filterFormControls.controls.selectedSupervisorIds)
    this.filterMap.set(ObserveUserFilterTypes.Manager, this.filterFormControls.controls.selectedManagerIds)
  }

  onClickEvent(data: ObserveUserData): void {
    this.selectedUser = data
    this.updatedById = this.authenticationService.getUser().id
    this.modal.open(this.editUserModal, {
      animation: true,
      centered: true,
      size: 'xl',
    })
  }

  async saveEvent(userSaved: boolean): Promise<void> {
    this.modal.dismissAll()
    if (userSaved) {
      this.isLoading = true
      this.fetchUsersQuery.refetch()
      this.isLoading = false
    }
  }
}
