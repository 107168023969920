import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Subject } from 'rxjs'
import { Organization } from 'generated/graphql'
import { OrgService } from 'app/admin/org/org.service'
import { takeUntil, take } from 'rxjs/operators'
import { Router, RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

/* Re-usable component to display a list of organizations
 *
 * @export
 * @class OrgListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, CommonModule, FormsModule, NgbModule],
})
export class OrganizationListComponent implements OnInit {
  orgSearchText = new FormControl('')
  displayedOrgs: Organization[] = []
  organizations: Organization[] = []
  destroy$ = new Subject<void>()

  /*
   * Binding to set a string URL,
   * If `routeTo` is `myroute/route`
   * When an org is clicked on in the table where the id is `123` it will route to `myroute/route/123`
   */
  @Input()
  set routeTo(route: string) {
    this.formatRouteUrl(route)
  }

  public route = ''

  constructor(private orgService: OrgService, private router: Router) {}

  ngOnInit(): void {
    this.getOrgs()
    this.orgSearchText.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filterOrgs()
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  async getOrgs(): Promise<void> {
    let result = await this.orgService.getOrgs().pipe(take(1)).toPromise()
    this.organizations = [...result.data.organizations.entities]
    this.displayedOrgs = this.organizations
  }

  filterOrgs(): void {
    const searchTerm = this.orgSearchText.value
    this.displayedOrgs = this.organizations?.filter((row) => {
      return row.name.toLowerCase().includes(searchTerm?.toLowerCase())
    })
  }

  formatRouteUrl(val: string): void {
    const firstChar = val.charAt(0)

    if (firstChar != '/') {
      val = '/' + val
    }

    const lastChar = val.charAt(val.length - 1)
    if (lastChar != '/') {
      val = val + '/'
    }
    this.route = val
  }
}
