<img
  class="border-1 shadow w-100"
  [ngClass]="{
    'border-primary': isActive,
    'border-grey-500 opacity-45': !isActive
  }"
  [src]="'/api/vx-file/' + vxFileId"
/>
<div *ngIf="caption" class="bg-alt-light-gray text-left small-page-preview-label py-1 px-2">
  {{ caption }}
</div>
