<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-grow-1 pt-2 scrolling-sidebar">
    <div class="card my-1 p-0 border-0 bg-transparent">
      <div class="card-header border-0 bg-primary text-white">
        Edit NPI Facility Details
      </div>
      <div class="card-body mt-2 px-5 bg-white shadow">
        <form [formGroup]="npiMappingForm" (ngSubmit)="onSave()" class="d-flex flex-column px-4">
          <div *ngFor="let input of inputConfigs" class="mb-3">
            <label [for]="input.id" class="form-label">{{ input.label }}</label>
            <input
              [type]="input.type || 'text'"
              [id]="input.id"
              [formControlName]="input.formControlName"
              class="form-control"
              [class.is-invalid]="npiMappingForm.get(input.formControlName)?.touched && npiMappingForm.get(input.formControlName)?.invalid"
              [placeholder]="input.placeholder"
            />
            <div *ngIf="npiMappingForm.get(input.formControlName)?.touched && npiMappingForm.get(input.formControlName)?.invalid"
              class="invalid-feedback">
              {{ input.errorMessage }}
            </div>
          </div>
          <!-- Submit Button -->
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-cancel" routerLink="/admin/npi-mappings">
              Cancel
            </button>
            <button class="btn btn-danger" type='button' (click)="onDelete()">
              Delete
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="npiMappingForm.invalid || isLoading">
              {{ isLoading ? 'Saving...' : 'Save' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
