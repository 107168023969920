import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateVxPageTypeFormField,
  DeleteVxPageTypeFormField,
  UpdateVxPageTypeFormField,
  VxPageTypeFormFieldsQuery,
  FindMatchingOcrTextRelationshipsQuery,
} from 'app/vision-x/vision-x.gql'
import {
  MutationCreateVxPageTypeFormFieldArgs,
  MutationDeleteVxPageTypeFormFieldArgs,
  MutationUpdateVxPageTypeFormFieldArgs,
  OcrTextRelationshipFromFormRulesResponse,
  QueryFindMatchingOcrTextRelationshipsArgs,
  QueryVxPageTypeFormFieldsArgs,
  VxPageTypeFormField,
  VxPageTypeFormFieldCreate,
  VxPageTypeFormFieldList,
  VxPageTypeFormFieldUpdate,
} from 'generated/graphql'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class VxFormFieldService {
  constructor(private apollo: Apollo) {}

  fetchVxFormFields(
    vxPageTypeId: string,
  ): Observable<ApolloQueryResult<{ vxPageTypeFormFields: VxPageTypeFormFieldList }>> {
    return this.apollo.watchQuery<{ vxPageTypeFormFields: VxPageTypeFormFieldList }, QueryVxPageTypeFormFieldsArgs>({
      query: VxPageTypeFormFieldsQuery,
      variables: {
        vxPageTypeId,
      },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  /**
   *
   * @param {string} fileId
   * @param {VxPageTypeFormFieldCreate[]} vxPageTypeFormFields
   * @returns {Promse<OcrTextRelationshipResponseScalar>}
   */
  fetchMatchingOcrTextRelationships(
    fileId: string,
    vxPageTypeFormFields: VxPageTypeFormFieldCreate[],
  ): Observable<
    ApolloQueryResult<{ findMatchingOcrTextRelationships: { entities: OcrTextRelationshipFromFormRulesResponse } }>
  > {
    return this.apollo.watchQuery<
      { findMatchingOcrTextRelationships: { entities: OcrTextRelationshipFromFormRulesResponse } },
      QueryFindMatchingOcrTextRelationshipsArgs
    >({
      query: FindMatchingOcrTextRelationshipsQuery,
      variables: {
        fileId,
        vxPageTypeFormFields,
      },
    }).valueChanges
  }

  async createVxPageTypeFormField(request: VxPageTypeFormFieldCreate): Promise<VxPageTypeFormField> {
    const result = await this.apollo
      .mutate<{ createVxPageTypeFormField: VxPageTypeFormField }, MutationCreateVxPageTypeFormFieldArgs>({
        mutation: CreateVxPageTypeFormField,
        variables: {
          data: request,
        },
      })
      .toPromise()
    return result.data?.createVxPageTypeFormField
  }

  async updateVxPageTypeFormField(id: string, request: VxPageTypeFormFieldUpdate): Promise<VxPageTypeFormField> {
    const result = await this.apollo
      .mutate<{ updateVxPageTypeFormField: VxPageTypeFormField }, MutationUpdateVxPageTypeFormFieldArgs>({
        mutation: UpdateVxPageTypeFormField,
        variables: {
          id,
          data: request,
        },
      })
      .toPromise()
    return result.data?.updateVxPageTypeFormField
  }

  async deleteVxPageTypeFormField(formFieldId: string): Promise<boolean> {
    const result = await this.apollo
      .mutate<{ deleteResult: boolean }, MutationDeleteVxPageTypeFormFieldArgs>({
        mutation: DeleteVxPageTypeFormField,
        variables: {
          id: formFieldId,
        },
        refetchQueries: [{ query: VxPageTypeFormFieldsQuery }],
      })
      .toPromise()
    return result.data?.deleteResult
  }
}
