import { RepeatRange } from 'app/vision-x/services/vx-extraction.service'
import { ExtractionRule } from 'generated/graphql'
import md5 from 'md5'
import { BehaviorSubject, Observable, of } from 'rxjs'

export class ExtractionRuleNode {
  value: ExtractionRule
  hash: string
  parentNode?: ExtractionRuleNode
  childNodes: Record<string, ExtractionRuleNode> = {}
  repeatRanges$: Observable<RepeatRange[]>
  fieldGroupId: string
  conditionChanges$ = new BehaviorSubject<void>(null)

  // need ref to fieldGroupId so a different hash is created for identical extraction rules in different field groups, which happens when one copies extraction rules
  constructor(extractionRule: ExtractionRule, fieldGroupId: string) {
    this.value = extractionRule
    this.fieldGroupId = fieldGroupId
    this.hash = this.generateHash()
  }

  private generateHash(): string {
    return md5(JSON.stringify(this.value) + this.fieldGroupId)
  }

  isEqualTo(node: ExtractionRuleNode): boolean {
    return this.hash === node.hash
  }

  get hasChildren(): boolean {
    return Object.keys(this.childNodes)?.length > 0
  }

  get hasParent(): boolean {
    return !!this.parentNode
  }

  get parentRepeatRanges$(): Observable<RepeatRange[]> {
    return this.hasParent ? this.parentNode.repeatRanges$ : of(null)
  }

  get childLength(): number {
    return Object.keys(this.childNodes)?.length ?? 0
  }

  addChildNode(childNode: ExtractionRuleNode): ExtractionRuleNode {
    childNode.parentNode = this
    childNode.fieldGroupId = this.fieldGroupId

    this.childNodes[childNode.hash] = childNode
    return this
  }

  findChildNode(lookupHash: string): ExtractionRuleNode | undefined {
    let possibleNode = this.childNodes[lookupHash]

    if (possibleNode) {
      return possibleNode
    }

    for (const node of Object.values(this.childNodes)) {
      possibleNode = node.findChildNode(lookupHash)

      if (possibleNode) {
        break
      }
    }

    return possibleNode
  }

  getAncestors(): ExtractionRuleNode[] {
    const nodes: ExtractionRuleNode[] = []

    if (!this.hasParent) {
      return []
    }

    let cursorNode: ExtractionRuleNode = this as ExtractionRuleNode
    while (cursorNode.hasParent) {
      nodes.unshift(cursorNode)
      cursorNode = cursorNode?.parentNode
    }

    return nodes
  }

  /**
   * Apply a predicate on a node and any of the child nodes.
   *
   * @param predicate
   */
  forEachNode(predicate: (node: ExtractionRuleNode) => void): void {
    predicate(this)
    for (const node of Object.values(this.childNodes)) {
      node.forEachNode(predicate)
    }
  }

  findNodeWithField(key: string): ExtractionRuleNode | undefined {
    const predicate = (node: ExtractionRuleNode) => {
      return node.value.fields.find((field) => field.dataKeys.includes(key))
    }
    let exists = predicate(this)
    if (exists) {
      return this
    }

    for (const node of Object.values(this.childNodes)) {
      exists = predicate(node)
      if (exists) {
        return node
      }
    }

    return undefined
  }
}
