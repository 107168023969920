import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'

/**
 * Modal to get user confirmation
 *
 * @export
 * @class ConfirmModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  host: {
    'data-t': 'confirm-modal',
  },
  standalone: true,
  imports: [NgbModule],
})
export class ConfirmModalComponent implements OnInit {
  @Input() title: string
  @Input() body: string
  @Input() yes: string
  @Input() yesClass: string

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
