import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { GetApiWorkerTask } from 'app/revbot/bot-detail/bot-run-overview/api-worker-task.gql'
import { GetBotRun, GetBotRunArtifacts, GetBotRuns } from 'app/revbot/bot-runs.gql'
import { debug } from 'app/shared/utils/debug'
import {
  ApiWorkerTask,
  BotRun,
  BotRunArtifactList,
  BotRunList,
  BotRunListResponseMetaData,
  ListResponseMetaData,
  QueryApiWorkerTaskArgs,
  QueryBotRunArgs,
  QueryBotRunArtifactsArgs,
  QueryBotRunsArgs,
} from 'generated/graphql'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

/**
 * Handle retrieving a bot run
 *
 * @export
 * @class BotRunsService
 */
@Injectable({
  providedIn: 'root',
})
export class BotRunsService {
  statuses: string[] = [
    'SUCCEEDED',
    'FAILED',
    'UNSTABLE',
    'QUEUED',
    'RUNNING',
    'SCATTERED',
    'ABORTED',
    'CLAIM_NOT_FOUND',
    'MISSING_DATA',
    'DEFERRED',
    'EXPIRED',
  ]
  startDate: string
  endDate: string
  startTime: string
  endTime: string

  private meta: ListResponseMetaData | BotRunListResponseMetaData

  constructor(private apollo: Apollo) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*}  {(ListResponseMetaData | BotRunListResponseMetaData)}
   * @memberof BotRunsService
   */
  getMeta(): ListResponseMetaData | BotRunListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve a single bot run based on ID
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ botRun: BotRun }>>}
   * @memberof BotRunsService
   */
  getBotRun(id: string): Observable<ApolloQueryResult<{ botRun: BotRun }>> {
    debug('bot-runs-service', 'get botRun', id)
    return this.apollo.watchQuery<{ botRun: BotRun }, QueryBotRunArgs>({
      query: GetBotRun,
      variables: {
        id: id,
      },
    }).valueChanges
  }

  /**
   * Retrieve a list of bot runs based on variables
   *
   * @param {QueryBotRunsArgs} obj
   * @return {*}  {Observable<ApolloQueryResult<{ botRuns: BotRunList }>>}
   * @memberof BotRunsService
   */
  getBotRuns(obj: QueryBotRunsArgs): Observable<ApolloQueryResult<{ botRuns: BotRunList }>> {
    debug('bot-runs-service', 'get bot runs', obj)
    let { statuses, payerName, dateEnd, dateStart, offset, limit, botId, botJobId } = obj
    return this.apollo
      .watchQuery<{ botRuns: BotRunList }, QueryBotRunsArgs>({
        query: GetBotRuns,
        variables: {
          statuses: statuses,
          payerName: payerName,
          dateEnd: dateEnd,
          dateStart: dateStart,
          offset: offset,
          limit: limit,
          botId: botId,
          botJobId: botJobId,
        },
      })
      .valueChanges.pipe(
        tap((result) => {
          this.meta = result?.data?.botRuns?.meta
        }),
      )
  }

  /**
   * Retrieve a single bot run's artifacts
   *
   * @param {string} botId
   * @return {*}  {Observable<ApolloQueryResult<{ botRunArtifacts: BotRunArtifactList; }>>}
   * @memberof BotRunsService
   */
  getBotRunArtifacts(botId: string): Observable<ApolloQueryResult<{ botRunArtifacts: BotRunArtifactList }>> {
    debug('bot-runs-service', 'get bot run artifacts', botId)
    return this.apollo.watchQuery<{ botRunArtifacts: BotRunArtifactList }, QueryBotRunArtifactsArgs>({
      query: GetBotRunArtifacts,
      variables: {
        botId: botId,
      },
    }).valueChanges
  }

  /**
   * Retrieve bot runs based on bot ID and variables
   *
   * @param {string} botJobId
   * @param {number} [offset]
   * @return {*}  {Observable<
   *     ApolloQueryResult<{
   *       botRuns: BotRunList
   *     }>
   *   >}
   * @memberof BotRunsService
   */
  filterBotRuns(
    botJobId: string,
    offset?: number,
  ): Observable<
    ApolloQueryResult<{
      botRuns: BotRunList
    }>
  > {
    let dateStart
    let dateEnd
    if (this.startDate) {
      this.startTime = this.startTime || '00:00'
      this.endDate = this.endDate || this.startDate
      this.endTime = this.endTime || '23:59'
      dateStart = moment(this.startDate + ' ' + this.startTime, 'MM/DD/YYYY HH:mm')
        .utc()
        .toISOString()
      dateEnd = moment(this.endDate + ' ' + this.endTime, 'MM/DD/YYYY HH:mm')
        .utc()
        .toISOString()
    }
    return this.getBotRuns({
      botJobId,
      limit: 1000,
      statuses: this.statuses,
      dateStart: dateStart,
      dateEnd: dateEnd,
      offset: offset,
    })
  }

  /**
   * Helper to show number of applied filters. Filters appear if there are any selected start
   * or end date/times and also when statuses other than the originally selected are selected
   *
   * @return {*}  {string}
   * @memberof BotRunsService
   */
  filterBadge(): string {
    let originalFilter = [
      'SUCCEEDED',
      'FAILED',
      'UNSTABLE',
      'RUNNING',
      'SCATTERED',
      'ABORTED',
      'CLAIM_NOT_FOUND',
      'MISSING_DATA',
    ]
    if (
      this.statuses?.every((status) => {
        originalFilter.includes(status)
      }) ||
      this.startDate ||
      this.startTime ||
      this.endDate ||
      this.endTime
    ) {
      return this.statuses?.every((status) => {
        originalFilter.includes(status)
      }) &&
        (this.startDate || this.startTime || this.endDate || this.endTime)
        ? '2'
        : '1'
    }
  }

  /**
   * Get the success rate of an array of give bot runs
   *
   * @param {BotRun[]} ranBots
   * @return {*}  {number}
   * @memberof BotRunsService
   */
  getAverageSuccessRate(ranBots: BotRun[]): number {
    return (
      ranBots.filter((botRun) => botRun.status === 'SUCCEEDED' || botRun.status === 'CLAIM_NOT_FOUND').length /
      ranBots.length
    )
  }

  /**
   * Retrieve an API Worker Task by its ID
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ apiWorkerTask: ApiWorkerTask; }>>}
   * @memberof BotRunsService
   */
  getApiWorkerTask(id: string): Observable<ApolloQueryResult<{ apiWorkerTask: ApiWorkerTask }>> {
    debug('bot-runs-service', 'get api worker task', id)
    return this.apollo.watchQuery<{ apiWorkerTask: ApiWorkerTask }, QueryApiWorkerTaskArgs>({
      query: GetApiWorkerTask,
      variables: {
        id,
      },
    }).valueChanges
  }
}
