import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { AuthorizationService } from 'app/auth/authorization.service'
import { ToastService } from 'app/shared/services/toast.service'
import { Organization, Role, User } from 'generated/graphql'
import { Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'
import { AssignOrgModalComponent } from '../assign-org-modal/assign-org-modal.component'
import { OrgRolesComponent } from '../org-roles/org-roles.component'
import { UnassignOrgModalComponent } from '../unassign-org-modal/unassign-org-modal.component'

@Component({
  selector: 'app-user-org-assignments',
  templateUrl: './user-org-assignments.component.html',
  styleUrls: ['./user-org-assignments.component.scss'],
  standalone: true,
  imports: [CommonModule, OrgRolesComponent, NgbModule],
})
export class UserOrgAssignmentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() primaryOrganization: Organization
  @Input() user?: User
  @Output() allowedOrganizationsChange = new EventEmitter<Organization[]>()
  @Output() userRolesChange = new EventEmitter<Role[]>()

  allowedOrganizations: Organization[] = []
  userRoles: { [orgId: string]: Role[] } = {}
  allRoles: { [orgId: string]: Role[] } = {}
  organizations: Organization[] = []
  loading: boolean = true

  private destroy$ = new Subject<void>()

  constructor(
    private modalService: NgbModal,
    private orgService: OrgService,
    private toastService: ToastService,
    private authorizationService: AuthorizationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true
    await this.fetchOrganizations()
    await this.fetchOrganizationRoles()
    await this.fetchUserRoles()
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.user) {
      await this.fetchOrganizations()
      await this.fetchOrganizationRoles()
      await this.fetchUserRoles()
      this.loading = false
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  async fetchOrganizations(): Promise<void> {
    if (this.user) {
      const orgResults = await Promise.all(
        this.user.allowedOrgIds.map((allowedOrgId) => this.orgService.getOrg(allowedOrgId).pipe(first()).toPromise()),
      )

      this.allowedOrganizations = orgResults
        .map((result) => result.data.organization)
        // Ensure the user's main org isn't included.
        .filter((result) => result.id !== this.primaryOrganization.id)
    }
  }

  async fetchOrganizationRoles(): Promise<void> {
    if (this.primaryOrganization) {
      const allOrgs = [this.primaryOrganization, ...this.allowedOrganizations]

      this.allRoles = {}

      const rolesResults = await Promise.all(
        allOrgs.map((org) => this.authorizationService.getOrgRoles(org.id).then((roles) => ({ orgId: org.id, roles }))),
      )

      rolesResults.forEach(({ orgId, roles }) => {
        this.allRoles[orgId] = roles
      })
    }
  }

  async fetchUserRoles(): Promise<void> {
    if (this.user && this.primaryOrganization) {
      const allOrgs = [this.primaryOrganization, ...this.allowedOrganizations]

      this.userRoles = {}

      const results = await Promise.all(
        allOrgs.map((org) =>
          this.authorizationService.getUserRoles(this.user.id, org.id).then((roles) => ({ orgId: org.id, roles })),
        ),
      )

      results.forEach(({ orgId, roles }) => {
        this.handleRolesChange(
          roles,
          allOrgs.find((org) => org.id === orgId),
        )
      })
    }
  }

  handleRolesChange(selectedRoles: Role[], org: Organization): void {
    this.userRoles[org.id] = selectedRoles
    this.userRolesChange.emit(
      Object.keys(this.userRoles)
        .map((orgId) => this.userRoles[orgId])
        .flat(),
    )
  }

  removeOrg(org: Organization): void {
    this.handleRolesChange([], org)
    this.allowedOrganizations = this.allowedOrganizations.filter((o) => o.id !== org.id)
    this.allowedOrganizationsChange.emit(this.allowedOrganizations)
  }

  async assignOrg(org: Organization): Promise<void> {
    this.allowedOrganizations.push(org)

    const orgRoles = await this.authorizationService.getOrgRoles(org.id)
    this.allRoles[org.id] = orgRoles
    if (this.user) {
      const userRoles = await this.authorizationService.getUserRoles(this.user.id, org.id)
      this.userRoles[org.id] = userRoles
    }

    this.allowedOrganizationsChange.emit(this.allowedOrganizations)
  }

  openOrgAssignModal(): void {
    const modalRef = this.modalService.open(AssignOrgModalComponent)
    const orgAssignedSub = modalRef.componentInstance.organizationAssigned.subscribe((assignedOrg: Organization) => {
      this.assignOrg(assignedOrg)
      orgAssignedSub.unsubscribe()
    })
    this.orgService
      .getOrgs()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          const assignedOrgIds = [this.primaryOrganization, ...this.allowedOrganizations].map((o) => o.id)
          this.organizations = result.data.organizations.entities.filter((o) => !assignedOrgIds.includes(o.id))
          modalRef.componentInstance.organizations = this.organizations
        },
        error: () => {
          this.toastService.error('Error fetching organizations')
        },
      })
  }

  openRemovalModal(org: Organization): void {
    const modalRef = this.modalService.open(UnassignOrgModalComponent)
    modalRef.componentInstance.organization = org

    const confirmationSub = modalRef.componentInstance.confirmRemoval.subscribe((removedOrg: Organization) => {
      this.removeOrg(removedOrg)
      confirmationSub.unsubscribe()
    })
  }
}
