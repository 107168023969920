import { CommonModule } from '@angular/common'
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

/**
 * Component to layout a collapsing card
 *
 * @export
 * @class CollapsingCardComponent
 */
@Component({
  selector: 'app-collapsing-card',
  templateUrl: './collapsing-card.component.html',
  styleUrls: ['./collapsing-card.component.scss'],
  host: {
    class: 'd-block rounded-lgr',
  },
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class CollapsingCardComponent {
  @Input() @HostBinding('class.is-collapsed') isCollapsed = false
  @Input() allowCollapse: boolean = true
  @Output() onToggled = new EventEmitter<boolean>()

  constructor(public elementRef: ElementRef) {}
}
