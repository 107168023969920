<div
  *ngIf="!isOpen; else shopping_cart_sidebar"
  class="justify-content-between text-center flex-grow-1 w-6rem mx-auto align-items-center pt-3"
>
  <button
    type="button"
    class="btn btn-md btn-light rounded border-1 border-alt-light-gray text-alt-medium-gray"
    id="page-types-sidebar-toggle-closed"
    (click)="isOpen = !isOpen"
  >
    <i class="fa fa-expand"></i>
  </button>
</div>
<ng-template #shopping_cart_sidebar>
  <div class="flex-grow-1 position-relative z-index-zero">
    <div class="position-absolute top-0 right-0 bottom-0 left-0 d-flex flex-column pr-4 overflow-auto">
      <div class="d-flex flex-column sticky-top bg-light pb-2">
        <span class="fs-18 font-weight-light mb-4 mt-3 d-flex">
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-md btn-light rounded border-1 border-alt-light-gray text-alt-medium-gray mr-2"
              id="page-types-sidebar-toggle-closed"
              (click)="isOpen = !isOpen"
            >
              <i class="fa fa-compress"></i>
            </button>
          </div>
        </span>
        <vx-document-type-typeahead
          (onVxDocumentTypeSelected)="onSelectVxDocumentType($event)"
          [selectedVxDocumentType]="selectedVxDocumentType$ | async"
        />
      </div>
      <ng-container *ngFor="let potentialFormField of visibleFormFields$ | async">
        <app-form-extraction-field-card
          [fieldName]="potentialFormField.name"
          [fieldKey]="potentialFormField.key"
          [fieldLabel]="potentialFormField.label"
          [isFieldIncludedInVxPageTypeRules]="potentialFormField.checked"
          [isHighlighted]="potentialFormField.highlighted"
          [checkboxDisabled]="potentialFormField.checkboxDisabled"
          (onSelect)="onPotentialFormFieldChange.next({ potentialFormFieldId: potentialFormField.id, checked: $event })"
          (onFieldLabelChange)="
            onPotentialFormFieldChange.next({ potentialFormFieldId: potentialFormField.id, label: $event })
          "
        ></app-form-extraction-field-card>
      </ng-container>
    </div>
  </div>
  <div class="row no-gutters justify-content-between border-0 pt-4">
    <div class="btn-group border-0 justify-content-start w-100">
      <button
        type="button"
        class="btn btn-outline-primary btn-lg w-50 font-weight-bold"
        id="vx-form-rule-template-pane-btn-show-all"
        name="vx-form-rule-template-pane-btn-show-all"
        style="font-size: 12px"
        (click)="toggleListView('all')"
        data-bs-toggle="button"
        [ngClass]="{
          'bg-alt-light-blue text-alt-white': (isShowAll$ | async),
          'bg-alt-light-grey': !(isShowAll$ | async)
        }"
      >
        Show all
      </button>
      <button
        type="button"
        class="btn btn-outline-primary btn-lg w-50 font-weight-bold"
        id="vx-form-rule-template-pane-btn-show-selected"
        name="vx-form-rule-template-pane-btn-show-selected"
        style="font-size: 12px"
        (click)="toggleListView('selected')"
        data-bs-toggle="button"
        [ngClass]="{
          'bg-alt-light-blue text-alt-white': !(isShowAll$ | async),
          'bg-alt-light-grey': (isShowAll$ | async)
        }"
      >
        Show selected
      </button>
    </div>
  </div>
</ng-template>
