<app-loading
  *ngIf="isLoading"
  innerClass="fa-4x"
  outerClass="align-self-center text-brand-light-blue"
  class="d-flex flex-column flex-grow-1 justify-content-center"
></app-loading>
<div *ngIf="!isLoading" class="card detail-card-new my-4 bg-light d-flex">
  <div class="card-header d-flex justify-content-between">
    <h3 class="text-dark">Run ID: {{ botRun?.id }}</h3>
    <a
      routerLink="/claims/{{ botRun?.providerClaimId }}"
      [class.disabled]="!botRun?.providerClaimId"
      class="btn btn-alt-steel-blue text-light mx-1"
      data-t="bot-run-view-claim-button"
    >
      View Claim
    </a>
  </div>
  <div class="card-body d-flex flex-column h-100">
    <div class="row no-gutters flex-grow-1">
      <div class="col-md-6 d-flex flex-column flex-grow-1 mt-2">
        <h5 class="text-dark ml-3 mb-4">Bot Run Details</h5>
        <app-bot-run-key-details [botRun]="botRun"></app-bot-run-key-details>
      </div>
      <div class="col-md-6 d-flex flex-column flex-grow-1 mt-2">
        <h5 class="text-dark ml-3 mb-4">Outcome Graph</h5>
        <app-bot-runs-graph [botRuns]="botRuns" [selectedBotRunId]="botRun?.id" [botId]="botId"></app-bot-runs-graph>
      </div>
    </div>
    <div class="w-100 mt-4 px-3" *ngIf="apiWorkerTask">
      <app-collapsing-card class="card bg-light border-grey border-1">
        <div slot="title">
          <h5 class="pb-2">Command</h5>
        </div>
        <div slot="actions">
          <button
            class="btn copy-btn btn-sm mr-2 text-alt-dark-gray font-weight-bold"
            ngbTooltip="Copy command to clipboard"
            container="body"
            [attr.data-clipboard-text]="apiWorkerTask?.command + ' ' + apiWorkerTask?.arguments?.join(' ')"
          >
            <i class="fa fa-fw fa-clone"></i>
            <span class="sr-only">Copy command to clipboard</span>
          </button>
        </div>
        <div slot="content">
          <div class="p-3">
            <pre
              [innerHTML]="apiWorkerTask?.command ? (apiWorkerTask?.command + '\n' + apiWorkerTask?.arguments?.join('\n')) : 'No command to display'"
              data-t="bot-run-command"
            ></pre>
          </div>
        </div>
      </app-collapsing-card>
    </div>
    <div class="mt-4">
      <nav ngbNav #nav="ngbNav" class="nav-pills muted">
        <!-- <ng-container ngbNavItem>
          <a ngbNavLink class="m-3" data-t="raw-json_button"><strong> Raw JSON </strong></a>
          <ng-template ngbNavContent>
            <div class="card mx-3 px-5 py-3 border-grey bg-light">
              <div>
                <button
                  *ngIf="botRun?.rawData"
                  type="button"
                  class="btn btn-link copy-btn text-dark float-right"
                  [attr.data-clipboard-text]="botRun.rawData"
                  data-t="bot-run-raw-json-button"
                >
                  <i class="fa fa-fw fa-clone"></i>
                </button>
                <pre
                  [innerHTML]="botRun?.rawData ? (rawData | prettyprint) : 'No Raw JSON to display'"
                  data-t="bot-run-json-data"
                ></pre>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink class="m-3" data-t="bot-run-screenshot-button"> <strong> Screenshots </strong></a>
          <ng-template ngbNavContent>
            <div class="card mx-3 border-grey bg-light">
              <ngb-carousel *ngIf="images.length" [interval]="null">
                <ng-template ngbSlide *ngFor="let image of images; let i = index">
                  <div class="d-flex picsum-img-wrapper justify-content-center">
                    <img [src]="image" width="500px" (click)="openImage(i)" data-t="bot-run-screenshot" />
                  </div>
                </ng-template>
              </ngb-carousel>
              <pre *ngIf="!images.length"> No Screenshots to display </pre>
            </div>
          </ng-template>
        </ng-container> -->
        <ng-container ngbNavItem>
          <a ngbNavLink class="m-3" data-t="bot-run-screenshot-button"> <strong> Logs </strong></a>
          <ng-template ngbNavContent>
            <div class="card mx-3 border-grey bg-light">
              <pre data-t="bot-run-logs">No logs to display</pre>
            </div>
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<ng-template #content let-modal class="d-flex justify-content-center">
  <img [src]="images[openImageIndex]" class="w-100" />
</ng-template>
