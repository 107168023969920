import { Injectable } from '@angular/core';
import {
  QueryNpiTinMappingsArgs,
  MutationCreateNpiTinMappingArgs,
  MutationUpdateNpiTinMappingArgs,
  MutationDeleteNpiTinMappingArgs,
  NpiTinMapList,
  NpiTinMap,
  DeleteMappingDocument,
  UpdateMappingDocument,
  CreateMappingDocument,
  GetMappingDocument,
  GetAllMappingsDocument,
  ListResponseMetaData,
  NpiTinMapping,
} from 'generated/graphql';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProviderDataService {
  private meta: ListResponseMetaData;

  constructor(private apollo: Apollo) { }

  /**
   * Fetches all NPI mappings.
   */
  getNpiTinMappings(
    offset: number,
    limit: number,
    sort: string,
    nameLike: string = '',
  ): Observable<NpiTinMapList> {
    return this.apollo
      .query<{ npiTinMappings: NpiTinMapList }, QueryNpiTinMappingsArgs>({
        query: GetAllMappingsDocument,
        variables: { offset, limit, sort, nameLike },
        fetchPolicy: 'network-only',
      })
      .pipe(
        tap((result) => {
          this.meta = result.data?.npiTinMappings?.meta;
        }),
        map((result) => result.data?.npiTinMappings)
      );
  }

  /**
   * Fetches a single NPI mapping by the Id.
   */
  getNpiTinMapping(id: string): Observable<NpiTinMap> {
    return this.apollo
      .query<{ npiTinMapping: NpiTinMap }, { id: string }>({
        query: GetMappingDocument,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data?.npiTinMapping));
  }

  /**
   * Creates a new NPI-to-TIN mapping.
   */
  createNpiTinMapping(npi: string, tin: string): Observable<NpiTinMap> {
    return this.apollo
      .mutate<{ createNpiTinMapping: NpiTinMap }, MutationCreateNpiTinMappingArgs>({
        mutation: CreateMappingDocument,
        variables: {
          mapping: {
            npi, tin,
          }
        },
        refetchQueries: [
          {
            query: GetAllMappingsDocument,
            variables: { offset: 0, limit: 10, sort: 1, nameLike: '' },
          },
        ],
      })
      .pipe(map((result) => result.data?.createNpiTinMapping));
  }

  /**
   * Updates an existing NPI-to-TIN mapping.
   */
  updateNpiTinMapping(data: NpiTinMapping): Observable<NpiTinMap> {
    return this.apollo
      .mutate<{ updateNpiTinMapping: NpiTinMap }, MutationUpdateNpiTinMappingArgs>({
        mutation: UpdateMappingDocument,
        variables: { mapping: data },
        refetchQueries: [
          {
            query: GetAllMappingsDocument,
            variables: { offset: 0, limit: 10, sort: 1, nameLike: '' },
          },
        ],
      })
      .pipe(map((result) => result.data?.updateNpiTinMapping));
  }

  /**
   * Deletes an NPI mapping.
   */
  deleteNpiTinMapping(id: string): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteNpiTinMapping: boolean }, MutationDeleteNpiTinMappingArgs>({
        mutation: DeleteMappingDocument,
        variables: { id },
        refetchQueries: [
          {
            query: GetAllMappingsDocument,
            variables: { offset: 0, limit: 10, sort: 1, nameLike: '' },
          },
        ],
      })
      .pipe(map((result) => result.data?.deleteNpiTinMapping));
  }

  getMeta(): ListResponseMetaData {
    return this.meta;
  }
}
