import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { NgbModule, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { debug } from 'app/shared/utils/debug'
import { merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators'
import { defer } from 'lodash'
import { VxDocument } from 'generated/graphql'
import { escapeElasticQuery } from 'app/shared/utils/escapeElasticQuery'
import { VxDocumentService } from 'app/vision-x/services/vx-document.service'
import { LoadingComponent } from '../loading/loading.component'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-vx-document-typeahead',
  templateUrl: './vx-document-typeahead.component.html',
  standalone: true,
  imports: [LoadingComponent, ReactiveFormsModule, NgbModule, CommonModule],
})
export class VxDocumentTypeaheadComponent implements OnChanges {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Output() onSelect = new EventEmitter<VxDocument>()
  @Output() onSearch = new EventEmitter<string>()
  @Input() selectedVxDocument: string = ''
  @Input() clearInputOnSelect: boolean = true

  searching: boolean = false

  constructor(private vxDocumentService: VxDocumentService) {}

  ngOnChanges(): void {
    if (this.selectedVxDocument?.length) {
      setTimeout(() => {
        this.element.nativeElement.value = this.selectedVxDocument
      })
    }
  }

  onFocus(event: string): void {
    this.focus$.next(event)
  }

  onClick(event: string): void {
    this.click$.next(event)
  }

  /**
   * Get vxDocuments based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @memberof VxDocumentTypeaheadComponent
   */
  search = (text$: Observable<string>): Observable<VxDocument[]> => {
    const debouncedText$ = text$.pipe(
      debounceTime(250),
      filter((documentNameToFind) => documentNameToFind.length > 2),
      distinctUntilChanged(),
    )
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((search) => {
        this.onSearch.emit(search)
        return this.vxDocumentService.fetchVxDocumentsByName(escapeElasticQuery(search)).pipe(
          catchError(() => {
            return of({ data: { vxDocumentsByName: [] } })
          }),
          first(),
        )
      }),
      map((result) => result?.data?.vxDocumentsByName),
      tap(() => (this.searching = false)),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @param {{ name: string }} x
   * @memberof VxDocumentTypeaheadComponent
   */
  vxDocumentFormatter = (x: VxDocument): string => x.name

  /**
   * Notify consumers that a vxDocument has been selected
   *
   * @param {*} event
   * @memberof VxDocumentTypeaheadComponent
   */
  onSelectVxDocument(event: { item: VxDocument }): void {
    let vxDocument = event.item
    debug('vxDocument', 'selected a vxDocument', vxDocument)
    this.onSelect.emit(vxDocument)
    if (this.clearInputOnSelect) {
      defer(() => (this.element.nativeElement.value = ''))
    } else {
      this.element.nativeElement.value = vxDocument?.name
    }
  }

  /**
   * Clear typeahead input
   *
   * @param {*} event
   * @memberof VxDocumentTypeaheadComponent
   */
  clearVxDocument(event: Event): void {
    event.preventDefault()
    this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
