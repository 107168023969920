<div class="d-flex flex-row flex-grow-1 px-3 pt-2">
  <div
    *ngIf="(activeExtractionRuleNode$ | async)?.hasParent"
    class="border-right-1 border-alt-light-gray p-2 fs-12 clickable d-flex flex-column align-items-center justify-content-center"
    (click)="showExtractionRuleParent()"
  >
    <div><i class="fa fa-level-up"></i></div>
    <div>Back to</div>
    <div>Parent</div>
  </div>
  <div class="flex-grow-1 d-flex flex-column">
    <div class="d-flex flex-row align-items-center">
      <button
        class="add-field-group-custom-btn btn btn-sm btn-light border-1 fs-12 px-2 m-2 hover"
        (click)="addGroup()"
      >
        <i class="fa fa-plus text-alt-light-gray"></i>
      </button>
      <app-extraction-rule-tab
        *ngFor="let extractionRule of tabExtractionRulesList"
        [extractionRuleNode]="extractionRule"
        (onTabClick)="handleRuleSelection($event)"
        [isActive]="extractionRule.hash === (activeExtractionRuleNode$ | async)?.hash"
      ></app-extraction-rule-tab>
    </div>
    <app-extraction-rule-details
      *ngIf="activeExtractionRuleNode$ | async; else noGroups"
      (onExtractionRuleSelection)="handleRuleSelection($event)"
    ></app-extraction-rule-details>
    <ng-template #noGroups>
      <div class="d-flex align-items-center justify-content-center p-4">Add an extraction group to get started.</div>
    </ng-template>
  </div>
</div>
