<div class="modal-header p-4pl-5">
  <h5 class="modal-title">Change Organization</h5>
  <button type="button" class="close font-weight-bold" aria-label="Close" (click)="handleModalClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form novalidate>
  <div class="modal-body p-5">
    <div class="form-group" *ngIf="currentOrg">
      <small class="form-text text-muted">
        Currently in <strong class="text-brand-dark-blue">{{ currentOrg.name }}</strong>
      </small>
      <label for="org" class="sr-only">Select Organization</label>
      <app-organization-typeahead
        (onSelect)="select($event)"
        [clearInput]="false"
        [placeholder]="currentOrg ? currentOrg.name : 'Loading'"
        [autoFocus]="false"
        id="org"
      ></app-organization-typeahead>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel mr-3" (click)="handleModalClose()" data-t="change-org-cancel-button">
      Cancel
    </button>
    <button
      (click)="changeOrg()"
      type="button"
      class="px-5"
      class="btn btn-brand-green px-5"
      data-t="change-org-button"
    >
      Confirm
    </button>
  </div>
</form>
