<div class="container-fluid py-4">
  <h2 class="mb-4 text-center">EDI Dashboard</h2>

  <section class="search-section card p-4 mb-4">
    <h4>Search</h4>
    <form class="form" (ngSubmit)="onSearch()">

      <div class="row mb-4">
        <label for="orgIdInput" class="col-sm-3 col-form-label">Organization ID:</label>
        <div class="col-sm-9">
          <select class="form-control" id="orgIdInput" [(ngModel)]="searchData.orgId" name="orgIdInput">
            <option value="" disabled selected>Select Organization</option>
            <option *ngFor="let org of orgs" [value]="org.id">{{ org.name }}</option>
          </select>
        </div>
      </div>

      <div class="row mb-4">
        <label for="epicIdInput" class="col-sm-3 col-form-label">Epic Referral ID:</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="epicIdInput" [(ngModel)]="searchData.epicReferralId" name="epicIdInput" placeholder="Epic Referral ID">
        </div>
      </div>

      <div class="row mb-4">
        <label for="procIdInput" class="col-sm-3 col-form-label">Procedure Set ID:</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="procIdInput" [(ngModel)]="searchData.procId" name="procIdInput" placeholder="Procedure Set ID">
        </div>
      </div>

      <div class="row mb-4">
        <label for="startDateInput" class="col-sm-3 col-form-label">Created At Start Date:</label>
        <div class="col-sm-9">
          <input type="date" class="form-control" id="startDateInput" [(ngModel)]="searchData.startDate" name="startDateInput">
        </div>
      </div>

      <div class="row mb-4">
        <label for="endDateInput" class="col-sm-3 col-form-label">Created At End Date:</label>
        <div class="col-sm-9">
          <input type="date" class="form-control" id="endDateInput" [(ngModel)]="searchData.endDate" name="endDateInput">
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-sm-9 offset-sm-3">
          <button type="submit" class="btn btn-primary mr-3">Search</button>
          <button type="button" class="btn btn-secondary" (click)="clearSearch()">Clear</button>
          <button type="button" class="btn btn-secondary ml-3" (click)="onSendSelectedTransactions()">Send EDIs</button>
        </div>
      </div>
    </form>
  </section>

  <section *ngIf="showTable" class="transaction-table card p-4 w-100">
    <h4>Transaction Details</h4>
    <table class="result-table table-striped table-bordered w-100">
      <thead>
        <tr>
          <th>Selected</th>
          <th>Transaction ID</th>
          <th>Transaction Type</th>
          <th>Transaction Direction</th>
          <th>Raw Text</th>
          <th>Created At</th>
          <th>Procedure Set ID</th>
          <th>Epic Referral ID</th>
          <th>Procedure Set Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of (transactions$ | async)">
          <td>
            <input 
            type="checkbox" 
            [(ngModel)]="transaction.selected"
            (change)="toggleTransactionSelection(transaction)" 
          />
          </td>
          <td>{{ transaction.id }}</td>
          <td>{{ transaction.type }}</td>
          <td>{{ transaction.isOutgoing ? 'Outgoing' : 'Incoming' }}</td>
          <td 
            class="expandable-text" 
            [attr.title]="transaction.rawText"
            [class.expanded]="expandedTransactions.has(transaction.id)" 
            (click)="toggleExpand(transaction.id)"
            [attr.title]="transaction.rawText">
            {{ transaction.raw }}
          </td>
          <td>{{ transaction.createdAt | date }}</td>
          <td>{{ transaction.relatedEntityId }}</td>
          <td>{{ transaction.epicReferralId }}</td>
          <td>{{ transaction.status }}</td>
        </tr>
      </tbody>
    </table>
  </section>

</div>