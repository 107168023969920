import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-form-extraction-field-card',
  templateUrl: './form-extraction-field-card.component.html',
})
export class FormExtractionFieldCardComponent implements OnInit, OnChanges {
  @Input() fieldName: string
  @Input() fieldKey: string
  @Input() fieldLabel?: string
  @Input() isFieldIncludedInVxPageTypeRules?: boolean
  @Input() isHighlighted?: boolean
  @Input() checkboxDisabled?: boolean

  @Output() onSelect: EventEmitter<boolean> = new EventEmitter()
  @Output() onFieldLabelChange: EventEmitter<string> = new EventEmitter()

  isFieldIncludedInVxPageTypeRulesForm: FormControl<boolean> = new FormControl(false)
  fieldLabelFormControl: FormControl<string> = new FormControl('')
  isFocused: boolean = false

  destroy$ = new Subject<void>()

  get borderColor(): 'light-gray' | 'orange' | 'light-blue' {
    if (this.isFocused) {
      return 'light-blue'
    } else if (this.isHighlighted && !this.fieldLabelFormControl.value) {
      return 'orange'
    }
    return 'light-gray'
  }

  ngOnInit(): void {
    if (this.isFieldIncludedInVxPageTypeRules) {
      this.isFieldIncludedInVxPageTypeRulesForm.setValue(true)
    }

    if (this.fieldLabel) {
      this.fieldLabelFormControl.setValue(this.fieldLabel)
    }

    this.fieldLabelFormControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((fieldLabelText) => this.onFieldLabelChange.emit(fieldLabelText))
  }

  ngOnChanges(): void {
    if (this.checkboxDisabled) {
      this.isFieldIncludedInVxPageTypeRulesForm.disable()
    } else {
      this.isFieldIncludedInVxPageTypeRulesForm.enable()
    }
  }

  handleCheckboxClick(): void {
    const newCheckboxValue: boolean = !this.isFieldIncludedInVxPageTypeRulesForm.value
    this.isFieldIncludedInVxPageTypeRulesForm.setValue(newCheckboxValue)
    this.onSelect.emit(newCheckboxValue)
    // need to re-emit value since if it was unchecked, the value will have been wiped from state
    if (newCheckboxValue) {
      this.onFieldLabelChange.emit(this.fieldLabelFormControl.value)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
