<!-- toolbar left -->
<div class="justify-content-start">
  <app-canvas-controls
    (zoomOut)="zoomOut()"
    (zoomIn)="zoomIn()"
    (resetZoom)="resetZoom()"
    (togglePanning)="togglePanning()"
    (setZoom)="setZoom($event)"
    [zoomLevel]="state.zoomLevel$ | async"
    [isPanning]="state.isPanningEnabled$ | async"
  ></app-canvas-controls>
</div>

<!-- toolbar right -->
<div class="d-flex flex-row flex-grow-1 justify-content-end align-items-center">
  <!-- <div class="custom-control custom-switch mr-2">
    <input type="checkbox" class="custom-control-input" id="pageVisible" [formControl]="isPageVisibleCheckbox" />
    <label class="custom-control-label" for="pageVisible">Page Visible</label>
  </div> -->
  <div class="badge badge-warning text-uppercase mr-2" *ngIf="state.hasUnsavedChanges$ | async">You have unsaved changes</div>
  <button class="btn btn-sm btn-light border-1 border-alt-light-gray px-4" (click)="saveChanges()">
    <i class="fa fa-floppy-o"></i>
  </button>
</div>
