import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { OrgService } from 'app/admin/org/org.service'
import { DEFAULT_HOME_PATH } from 'app/app-routing.routes'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Organization } from 'generated/graphql'
import { first } from 'rxjs/operators'
import { OrganizationTypeaheadComponent } from '../organization-typeahead/organization-typeahead.component'
import { CommonModule } from '@angular/common'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-org-switcher',
  templateUrl: './org-switcher.component.html',
  standalone: true,
  imports: [OrganizationTypeaheadComponent, CommonModule, NgbModule],
})
export class OrgSwitcherComponent implements OnInit {
  currentOrg: Organization = null
  selected = new UntypedFormControl(null) // Object of type Organization
  isLoading: boolean = false

  constructor(
    private toast: ToastService,
    private orgService: OrgService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const orgResult = await this.orgService
        .getOrg(this.authenticationService.getUser()?.orgId)
        .pipe(first())
        .toPromise()
      this.currentOrg = orgResult?.data?.organization
    } catch (e) {
      debug('org-switcher', 'error loading orgs', JSON.stringify(e))
    }
  }

  select($event: Organization): void {
    this.selected.setValue($event)
  }

  /**
   * Change the current user's organization
   *
   * @return {*}  {Promise<void>}
   * @memberof OrgSwitcherComponent
   */
  async changeOrg(): Promise<void> {
    let selectedOrg: Organization = this.selected?.value || this.currentOrg // in case user hasn't explicitly selected an organization and wants to log into their current organization
    if (selectedOrg) {
      this.isLoading = true
      try {
        this.currentOrg = selectedOrg
        await this.orgService.setOrg(selectedOrg?.id)
        await this.authenticationService.setUser()
        const relayState = this.route.snapshot.queryParamMap.get('relayState') || DEFAULT_HOME_PATH
        this.authenticationService.redirectAfterLogin(relayState)
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not switch org'), JSON.stringify(e))
      }
      this.isLoading = false
    }
  }
}
