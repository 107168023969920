import { Component, Input, SimpleChange } from '@angular/core'
import { ReactiveFormsModule, FormGroup, FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { TableService } from '../table-service'
import { OrgService } from 'app/admin/org/org.service'
import { IntegrationBillingProviderService } from '../services/integrationBillingProvider/ibp.service'
import { PaginationComponent, PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { ListResponseMetaData } from 'generated/graphql'

@Component({
  selector: 'viewer-component',
  templateUrl: './viewer.page.html',
  styleUrls: ['./viewer.page.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, PaginationComponent],
})
export class ViewerComponent {
  constructor(private orgService: OrgService, private ibpService: IntegrationBillingProviderService) {}
  @Input() selectedEndpoint: string
  selectedOrg: string = 'f3bb0966-b617-4733-aecf-0804bb4978fa'
  form: FormGroup = new FormGroup({})
  formFields: string[] = []
  entries: any[] = []
  data: any[] = []
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 10
  totalEntries: number = 0
  lastpage: boolean = false
  orgs = []
  dbService = new TableService(this.orgService, this.ibpService)
  serviceMap = {
    integrationBillingprovider: 'getIntegrationBillingProviders',
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes['selectedEndpoint']) {
      this.loadEntries()
    }
  }

  async loadEntries() {
    if (!this.selectedEndpoint) return
    const table = this.dbService?.get(this.selectedEndpoint, this.selectedOrg)
    if (!table) return
    await table.subscribe((tableData) => {
      let service = this.serviceMap[this.selectedEndpoint]
      if (!service) return
      this.data = tableData.data[service].entities
      this.meta = tableData.data[service].meta
      this.pagenateEntries()
    })

    return
  }
  async pagenateEntries() {
    if (this.data.length < 1) return
    const startIndex = (this.page - 1) * this.pageSize
    let endIndex = startIndex + this.pageSize
    if (endIndex >= this.data.length) {
      endIndex = this.data.length - 1
      this.lastpage = true
    }
    this.entries = this.data?.slice(startIndex, endIndex) ?? this.entries
    this.entries = this.entries.map(({ id, __typename, ...all }) => all)
    if (this.entries.length > 0) {
      this.formFields = Object.keys(this.entries[0])
    }
    return
  }
  changePage(event: PaginationUpdateEvent): void {
    this.page = event.newPageNumber
    this.pagenateEntries()
  }

  editEntry(entry: string) {
    return
  }

  camelToNormalCase(text: string): string {
    const result = text.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
  }
  nextPage() {
    if (this.lastpage) return
    this.page++
    this.pagenateEntries()
  }

  prevPage() {
    if (this.page > 1) {
      this.page--
      this.lastpage = false
      this.pagenateEntries()
    }
  }

  updateEntry(entry: string, field: string) {
    try {
      if (field === 'orgId') return this.getOrgname(entry)
      return entry
    } catch (err) {
      return entry
    }
  }
  getOrgname(id: string) {
    const orgList = {
      BAYLOR_SCOTT_AND_WHITE_HEALTH: 'bea32763-1538-4891-9f3c-45aa81d56bfc',
      CARLE: 'f50c2fb6-923b-447d-b371-3f383cf46f0c',
      MEMORIAL_HEALTHCARE_SYSTEM: '4ed51a1c-6dba-430b-8f62-a677926f64b7',
      UCHICAGO_MEDICINE: '3cd46f18-2ae5-482f-9167-ccad07d5d5a1',
      UC_HEALTH: '38e73e3e-a8ba-4f20-8e4b-e7dd095c6c5b',
      UNITY_POINT_HEALTH: 'f3bb0966-b617-4733-aecf-0804bb4978fa',
    }
    return Object.keys(orgList).find((key) => orgList[key] === id)
  }
}
