import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'ordinal', pure: true, standalone: true })
export class OrdinalPipe implements PipeTransform {
  transform(value: number): string {
    if (value % 10 === 1 && value % 100 !== 11) {
      return `${value}st`
    } else if (value % 10 === 2 && value % 100 !== 12) {
      return `${value}nd`
    } else if (value % 10 === 3 && value % 100 !== 13) {
      return `${value}rd`
    } else {
      return `${value}th`
    }
  }
}
