import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from 'app/shared/services/toast.service'
import { ObserveUserData } from 'generated/graphql'
import { firstValueFrom } from 'rxjs'
import { ObserveUserService } from '../services/observe-user.service'

@Component({
  selector: 'app-observe-user-edit-modal',
  templateUrl: './observe-user-edit-modal.component.html',
  providers: [NgbActiveModal],
})
export class ObserveUserEditModalComponent implements OnInit {
  isSaving = false

  @Input()
  observeUser: ObserveUserData

  @Input()
  updatedById: string

  @Input()
  orgId: string

  @Output()
  observeUserSave: EventEmitter<boolean> = new EventEmitter<boolean>()
  observeUserForm: FormGroup = new FormGroup({
    windowsUserName: new FormControl(''),
    name: new FormControl('', [Validators.required, this.nameValidator()]),
    email: new FormControl('', [Validators.email]),
    groups: new FormArray([]),
    team: new FormControl('', [Validators.required]),
    role: new FormControl(''),
    manager: new FormControl('', [this.nameValidator()]),
    supervisor: new FormControl('', [this.nameValidator()]),
    director: new FormControl('', [this.nameValidator()]),
    epicUserName: new FormControl(''),
    enforcerUserName: new FormControl(''),
    resqUserName: new FormControl(''),
    athenaUserName: new FormControl(''),
    hourlyProductivityGoal: new FormControl(),
    hourlyCost: new FormControl(),
  })
  groupFormControls = this.observeUserForm.get('groups') as FormArray

  constructor(private toastService: ToastService, private observeUserService: ObserveUserService) {}

  ngOnInit(): void {
    this.observeUserForm.patchValue({
      windowsUserName: this.observeUser.userName,
      name: this.observeUser.name,
      email: this.observeUser.email,
      team: this.observeUser.team.name,
      role: this.observeUser.role.name,
      manager: this.observeUser.manager.name,
      supervisor: this.observeUser.supervisor.name,
      director: this.observeUser.director.name,
      epicUserName: this.observeUser.epicUserName,
      enforcerUserName: this.observeUser.enforcerUserName,
      resqUserName: this.observeUser.resqUserName,
      athenaUserName: this.observeUser.athenaIdxUsername,
      hourlyProductivityGoal: this.observeUser.hourlyProductivityGoal,
      hourlyCost: this.observeUser.hourlyCost,
    })

    for (let group of this.observeUser.groupNames) {
      this.groupFormControls.push(new FormControl(group, [this.groupsValidator()]))
    }
  }

  async confirm(): Promise<void> {
    if (this.observeUserForm.valid) {
      this.isSaving = true
      const updatedUserData = this.formatFormChanges()
      const result = await this.observeUserService.updateObserveUser(this.orgId, updatedUserData, this.updatedById)
      if (result?.data) {
        this.observeUserSave.emit(true)
      } else {
        this.toastService.error('A error occured while updating the observeUser')
      }
      this.isSaving = false
    } else {
      this.toastService.error('There are errors within the form')
    }
  }

  cancel(): void {
    this.observeUserSave.emit(false)
  }

  formatFormChanges(): ObserveUserData {
    return {
      id: this.observeUser.id,
      userName: this.observeUserForm.get('windowsUserName').value || this.observeUser.userName,
      name: this.observeUserForm.get('name').value || this.observeUser.name,
      email: this.observeUserForm.get('email').value || this.observeUser.email,
      groupNames: this.groupFormControls.value?.length ? this.groupFormControls.value : [],

      team: this.observeUserForm.get('team').value
        ? {
            name: this.observeUserForm.get('team').value,
            id: 'newTeamId',
            functionalArea: {
              id: '',
              name: '',
            },
          }
        : this.observeUser.team,

      role: this.observeUserForm.get('role').value
        ? {
            name: this.observeUserForm.get('role').value,
            id: 'newRoleId',
          }
        : this.observeUser.role,

      manager: this.observeUserForm.get('manager').value
        ? {
            name: this.observeUserForm.get('manager').value,
            id: 'newManagerId',
          }
        : this.observeUser.manager,

      supervisor: this.observeUserForm.get('supervisor').value
        ? {
            name: this.observeUserForm.get('supervisor').value,
            id: 'newSupervisorId',
          }
        : this.observeUser.supervisor,

      director: this.observeUserForm.get('director').value
        ? {
            name: this.observeUserForm.get('director').value,
            id: 'newDirectorId',
          }
        : this.observeUser.director,

      epicUserName: this.observeUserForm.get('epicUserName').value || this.observeUser.epicUserName,
      enforcerUserName: this.observeUserForm.get('enforcerUserName').value || this.observeUser.enforcerUserName,
      resqUserName: this.observeUserForm.get('resqUserName').value || this.observeUser.resqUserName,
      athenaIdxUsername: this.observeUserForm.get('athenaUserName').value || this.observeUser.athenaIdxUsername,
      hourlyProductivityGoal: parseFloat(this.observeUserForm.get('hourlyProductivityGoal').value) || this.observeUser.hourlyProductivityGoal,
      hourlyCost: parseFloat(this.observeUserForm.get('hourlyCost').value) || this.observeUser.hourlyCost,
    }
  }

  deleteGroup(index: number): void {
    this.groupFormControls.removeAt(index)
  }

  addGroup(): void {
    if (this.groupFormControls.length < 3) {
      this.groupFormControls.push(new FormControl('', [this.groupsValidator()]))
    }
  }

  groupsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pattern = new RegExp('\\w*:\\w+|:\\w+')
      return pattern.test(control.value) ? null : { error: control.value }
    }
  }

  nameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pattern = new RegExp('\\w+, ?\\w+')
      if (!control.value) {
        return null
      }
      return pattern.test(control.value) ? null : { error: control.value }
    }
  }

  async removeUser(deleteUser = false): Promise<void> {
    this.isSaving = true
    const result = await firstValueFrom(
      this.observeUserService.removeUser(deleteUser, this.orgId, this.observeUser.id, this.updatedById),
    )
    if (result.data.removeObserveUser) {
      this.observeUserSave.emit(true)
    } else {
      this.toastService.error('A error occured while updating the observeUser')
    }
    this.isSaving = false
  }

  async restoreUser(undeleteUser = false): Promise<void> {
    this.isSaving = true
    const statusChangeDate = undeleteUser ? this.observeUser.deletedAt.toString() : this.observeUser.deactivatedOn.toString()
    const result = await firstValueFrom(
      this.observeUserService.restoreUser(undeleteUser, this.orgId, this.observeUser.id, this.updatedById, statusChangeDate),
    )
    if (result.data.restoreObserveUser) {
      this.observeUserSave.emit(true)
    } else {
      this.toastService.error('A error occured while updating the observeUser')
    }
    this.isSaving = false
  }
}
