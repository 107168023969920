import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { DEFAULT_HOME_PATH } from 'app/app-routing.routes'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Organization } from 'generated/graphql'
import { first } from 'rxjs/operators'
import { OrganizationTypeaheadComponent } from '../organization-typeahead/organization-typeahead.component'
import { CommonModule } from '@angular/common'

/**
 * Modal for switching the current user's assigned organization
 * Only available for super users
 *
 * @export
 * @class OrgSwitcherComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-org-switcher-modal',
  templateUrl: './org-switcher-modal.component.html',
  standalone: true,
  imports: [OrganizationTypeaheadComponent, CommonModule, NgbModule],
})
export class OrgSwitcherModalComponent implements OnInit {
  currentOrg: Organization = null
  selected = new UntypedFormControl(null) // Object of type Organization
  redirect = true

  constructor(
    private orgService: OrgService,
    private authenticationService: AuthenticationService,
    private toast: ToastService,
    public modal: NgbActiveModal,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const orgResult = await this.orgService
        .getOrg(this.authenticationService.getUser()?.orgId)
        .pipe(first())
        .toPromise()
      this.currentOrg = orgResult?.data?.organization
    } catch (e) {
      debug('org-switcher', 'error loading orgs', JSON.stringify(e))
    }
  }

  handleModalClose(): void {
    this.modal.dismiss()
  }

  select($event: Organization): void {
    this.selected.setValue($event)
  }

  /**
   * Change the current user's organization
   *
   * @return {*}  {Promise<void>}
   * @memberof OrgSwitcherComponent
   */
  async changeOrg(): Promise<void> {
    let selectedOrg: Organization = this.selected?.value
    if (selectedOrg) {
      try {
        this.modal.close()
        this.toast.success(`Switching to ${selectedOrg?.name} ...`)
        this.currentOrg = selectedOrg

        await this.orgService.setOrg(selectedOrg?.id)

        if (!this.redirect) {
          window.location.reload()
          return
        }

        await this.authenticationService.setUser()
        this.authenticationService.redirectAfterLogin(DEFAULT_HOME_PATH)
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not switch org'), JSON.stringify(e))
      }
    }
  }
}
