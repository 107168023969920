import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  UntypedFormControl,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { ToastService } from 'app/shared/services/toast.service'
import { ObserveAccessControl, ObserveUsageSummary, UpdateObserveAccessControlInput } from 'generated/graphql'
import {
  HOST_PATTERN,
  patternCanBeInterpreted,
  parsePattern,
  ParsedPattern,
  generatePattern,
} from './parse-acl-patterns'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { ObserveAccessControlsService } from 'app/admin/observe-access-controls/observe-access-controls.service'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

interface RuleFields {
  id?: string
  type: 'APP' | 'URL'
  pattern: string
  name?: string
  enhancedMetadataCapture?: boolean
  allowed?: boolean
}

@Component({
  selector: 'app-observe-access-control-edit',
  templateUrl: './observe-access-control-edit.component.html',
})
export class ObserveAcessControlEditComponent implements OnInit, OnDestroy {
  @Output()
  closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  isNewRule = true
  _inputRule: ObserveAccessControl | ObserveUsageSummary | null
  @Input()
  set inputRule(val: ObserveAccessControl | ObserveUsageSummary | null) {
    this._inputRule = val
    if (val) {
      const type = val.type === 'APP' ? 'APP' : 'URL'
      // narrow type to ObserveAccessControl
      if ('id' in val) {
        this.isNewRule = false
        this.setFormValues({
          id: val.id,
          type,
          pattern: val.pattern,
          enhancedMetadataCapture: val.enhancedMetadataCapture,
          allowed: val.allowed,
          name: val.name,
        })
        // otherwise must be ObserveUsageSummary
      } else {
        this.isNewRule = true
        this.setFormValues({
          type,
          pattern: val.applicationOrUrl,
          enhancedMetadataCapture: true,
          allowed: val.allowed,
        })
      }
    }
  }

  createRuleForm = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    type: new UntypedFormControl(null, Validators.required),
    allowed: new UntypedFormControl(true, Validators.required),
    pattern: new UntypedFormControl(null, [Validators.required, this.invalidRegexValidator()]),
    name: new UntypedFormControl(''),
    enhancedMetadataCapture: new FormControl<boolean>(true),
  })
  managedPatternCtrls = new UntypedFormGroup({
    protocol: new UntypedFormControl('empty'),
    domain: new UntypedFormControl(null, [Validators.required, Validators.pattern(HOST_PATTERN)]),
    allowSubdomains: new UntypedFormControl(),
    allowPaths: new UntypedFormControl(),
  })
  patternCanBeInterpreted = patternCanBeInterpreted

  showManagedPatternOptions = false
  isSaving = false
  destroy$: Subject<void> = new Subject<void>()
  constructor(private toastService: ToastService, private observeAccessControlService: ObserveAccessControlsService) {}

  ngOnInit(): void {
    this.setTypeWatches()
    this.setCtrlWatches()
    this.setAllowedWatches()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  cancel(): void {
    this.closeEvent.emit(true)
  }

  async saveOAC(closeModal: boolean): Promise<void> {
    if (this.createRuleForm.invalid) {
      this.toastService.error(
        'Please confirm all required fields are filled in before saving',
        JSON.stringify(readableFormGroupErrors(this.createRuleForm)),
      )
      this.createRuleForm.markAllAsTouched()
    } else {
      try {
        const value: UpdateObserveAccessControlInput = {
          type: this.createRuleForm.value.type === 'APP' ? 'APP' : 'URL',
          pattern: this.createRuleForm.value.pattern?.trim(),
          order: 1, // Field no longer used, will be setting all new records with order = 1
          allowed: this.createRuleForm.value.allowed,
          name: this.createRuleForm.value.name,
          enhancedMetadataCapture: this.createRuleForm.value.enhancedMetadataCapture,
        }

        if (this.createRuleForm.value.id) {
          await this.observeAccessControlService.updateObserveAccessControl(this.createRuleForm.value.id, value)
        } else {
          await this.observeAccessControlService.createObserveAccessControl(value)
        }
        this.toastService.success('Observe access control successfully saved')
        if (closeModal) {
          this.closeEvent.emit(true)
        } else {
          this.createRuleForm.reset()
        }
      } catch (e) {
        this.toastService.error(`Could not save Observe access control ${e}`)
      }
    }
  }

  updateManagedPatternCtrlsFromParsedPattern(parsed: ParsedPattern): void {
    this.managedPatternCtrls.setValue({
      protocol: parsed.protocol === 'unknown' ? 'empty' : parsed.protocol,
      domain: parsed.domainLiteral,
      allowSubdomains: parsed.subdomains !== 'disallowed',
      allowPaths: parsed.path !== 'disallowed',
    })
  }

  setFormValues(ruleFields: RuleFields): void {
    if (ruleFields.type == 'APP') {
      this.createRuleForm.patchValue({
        id: ruleFields.id ?? null,
        type: 'APP',
        pattern: ruleFields.pattern,
        name: ruleFields.name ?? '',
        allowed: ruleFields.allowed ?? true,
        enhancedMetadataCapture: ruleFields.enhancedMetadataCapture ?? true,
      })
    } else {
      const type =
        this.parseUrlOrNull(ruleFields.pattern) || patternCanBeInterpreted(ruleFields.pattern)
          ? 'URL-MANAGED'
          : 'URL-CUSTOM'
      this.createRuleForm.patchValue({
        id: ruleFields.id ?? null,
        pattern: ruleFields.pattern,
        type, // `type`  must be set *after* `pattern`, so that observables fire in the right order
        name: ruleFields.name ?? '',
        allowed: ruleFields.allowed ?? true,
      })
    }
  }

  setCtrlWatches(): void {
    this.managedPatternCtrls.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => {
          return (
            a.protocol === b.protocol &&
            a.domain === b.domain &&
            a.allowSubdomains == b.allowSubdomains && // use == for casting
            a.allowPaths == b.allowPaths // use == for casting
          )
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(({ protocol, domain, allowSubdomains, allowPaths }) => {
        const pattern = this.createRuleForm.get('pattern').value ?? ''
        const opts = parsePattern(pattern)
        // Adds escape char for '.'
        opts.domainLiteral = domain.replace(/\./g, '\\.') || ''
        opts.protocol = typeof protocol === 'string' ? (protocol as ParsedPattern['protocol']) : 'unknown'

        // Always ignore the query string when users edit via this form.
        opts.ignoreQueryString = true

        if (allowSubdomains === false) {
          opts.subdomains = 'disallowed'
        } else {
          opts.subdomains = 'any'
        }

        if (allowPaths === false) {
          opts.path = 'disallowed'
        }else{
          opts.path = 'empty'
        }

        if (opts.domainLiteral != '') {
          const newPattern = generatePattern(opts)
          if (newPattern !== pattern) {
            this.createRuleForm.get('pattern').setValue(newPattern)
          }
        } else {
          this.createRuleForm.get('pattern').patchValue('')
        }
      })
  }

  setTypeWatches(): void {
    this.createRuleForm
      .get('type')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val === 'URL-MANAGED') {
          this.showManagedPatternOptions = true
          this.createRuleForm.addControl('managedPatternOptions', this.managedPatternCtrls)
          const parsed = parsePattern(this.createRuleForm.get('pattern')?.value ?? '')

          this.updateManagedPatternCtrlsFromParsedPattern(parsed)
        } else {
          this.showManagedPatternOptions = false
          this.createRuleForm.removeControl('managedPatternOptions')
        }

        if (val === 'APP') {
          const enhancedMetadataCaptureValue =
            this._inputRule && 'id' in this._inputRule ? this._inputRule.enhancedMetadataCapture : true
          this.createRuleForm.get('enhancedMetadataCapture').setValue(enhancedMetadataCaptureValue)
        } else {
          this.createRuleForm.get('enhancedMetadataCapture').setValue(true)
        }
      })
  }

  setAllowedWatches(): void {
    this.createRuleForm
      .get('allowed')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((allowed) => {
        if (!allowed) {
          this.createRuleForm.get('enhancedMetadataCapture').setValue(false)
        }
      })
  }

  private invalidRegexValidator(): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      const pattern = (ctrl.value ?? '').toString()
      try {
        new RegExp(pattern)
        return null
      } catch {
        return { invalidRegex: pattern }
      }
    }
  }

  private parseUrlOrNull = (url: string): URL | null => {
    try {
      return new URL(url)
    } catch {
      return null
    }
  }
}
