import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-badge-pill',
  templateUrl: './badge-pill.component.html',
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class BadgePillComponent implements OnInit {
  @Input() boldedLabel: string
  @Input() label: string
  @Output() onDeleteClicked = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}
}
