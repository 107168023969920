import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GenerateVxDocumentConcatenatedPageFile } from 'app/vision-x/vision-x.gql'
import { MutationGenerateConcatenatedPageFileArgs, VxDocument } from 'generated/graphql'

@Injectable({
  providedIn: 'root',
})
export class VxFileService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  async generateVxDocumentConcatenatedPageFile(documentId: string): Promise<VxDocument> {
    const result = await this.apollo
      .mutate<{ generateConcatenatedPageFile: VxDocument }, MutationGenerateConcatenatedPageFileArgs>({
        mutation: GenerateVxDocumentConcatenatedPageFile,
        variables: {
          id: documentId,
        },
      })
      .toPromise()

    return result?.data?.generateConcatenatedPageFile
  }

  /**
   * Fetch a file pertaining to VisionX and return it's content as a string
   *
   * @param {string} id
   * @return {*}  {Promise<string>}
   * @memberof FileService
   */
  async loadVxFileContents(id: string): Promise<ArrayBuffer> {
    let contents = await this.http
      .get(`/api/vx-file/${id}`, { withCredentials: true, responseType: 'arraybuffer' })
      .toPromise()
    return contents
  }
}
