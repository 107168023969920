import { Injectable } from '@angular/core'
import {
  ExtractionRule,
  ExtractionRuleCondition,
  FieldExtractionRule,
  RegexField,
  TemplateMatchCoordinates,
  VxPage,
  VxPageType,
  VxPageTypeFieldGroup,
  VxPageTypeFormField,
  VxRuleTemplateField,
} from 'generated/graphql'
import { Subject, BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ExtractionRuleNode } from './utils/VxExtractionRuleNode'
import { ExtractionRuleTree } from './utils/VxExtractionRuleTree'

export type ConditionType = 'START' | 'END' | 'FINISHED_REPEATING'

export type TemplatePaneField = FieldExtractionRule | RegexField

export type ExtractionRuleConditionWithType = ExtractionRuleCondition & {
  type: ConditionType
}

export enum EditorModes {
  POSITIONAL_EXTRACTION,
  FORM_EXTRACTION,
}

export type VxRuleTemplateFieldAndExtractionNode = {
  vxRuleTemplateField: VxRuleTemplateField
  extractionRuleNodeHash: string // requires looking up the actual node whenever needed
  hasRepeatingRanges$?: Observable<boolean>
}

export enum SelectedObjectType {
  NotExtractionRule = 0,
  RuleTemplateField,
  NonRuleTemplateField,
}

@Injectable({
  providedIn: 'root',
})
export class VxRuleEditorStateService {
  constructor() {}

  /* Editor Modes */
  availableEditorModes = EditorModes

  // FeatureFlag for VxRuleTemplate aka ui.visionx.ruleTemplates
  ruleTemplateFlag$ = new BehaviorSubject<boolean>(false)

  positionalExtractionFlag$ = new BehaviorSubject<boolean>(false)
  formExtractionFlag$ = new BehaviorSubject<boolean>(false)

  // trigger to add a custom field to the shopping cart
  triggerAddCustomField$ = new Subject<void>()

  /*
   * Canvas Manipulation
   */

  // Canvas Pan mode
  isPanningEnabled$ = new BehaviorSubject<boolean>(false)

  // Canvas Zoom mode
  zoomLevel$ = new BehaviorSubject<number>(null)
  zoomToFitLevel$ = new BehaviorSubject<number>(null)
  // Event listener to reset the image wrapper to the size of the screen.

  editorCanvasResize$ = new BehaviorSubject<void>(null)

  /*
   * Image dimensions
   */
  editorImageDimensions$ = new BehaviorSubject<{ width: number; height: number }>(null)

  // Allow for listening to canvas events
  editorDrawMode$ = new BehaviorSubject<boolean>(false)

  enableDrawMode(): void {
    this.isPanningEnabled$.next(false)
    this.editorDrawMode$.next(true)
  }

  disableDrawMode(): void {
    this.editorDrawMode$.next(false)
  }

  /*
   * Extraction Rules
   */

  vxPageTypeFieldGroups$ = new BehaviorSubject<VxPageTypeFieldGroup[]>([])
  vxPageTypeFormFields$ = new BehaviorSubject<VxPageTypeFormField[]>([])
  vxExtractionRuleTree$ = new BehaviorSubject<ExtractionRuleTree>(null)
  activeExtractionRuleNode$ = new BehaviorSubject<ExtractionRuleNode>(null)
  editedVxFormFields$ = new BehaviorSubject<Map<string, VxPageTypeFormField>>(new Map())

  /**
   * Given a tree, determine if the active extraction rule node needs to be updated.
   * This should only set a default value if the current active extraction node is not
   * located in the tree.
   * @param tree
   */
  setDefaultActiveExtractionRuleNode(tree: ExtractionRuleTree): void {
    const activeNode = this.activeExtractionRuleNode$.getValue()
    /*
     * There can be a null extraction tree due to it being cleared out, or one that simple hasn't had any
     * rules created. So we need to clear out the active extraction rule node.
     */
    if (!tree || !tree?.hasNodes) {
      this.activeExtractionRuleNode$.next(null)
      return
    }

    /*
     * This function gets invoked not only when we switch page types, but
     * also when we update any of the nodes in the tree.
     * So before deciding on the default extraction rule node, we must find out if the
     * currently active extraction rule node belongs to this tree.
     *
     * If it does, we should not touch it and let it be in focus, but if the active node
     * belongs to a different page type, we need to set a default one.
     */

    let isActiveNodePartOfTree = false

    if (activeNode && tree?.hasNodes) {
      isActiveNodePartOfTree = !!tree.findNode(activeNode.hash)
    }

    if (isActiveNodePartOfTree) {
      return
    }

    // Get the first available extraction rule
    this.activeExtractionRuleNode$.next(Object.values(tree.childNodes)[0])
  }

  vxRuleTemplateFields$ = new BehaviorSubject<VxRuleTemplateFieldAndExtractionNode[]>([])
  // helper for backwards compatability to non-rule template page type fields
  selectedObjectType$ = new BehaviorSubject<SelectedObjectType>(SelectedObjectType.NotExtractionRule)

  /**
   * Update the values of the an extraction rule. This is in reference ot the actual value found on the node
   * and not the node itself. For node changes, use the ExtractionRuleTree class.
   *
   * A side effect of this function is that it will update any views with the new tree
   *
   * @param tree
   * @param node
   * @param updates
   */
  updateExtractionRuleValues(
    tree: ExtractionRuleTree,
    node: ExtractionRuleNode,
    updates: Partial<ExtractionRule>,
  ): void {
    if (!tree) {
      throw new Error('Missing an Extraction Rule Tree')
    }

    if (!node) {
      throw new Error('Missing an Extraction Rule Node')
    }

    if (!Object.keys(updates)?.length) {
      // There is nothing to update
      return
    }

    node.value = {
      ...node.value,
      ...updates,
    }

    tree = tree.updateExtractionRuleNode(node)
    this.addPendingUpdateFieldGroupId(node.fieldGroupId)
    this.vxExtractionRuleTree$.next(tree)
  }

  vxPageTypePendingUpdates$ = new BehaviorSubject<Partial<VxPageType>>(null)
  vxFieldGroupsPendingUpdates$ = new BehaviorSubject<Set<string>>(new Set())
  vxFieldGroupsPendingRemovals$ = new BehaviorSubject<Set<string>>(new Set())
  vxFormFieldsPendingRemovals$ = new BehaviorSubject<Set<string>>(new Set())

  addPendingUpdateField = (vxPageTypeUpdates: Partial<VxPageType>): void => {
    if (!Object.keys(vxPageTypeUpdates)?.length) {
      // There is nothing to update
      return
    }
    const existingPageType: Partial<VxPageType> = this.vxPageTypePendingUpdates$.getValue() || {}
    // update the value
    const updatedPageType: Partial<VxPageType> = {
      ...existingPageType,
      ...vxPageTypeUpdates,
    }
    this.vxPageTypePendingUpdates$.next(updatedPageType)
  }

  addPendingUpdateFieldGroupId = (fieldGroupId: string): void => {
    const pendingUpdatesSet = this.vxFieldGroupsPendingUpdates$.getValue()
    pendingUpdatesSet.add(fieldGroupId)
    this.vxFieldGroupsPendingUpdates$.next(pendingUpdatesSet)
  }
  removePendingUpdateFieldGroupId = (fieldGroupId: string): void => {
    const pendingUpdatesSet = this.vxFieldGroupsPendingUpdates$.getValue()
    pendingUpdatesSet.delete(fieldGroupId)
    this.vxFieldGroupsPendingUpdates$.next(pendingUpdatesSet)
  }
  addPendingRemovalFieldGroupId = (fieldGroupId: string): void => {
    const pendingRemovalsSet = this.vxFieldGroupsPendingRemovals$.getValue()
    pendingRemovalsSet.add(fieldGroupId)
    this.vxFieldGroupsPendingRemovals$.next(pendingRemovalsSet)
  }
  removePendingRemovalFieldGroupId = (fieldGroupId: string): void => {
    const pendingRemovalsSet = this.vxFieldGroupsPendingRemovals$.getValue()
    pendingRemovalsSet.delete(fieldGroupId)
    this.vxFieldGroupsPendingRemovals$.next(pendingRemovalsSet)
  }
  addPendingRemovalFormFieldId = (formFieldId: string): void => {
    const pendingRemovalsSet = this.vxFormFieldsPendingRemovals$.getValue()
    pendingRemovalsSet.add(formFieldId)
    this.vxFormFieldsPendingRemovals$.next(pendingRemovalsSet)
  }
  removePendingRemovalFormFieldId = (formFieldId: string): void => {
    const pendingRemovalsSet = this.vxFormFieldsPendingRemovals$.getValue()
    pendingRemovalsSet.delete(formFieldId)
    this.vxFormFieldsPendingRemovals$.next(pendingRemovalsSet)
  }

  canvasIsResizingObject$ = new BehaviorSubject<boolean>(false)
  canvasResizeeBeginValue$ = new BehaviorSubject<ExtractionRuleConditionWithType | FieldExtractionRule>(null)
  canvasResizeeEdge$ = new BehaviorSubject<Array<'top' | 'right' | 'bottom' | 'left'>>(null)
  canvasResizeeInitialClick$ = new BehaviorSubject<MouseEvent>(null)
  canvasSelectedObject$ = new BehaviorSubject<ExtractionRuleCondition | FieldExtractionRule>(null)
  selectedRegexField$ = new BehaviorSubject<string>(null)
  vxPageType$ = new BehaviorSubject<VxPageType>(null)
  vxPageTypeId$ = new BehaviorSubject<string>(null)
  vxPage$ = new BehaviorSubject<VxPage>(null)
  vxPages$ = new BehaviorSubject<VxPage[]>([])
  currentEditorMode$ = new BehaviorSubject<EditorModes>(EditorModes.POSITIONAL_EXTRACTION)

  /**
   * Observable to track regex matches for an active page.
   *
   * A simple map that contains an array of the matched positions
   */
  regexFieldMatches$ = new BehaviorSubject<TemplateMatchCoordinates[]>([])

  /**
   * Removes anything that is considered an unsaved change from the state service
   */
  clearAllChanges(): void {
    this.vxPageTypePendingUpdates$.next(null)
    this.vxFieldGroupsPendingRemovals$.next(new Set())
    this.vxFieldGroupsPendingUpdates$.next(new Set())
    this.vxFormFieldsPendingRemovals$.next(new Set())
    this.editedVxFormFields$.next(new Map())
  }

  /**
   * Will return a partial `VxPageType` if there are page type updates;
   * otherwise, it will return a boolean indicating any field groups or
   * templates have updates.
   */
  hasUnsavedChanges$ = combineLatest([
    this.vxPageTypePendingUpdates$,
    this.vxFieldGroupsPendingUpdates$,
    this.vxFieldGroupsPendingRemovals$,
    this.editedVxFormFields$,
    this.vxFormFieldsPendingRemovals$,
  ]).pipe(
    map(
      ([
        vxPageTypeUpdates,
        pendingFieldGroupUpdates,
        pendingFieldGroupRemovals,
        editedVxFormFields,
        pendingFormFieldRemovals,
      ]) => {
        return (
          (vxPageTypeUpdates as VxPageType) ||
          (pendingFieldGroupUpdates as Set<string>).size > 0 ||
          (pendingFieldGroupRemovals as Set<string>).size > 0 ||
          (editedVxFormFields as Map<string, VxPageTypeFormField>).size > 0 ||
          (pendingFormFieldRemovals as Set<string>).size > 0
        )
      },
    ),
  )

  /**
   * Return true if a field is a RegexField
   * @returns {boolean}
   */
  isRegexField(field: TemplatePaneField): field is RegexField {
    return field.__typename === 'RegexField'
  }

  /**
   * Return true if a field is a FieldExtractionRule
   * @returns {boolean}
   */
  isFieldExtractionRule(field: TemplatePaneField): field is FieldExtractionRule {
    return field.__typename === 'FieldExtractionRule'
  }
}
