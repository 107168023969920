import { Component, OnDestroy, OnInit } from "@angular/core"
import { Apollo } from 'apollo-angular'
import { Observable, Subject, of, combineLatest } from 'rxjs'
import { map, takeUntil, catchError, switchMap, take } from 'rxjs/operators'
import { environment } from 'environments/environment'
import { GetEdiTransactions, GetEdiTransactionsByOrgAndDate } from 'app/edi-transactions/edi-transactions.gql'
import { GetProcedureSet, GetProcedureSetsByEpicReferralId } from 'app/procedure-sets/procedure-sets.gql'
import { EdiTransactionBatch, EdiTransactionsInput, ProcedureSet, EdiTransactionSearchInput, ProcedureSetList } from 'generated/graphql'
import { EdiDashboardService } from "./edi-dashboard.service"
import { OrgService } from 'app/admin/org/org.service'
import { ToastService } from 'app/shared/services/toast.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { GenericModalComponent } from 'app/shared/components/generic-modal/generic-modal.component'


/**
 * Page to display and send edi transactions
 *
 * @export
 * @class EdiDashboard
 * @implements {OnInit}
 * @implements {OnDestroy}
 */

@Component({
  selector: 'app-edi-dashboard',
  templateUrl: './edi-dashboard.page.html',
  styleUrls: ['./edi-dashboard.page.scss'],
})

export class EdiDashboard implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  constructor(
    private apollo: Apollo,
    private orgService: OrgService,
    private ediDashboardService: EdiDashboardService,
    private toast: ToastService,
    private modalService: NgbModal 
  ) {}
  searchData = {
    orgId: '',
    epicReferralId: '',
    procId: '',
    startDate: '',
    endDate: ''
  }
  orgs = []
  transactions$: Observable<any[]>
  expandedTransactions: Set<string> = new Set()
  selectedTransactions: { id: string, isOutgoing: boolean, type: string, envName: string }[] = []
  showTable: boolean = false

  ngOnInit(): void {
    this.fetchOrgs()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  fetchOrgs(): void {
    this.orgService.getOrgs().subscribe(
      (orgs) => {
        this.orgs = orgs.data.organizations.entities.map((org) => ({
          id: org.id,
          name: org.name,
        }))
      },
      (error) => {
        this.toast.error('Error fetching organizations:', JSON.stringify(error))
      }
    )
  }

  clearSearch(): void {
    this.searchData = {
      orgId: '',
      epicReferralId: '',
      procId: '',
      startDate: '',
      endDate: ''
    }
    this.showTable = false
  }

  toggleExpand(transactionId: string) {
    if (this.expandedTransactions.has(transactionId)) {
      this.expandedTransactions.delete(transactionId)
    } else {
      this.expandedTransactions.add(transactionId)
    }
  }

  toggleTransactionSelection(transaction: any): void {
    const env = environment.name
    const index = this.selectedTransactions.findIndex(
      (t) => t.id === transaction.id
    )

    if (index !== -1) {
      this.selectedTransactions.splice(index, 1)
    } else {
      this.selectedTransactions.push({
        id: transaction.id,
        isOutgoing: transaction.isOutgoing,
        type: transaction.type,
        envName: env
      })
    }
  }

  onSendSelectedTransactions(): void {
    if (this.selectedTransactions.length === 0) {
      this.toast.error('No transactions selected')
      return
    }

    this.transactions$.pipe(
      take(1)
    ).subscribe((transactions) => {
      if (environment.name === 'Production') {
        const modalRef = this.modalService.open(GenericModalComponent, {
          centered: true,
          backdrop: 'static',
          size: 'lg'
        })
  
        modalRef.componentInstance.title = 'Warning'
        modalRef.componentInstance.body = 'This is production data and will be sent to the customer. Do you want to continue?'
        modalRef.componentInstance.confirmText = 'Yes'
        modalRef.componentInstance.cancelText = 'No'
  
        modalRef.componentInstance.onConfirm.subscribe(() => {
          this.ediDashboardService.sendEdi(this.selectedTransactions)
        })
        modalRef.componentInstance.onCancel.subscribe(() => {
          this.selectedTransactions = []
          transactions.forEach((transaction) => {
            transaction.selected = false
          })
          modalRef.close()
        })
      } else {
        this.ediDashboardService.sendEdi(this.selectedTransactions)
      }
    })
  }

  onSearch() {
    this.transactions$ = of([])
    this.showTable = true 

    const { orgId, epicReferralId, procId, startDate, endDate } = this.searchData

    if (orgId && startDate && endDate) {
      this.getEdiBatchByOrgIdAndDateRange()
    }
  
    if (epicReferralId && orgId) {
      this.getProcedureSetsByEpicReferralId()
    }
  
    if (procId) {
      this.getEdiBatchByPsId()
    }

    this.transactions$.subscribe((transactions) => {
      if (transactions.length === 0) {
        this.toast.error('No search results found, please try another search.')
      }
    })
  }

  getEdiBatchByOrgIdAndDateRange() {
    const { orgId, startDate, endDate } = this.searchData

    if (new Date(endDate) < new Date(startDate)) {
      this.toast.error('End date cannot be earlier than start date')
      return of(null)
    }

    const ediTransactions$ = this.getEdiByOrgIdAndDateRange({ orgId, startDate, endDate}).pipe(
      catchError((error) => {
        this.toast.error('Error fetching EDI data: ' + error.message)
        return of(null)
      })
    )

    this.transactions$ = ediTransactions$.pipe(
      switchMap((ediTransactions) => {
        const combinedTransaction$ = ediTransactions.entities.map((transaction) => {
          if (transaction.relatedEntityId) {
            return this.getProcedureSets(transaction.relatedEntityId).pipe(
              catchError((error) => {
                this.toast.error('Error fetching procedure set data: ' + error.message)
                return of(null)
              }),
              takeUntil(this.destroy$),
              map((ps) => {
                return {
                  ...transaction,
                  epicReferralId: ps?.epicReferralId,
                  status: ps?.status,
                }
              })
            )
          } else {
            return of({ ...transaction })
          }
        })
        return combineLatest(combinedTransaction$)
      })
    )
  }

  getProcedureSetsByEpicReferralId() {
    const { orgId, epicReferralId } = this.searchData
    const procedureSets$ = this.getProcedureSetsByEpicId(epicReferralId, orgId).pipe(
      catchError((error) => {
        this.toast.error('Error fetching procedure set data: ' + error.message)
        return of(null)
      })
    )

    this.transactions$ = procedureSets$.pipe(
      switchMap((procedureSets) => {
        return combineLatest(
          procedureSets.entities.map((ps) =>
            this.getEdiTransactions({ relatedEntityId: ps.id }).pipe(
              map((transactions) => {
                return transactions.entities.map((transaction) => ({
                  ...transaction,
                  epicReferralId: ps?.epicReferralId,
                  status: ps?.status,
                }))
              }),
              catchError((error) => {
                this.toast.error('Error fetching EDI data: ' + error.message)
                return of(null)
              }),
              takeUntil(this.destroy$)
            )
          )
        ).pipe(
          map((transactions) => transactions.flat())
        )
      })
    )
  }

  getEdiBatchByPsId() {
  const { procId } = this.searchData
  const ediTransactions$ = this.getEdiTransactions({ relatedEntityId: procId }).pipe(
    catchError((error) => {
      this.toast.error('Error fetching EDI data: ' + error.message)
      return of(null)
    })
  )
  const procedureSets$ = this.getProcedureSets(procId).pipe(
    catchError((error) => {
      this.toast.error('Error fetching procedure set data: ' + error.message)
      return of(null)
    })
  )

  this.transactions$ = combineLatest([ediTransactions$, procedureSets$]).pipe(
    takeUntil(this.destroy$),
    map(([ediTransactions, procedureSet]) => {
      return ediTransactions.entities.map((transaction) => ({
        ...transaction,
        epicReferralId: procedureSet?.epicReferralId,
        status: procedureSet?.status,
      }))
    })
  )
  }

  getProcedureSetsByEpicId(epicReferralId: string, orgId: string): Observable<ProcedureSetList>{
    return this.apollo
    .watchQuery<{procedureSetsByEpicReferralId: ProcedureSetList}>({
      query: GetProcedureSetsByEpicReferralId,
      variables: {
        epicReferralId,
        orgId,
      }
    })
    .valueChanges.pipe(map((result) => result.data?.procedureSetsByEpicReferralId))
  }

  getEdiByOrgIdAndDateRange(data: EdiTransactionSearchInput): Observable<EdiTransactionBatch>{
    return this.apollo
    .watchQuery<{ ediTransactionsByOrgAndDate: EdiTransactionBatch }>({
      query: GetEdiTransactionsByOrgAndDate,
      variables: {
        data,
      },
    })
    .valueChanges.pipe(map((result) => result.data?.ediTransactionsByOrgAndDate))
  }
  
  getEdiTransactions(data: EdiTransactionsInput): Observable<EdiTransactionBatch> {
    return this.apollo
      .watchQuery<{ ediTransactions: EdiTransactionBatch }>({
        query: GetEdiTransactions,
        variables: {
          data,
        },
      })
      .valueChanges.pipe(map((result) => result.data?.ediTransactions))
  }

  getProcedureSets(id: string): Observable<ProcedureSet> {
    return this.apollo
      .watchQuery<{procedureSet: ProcedureSet}>({
        query: GetProcedureSet,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map((result) => result.data?.procedureSet))
  }
}
